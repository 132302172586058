import axios from "axios";
import { WarningNetworkFailure } from "../../helpers/alertsweet";

const END_POINT_SEVER = "https://minion-api.nonlub.com";
// const END_POINT_SEVER = "http://localhost:7070";

// @ts-ignore
export const orderGetMany = async (findby, token) => {
  try {
    const url = END_POINT_SEVER + "/order/get-many?" + findby;
    const res = await axios.get(url, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error) {
    // @ts-ignore
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    // @ts-ignore
    return { error: true, message: error.message };
  }
};

export const orderSmsCallback = async (
  body: { text: string },
  token: string
) => {
  try {
    const url = END_POINT_SEVER + "/order/sms-callback";
    const res = await axios.post(url, body, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};

export const orderDeleteOne = async (_id: string, token: string) => {
  try {
    const url = END_POINT_SEVER + "/order/delete-one/" + _id;
    const res = await axios.put(url, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};
