import Swal from "sweetalert2";
// icons svg

export const SuccessSwal = (item) => {
  Swal.fire({
    imageAlt: "Custom image",
    animation: false,
    html: `<div style="display:flex;justify-content:center;align-items:center;gap:20px;padding:10px;flex-direction: column;"><span style="color: #0178c1;font-size:16px;font-weight:700">${item}</span>`,
    showConfirmButton: false,
    timer: 1800,
    width: 320,
    height: 208,
  });
};

export const testSwal = (item) => {
  Swal.fire(<div>testtest</div>);
};

export const ErrorSwal = (err) => {
  Swal.fire({
    icon: "error",
    text: err,
    showConfirmButton: false,
    timer: 3000,
  });
};

export const WarningSwal = (text) => {
  Swal.fire({
    icon: "warning",
    text: text,
    showConfirmButton: false,
  });
};

export const WarningNetworkFailure = () => {
  Swal.fire({
    imageAlt: "Custom image",
    animation: false,
    html: `<div style="display:flex;justify-content:center;align-items:center;gap:20px;padding:10px;flex-direction: column;"><span style="color: #FF8A00;font-size:18px;font-weight:700">ເຄືອຄ່າຍຂັດຂ້ອງກະລຸນາເຊື່ອມອິນເຕີເນັດ!</span>`,
    showConfirmButton: false,
    width: 420,
    height: 208,
  });
};

export const WarningError = (text) => {
  Swal.fire({
    imageAlt: "Custom image",
    animation: false,
    html: `<div style="display:flex;justify-content:center;align-items:center;gap:20px;padding:10px;flex-direction: column;"><span style="color: #FF0000;font-size:18px;font-weight:700">${text}</span>`,
    showConfirmButton: false,
    width: 420,
    height: 208,
  });
};

export const wariningWithTextAndConfirmButton = (title, detail, onConfirm) => {
  Swal.fire({
    title: title,
    text: detail,
    icon: "success",
    timer: 5000,
  }).then(async () => {
    try {
      await onConfirm();
    } catch (error) {
      console.log("Error: ", error);
    }
  });
};
