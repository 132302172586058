import React from "react";
import { Outlet } from "react-router-dom";
// page
// import MemberPage from "../pages/MemberPage";
import StoreListPage from "../pages/store/StoreListPage";

// eslint-disable-next-line
export default {
  path: "/store",
  element: <Outlet />,
  children: [
    {
      path: "all-store",
      element: <StoreListPage />,
    },
  ],
};
