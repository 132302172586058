import React from "react";
import { Button } from "react-bootstrap";

interface NumberKeyboardProps {
  totalBill: number;
  selectInput: string;
  payType: string;
  setSelectInput: React.Dispatch<React.SetStateAction<string>>;
  onClickButtonDrawer: () => void;
}

const NumberKeyboard: React.FC<NumberKeyboardProps> = ({
  totalBill,
  selectInput,
  payType,
  setSelectInput,
  onClickButtonDrawer,
}) => {
  const _num = [
    { key: "1", name: "1" },
    { key: "2", name: "2" },
    { key: "3", name: "3" },
    { key: "4", name: "4" },
    { key: "5", name: "5" },
    { key: "6", name: "6" },
    { key: "7", name: "7" },
    { key: "8", name: "8" },
    { key: "9", name: "9" },
    { key: "Delete", name: "Delete" },
    { key: "0", name: "0" },
    { key: "000", name: "000" },
  ];

  // Function to handle button clicks
  const clickButton = (text: string) => {
    if (!setSelectInput) return;

    if (text === "Delete") {
      if (!selectInput) return setSelectInput("");
      if (selectInput.length <= 0) return setSelectInput("");
      let _prev = selectInput || "";
      let _text = _prev.substr(0, _prev.length - 1);
      setSelectInput(_text);
    } else {
      let _text = (selectInput || "") + text;
      setSelectInput(_text);
    }
  };

  return (
    <div>
      <div className="grid gap-[10px] grid-cols-1 md:grid-cols-[150px_1fr]">
        <div className="hidden md:grid grid-cols-2 gap-[10px] grid-rows-1 h-[250px]">
          <Button disabled>ສະມາຊິກ</Button>
          <Button
            disabled={payType !== "cash"}
            onClick={() => {
              console.log(totalBill);
              setSelectInput(totalBill + "");
            }}
          >
            ເຕັມຈຳນວນ
          </Button>
          <Button onClick={onClickButtonDrawer}>Drawer</Button>
          <Button
            onClick={() => setSelectInput("")}
            disabled={payType === "transfer"}
          >
            ລົບທັງໝົດ
          </Button>
        </div>
        <div className="block md:hidden">
          <Button
            style={{ width: "100%" }}
            disabled={payType !== "cash"}
            onClick={() => {
              console.log(totalBill);
              setSelectInput(totalBill + "");
            }}
          >
            ເຕັມຈຳນວນ
          </Button>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3,1fr)",
            gap: 10,
            gridAutoRows: "1fr",
            height: 250,
          }}
        >
          {_num?.map((e, i) => {
            return (
              <Button
                key={i}
                onClick={() => {
                  clickButton(e.key);
                }}
                disabled={payType === "transfer"}
              >
                {e.name}
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NumberKeyboard;
