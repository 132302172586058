import React, { useState, useEffect, useRef } from "react";
import Table from "react-bootstrap/Table";
import axios from "axios";
import _ from "lodash";
import Swal from "sweetalert2";
import html2canvas from "html2canvas";
import { base64ToBlob } from "../../helpers";
import { Button } from "react-bootstrap";
import { USER_KEY } from "../../constants/index";

import { END_POINT_SEVER, getLocalData, MENUS } from "../../constants/api";
import { getHeaders } from "../../services/auth";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../store";
import BillForChef80 from "../../components/bill/BillForChef80";
import MenuCardItem from "../../components/menu/MenuCardItem";
import { orderCreateMany } from "../../services/order";

function AddOrder() {
  const params = useParams();
  const navigate = useNavigate();
  const code = params?.code;

  // state
  const tableId = params?.tableId;
  const [isLoading, setIsLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [userData, setUserData] = useState({});

  const [selectedMenu, setSelectedMenu] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [allSelectedMenu, setAllSelectedMenu] = useState([]);

  function handleSetQuantity(int, data) {
    let dataArray = [];
    for (const i of selectedMenu) {
      let _data = { ...i };
      if (data?.id === i?.id) {
        _data = { ..._data, quantity: _data?.quantity + int };
      }
      if (_data.quantity > 0) {
        dataArray.push(_data);
      }
    }
    setSelectedMenu(dataArray);
  }

  const { storeDetail, printers, selectedTable, onSelectTable } = useStore();

  const [search, setSearch] = useState("");
  const afterSearch = _.filter(
    allSelectedMenu,
    (e) =>
      (e?.name?.indexOf(search) > -1 && selectedCategory === "All") ||
      e?.categoryId?._id === selectedCategory
  );

  const arrLength = selectedMenu?.length;
  const billForCher80 = useRef([]);
  const billForCher58 = useRef([]);
  if (billForCher80.current.length !== arrLength) {
    // add or remove refs
    billForCher80.current = Array(arrLength)
      .fill()
      .map((_, i) => billForCher80.current[i]);
  }
  if (billForCher58.current.length !== arrLength) {
    // add or remove refs
    billForCher58.current = Array(arrLength)
      .fill()
      .map((_, i) => billForCher58?.current[i]);
  }
  const onPrintForCher = async () => {
    const orderSelect = selectedMenu;
    let _index = 0;
    for (const _ref of billForCher80.current) {
      const _printer = printers.find((e) => {
        return e?._id === orderSelect?.[_index]?.printer;
      });

      try {
        let urlForPrinter = "";
        let dataUrl;
        if (_printer?.width === "80mm") {
          dataUrl = await html2canvas(billForCher80?.current[_index], {
            useCORS: true,
            scrollX: 10,
            scrollY: 0,
            // scale: 530 / widthBill80,
          });
        }
        if (_printer?.width === "58mm") {
          dataUrl = await html2canvas(billForCher58?.current[_index], {
            useCORS: true,
            scrollX: 10,
            scrollY: 0,
            // scale: 350 / widthBill58,
          });
        }
        if (_printer?.type === "ETHERNET") {
          urlForPrinter = "http://localhost:9150/ethernet/image";
        }
        if (_printer?.type === "BLUETOOTH") {
          urlForPrinter = "http://localhost:9150/bluetooth/image";
        }
        if (_printer?.type === "USB") {
          urlForPrinter = "http://localhost:9150/usb/image";
        }

        // const _image64 = await resizeImage(dataUrl.toDataURL(), 300, 500);

        const _file = await base64ToBlob(dataUrl.toDataURL());
        var bodyFormData = new FormData();
        bodyFormData.append("ip", _printer?.ip);
        bodyFormData.append("port", "9100");
        if (_index === 0) {
          bodyFormData.append("beep1", 1);
          bodyFormData.append("beep2", 9);
        }
        bodyFormData.append("image", _file);
        await axios({
          method: "post",
          url: urlForPrinter,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
      } catch (err) {
        console.log(err);
      }
      _index++;
    }
  };

  useEffect(() => {
    const ADMIN = localStorage.getItem(USER_KEY);
    // const ADMIN = profile;
    const _localJson = JSON.parse(ADMIN);
    setUserData(_localJson);

    const fetchData = async () => {
      const _localData = await getLocalData();
      if (_localData) {
        getMenu(_localData?.DATA?.storeId);
      }
    };
    fetchData();
    // getcurrency();
  }, []);
  useEffect(() => {
    // TODO: check selectTable
    if (!selectedTable) {
      navigate("/tables");
    }
  }, [selectedTable]);

  const getMenu = async (id) => {
    setIsLoading(true);
    await fetch(
      MENUS +
        `?storeId=${id}&${
          selectedCategory === "All" ? "" : "categoryId =" + selectedCategory
        }`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((json) => {
        // setMenus(json);
        setAllSelectedMenu(json);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const _checkMenuOption = async (menuId) => {
    try {
      var _menuOptions = [];
      _menuOptions = _.filter(
        allSelectedMenu,
        (e) => e?.menuId?._id === menuId
      );
      return _menuOptions;
    } catch (error) {
      return [];
    }
  };

  const addToCart = async (menu) => {
    const _menuOptions = await _checkMenuOption(menu?._id);
    if (_menuOptions.length >= 1) {
      // setMenuOptions(_menuOptions);
      // handleShow();
      return;
    }
    // setSelectedItem({ ...menu, printer: menu?.categoryId?.printer });
    let allowToAdd = true;
    let itemIndexInSelectedMenu = 0;
    let data = {
      id: menu._id,
      name: menu.name,
      quantity: 1,
      price: menu.price,
      categoryId: menu?.categoryId,
      printer: menu?.categoryId?.printer,
    };
    if (selectedMenu.length === 0) {
      setSelectedMenu([...selectedMenu, data]);
    } else {
      let thisSelectedMenu = [...selectedMenu];
      for (let index in thisSelectedMenu) {
        if (thisSelectedMenu[index]?.id === menu?._id) {
          allowToAdd = false;
          itemIndexInSelectedMenu = index;
        }
      }
      if (allowToAdd) {
        setSelectedMenu([...selectedMenu, data]);
      } else {
        let copySelectedMenu = [...selectedMenu];
        let currentData = copySelectedMenu[itemIndexInSelectedMenu];
        currentData.quantity += 1;
        copySelectedMenu[itemIndexInSelectedMenu] = currentData;
        setSelectedMenu(copySelectedMenu);
      }
    }
  };

  const onRemoveFromCart = (id) => {
    let selectedMenuCopied = [...selectedMenu];
    for (let i = 0; i < selectedMenuCopied.length; i++) {
      var obj = selectedMenuCopied[i];
      if (obj.id === id) {
        selectedMenuCopied.splice(i, 1);
      }
    }
    setSelectedMenu([...selectedMenuCopied]);
  };

  const createOrder = async (data, header, isPrinted) => {
    try {
      const data = await orderCreateMany([])
      const _storeId = userData?.data?.storeId;
      // const _billId = _bills?.[0]?._id;
      // if (!_billId) {
      //   Swal.fire({
      //     icon: "error",
      //     title: "ບໍ່ສຳເລັດ",
      //     showConfirmButton: false,
      //     timer: 1800,
      //   });
      //   setDisabledButton(false);
      //   return;
      // }
      const headers = {
        "Content-Type": "application/json",
        Authorization: header.authorization,
      };
      const _body = {
        orders: data,
        storeId: _storeId,
        tableId: tableId,
        code: code,
        // billId: _billId,
      };
      axios
        .post(END_POINT_SEVER + "/order/create-many", _body, {
          headers: headers,
        })
        .then(async (response) => {
          if (response?.data) {
            Swal.fire({
              icon: "success",
              title: "ເພີ່ມອໍເດີສໍາເລັດ",
              showConfirmButton: false,
              timer: 1800,
            });
            if (isPrinted) {
              //  print
              onPrintForCher().then(() => {
                onSelectTable(selectedTable);
                navigate(`/tables`);
              });
            } else {
              onSelectTable(selectedTable);
              navigate(`/tables`);
            }
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "warning",
            title: "ອາຫານບໍ່ພຽງພໍ",
            showConfirmButton: false,
            timer: 1800,
          });
          setDisabledButton(false);
        });
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "ບໍ່ສຳເລັດ",
        showConfirmButton: false,
        timer: 1800,
      });
      setDisabledButton(false);
    }
  };

  

  const onSubmit = async (isPrinted) => {
    try {
      setIsLoading(true);
      if (selectedMenu.length === 0) {
        Swal.fire({
          icon: "warning",
          title: "ເລືອກເມນູອໍເດີກ່ອນກົດສັ່ງອາຫານ",
          showConfirmButton: false,
          timer: 1800,
        });
        setIsLoading(false);
        setDisabledButton(false);
        return;
      }
      let header = await getHeaders();
      if (selectedMenu.length !== 0) {
        await createOrder(selectedMenu, header, isPrinted);
      }
      setIsLoading(false);
    } catch (err) {
      setDisabledButton(false);
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          overflow: "hidden",
          height: "calc(100dvh - 64px)",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            height: "calc(100dvh - 64px)",
            overflowY: "scroll",
          }}
        >
          {/**
           * ສ່ວນການ filter menu ເພື່ອງານໃນການຄົ້ນຫາ
           *
           * TODO: ຄົ້ນຫາຊື່ເມນູ
           * TODO: ຄົ້ນຫາລະຫັດເມນູ
           * TODO: ເລືອກປະເພດ
           * TODO: ຈັດລຽງຕາມ ຊື່ ລະຫັດ ຫຼື ອື່ນໆ
           * TODO: ປຸ່ມ reset
           **/}
          {/* <div style={{ backgroundColor: "#fff" }}>
            <div>
              <label>ເລືອກປະເພດ</label>
              <select
                className="form-control"
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="All">ທັງໝົດ</option>
                {Categorys &&
                  Categorys?.map((data, index) => {
                    return (
                      <option key={"category" + index} value={data?._id}>
                        {data?.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          
            <div style={{ padding: 10 }}>
              <div style={{ display: "flex", width: 250 }}>
                <Form.Control
                  placeholder="ຄົ້ນເມນູອາຫານ"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <Button>Search</Button>
              </div>
            </div>
            <div>
              <Button>ALL</Button>
              <Button variant="outline-primary">ທອດ</Button>
              <Button variant="outline-primary">ແກ້ງ</Button>
              <Button variant="outline-primary">ເຄື່ອງດື່ມ</Button>
            </div>
          </div> */}

          {/**
           * ສ່ວນ ລາຍການເມນູ
           *
           * TODO: ສະແດງລາຍການເມນູຕາມ ທີ່ filter
           *
           **/}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(6,1fr)",
              gap: 10,
              padding: 10,
              cursor: "pointer",
            }}
          >
            {afterSearch?.map((data, index) => (
              <MenuCardItem
                key={index}
                onClick={() => {
                  addToCart(data);
                }}
                name={data?.name}
                price={data?.price}
              />
            ))}
          </div>
        </div>
        {/* Detail Table */}
        <div
          style={{
            minWidth: 500,
            maxWidth: 500,
            height: "calc(100dvh - 64px)",
            overflow: "hidden",

            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              overflowY: "scroll",
              flex: 1,
              padding: 10,
            }}
          >
            <Table>
              <thead>
                <tr>
                  <th>ລຳດັບ</th>
                  <th>ຊື່ເມນູ</th>
                  <th>ຈຳນວນ</th>
                  <th>ຈັດການ</th>
                </tr>
              </thead>
              <tbody>
                {selectedMenu &&
                  selectedMenu.map((data, index) => {
                    return (
                      <tr key={"selectMenu" + index}>
                        <td>{index + 1}</td>
                        <td>{data.name}</td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <button
                              style={{
                                color: "blue",
                                border: "none",
                                width: 20,
                              }}
                              onClick={() => handleSetQuantity(-1, data)}
                            >
                              -
                            </button>
                            {data.quantity}
                            <button
                              style={{
                                color: "red",
                                border: "none",
                                width: 20,
                              }}
                              onClick={() => handleSetQuantity(1, data)}
                            >
                              +
                            </button>
                          </div>
                        </td>
                        <td>
                          <Button onClick={() => onRemoveFromCart(data.id)}>
                            Remove
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
          {/* <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              marginBottom: 4,
              padding: 10,
            }}
          >
            <Button
              variant="outline-primary"
              onClick={() => navigate(`/tables`)}
            >
              ຍົກເລີກ
            </Button>
            <Button
              variant="primary"
              disabled={disabledButton}
              onClick={() => {
                setDisabledButton(true);
                onSubmit(false);
              }}
            >
              ສັ່ງອາຫານ
            </Button>
          </div> */}
          <Button
            style={{ width: "100%", height: 60 }}
            variant="primary"
            disabled={disabledButton}
            onClick={() => {
              // onPrint();
              setDisabledButton(true);
              onSubmit(true);
            }}
          >
            ສັ່ງອາຫານ ແລະ ປຣິນບິນໄປຫາຄົວ +{" "}
          </Button>
        </div>
      </div>
      {selectedMenu?.map((val, i) => {
        return (
          <div
            style={{
              width: "80mm",
              paddingRight: "20px",
              paddingBottom: "10px",
            }}
            ref={(el) => (billForCher80.current[i] = el)}
          >
            <BillForChef80
              storeDetail={storeDetail}
              selectedTable={selectedTable}
              // dataBill={dataBill}
              val={{ ...val, tableId: { name: selectedTable?.tableName } }}
            />
          </div>
        );
      })}
    </div>
  );
}

export default AddOrder;
