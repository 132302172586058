import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { BACKGROUND } from "../constants/color";
import { BanhouseMenu, LeluckMenu, Q23CoMenu, TherdyCoMenu } from "./menuData";
import { useNavigate } from "react-router-dom";

const Sidenav = () => {
  const navigate = useNavigate();
  const listForRole = BanhouseMenu.filter((e: any) => {
    return true;
  });

  useEffect(() => {
    console.log("listForRole", listForRole);
  }, [listForRole]);

  return (
    <div className="h-full overflow-y-auto w-[250px] bg-white">
      <nav className="flex-1 flex flex-col gap-2 w-full p-2">
        <Button variant="outline" className="bg-gray-100" disabled>
          BANHOUSE
        </Button>
        {listForRole.map((e: any, index: any) => (
          <Button
            variant="outline-primary"
            key={index}
            onClick={() => {
              navigate("/" + e.key);
            }}
          >
            {e.title}
          </Button>
        ))}
        <Button variant="outline" className="bg-gray-100" disabled>
          THERDY CO
        </Button>
        {TherdyCoMenu.map((e: any, index: any) => (
          <Button
            variant="outline-primary"
            key={index}
            onClick={() => {
              navigate("/" + e.key);
            }}
          >
            {e.title}
          </Button>
        ))}
        <Button variant="outline" className="bg-gray-100" disabled>
          Q23 CO
        </Button>
        {Q23CoMenu.map((e: any, index: any) => (
          <Button
            variant="outline-primary"
            key={index}
            onClick={() => {
              navigate("/" + e.key);
            }}
          >
            {e.title}
          </Button>
        ))}
        <Button variant="outline" className="bg-gray-100" disabled>
          Leluck
        </Button>
        {LeluckMenu.map((e: any, index: any) => (
          <Button
            variant="outline-primary"
            key={index}
            onClick={() => {
              navigate("/" + e.key);
            }}
          >
            {e.title}
          </Button>
        ))}
      </nav>
    </div>
  );
};

export default Sidenav;
