import React from "react";
import PopUpAddMenu from "../../components/popup/PopUpAddMenu";

export default function List() {
  return (
    <div>
      <p>test</p>
      <PopUpAddMenu open/>
    </div>
  );
}
