import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { moneyCurrency } from "../../../helpers/index";
import { FaPrint } from "react-icons/fa";
import { GiCash } from "react-icons/gi";
// import socketIOClient from "socket.io-client";
import moment from "moment";
// import { useStore } from "../../../store";

const OrderCheckOut = ({
  data = { orderId: [] },
  tableData = {},
  show = false,
  hide,
  taxPercent = 0,
  onPrintBill = () => {},
  onSubmit = () => {},
}) => {
  const [total, setTotal] = useState();

  useEffect(() => {
    // for (let i = 0; i < data?.orderId.length; i++) {
    _calculateTotal();
    // }
    
  }, [data, data?.orderId]);

  const _calculateTotal = () => {
    setTotal();
    let _total = 0;
    if (data?.orderId) {
      for (let i = 0; i < data?.orderId?.length; i++) {
        if (data?.orderId[i]?.status === "SERVED") {
          _total += data?.orderId[i]?.quantity * data?.orderId[i]?.price;
        }
      }
    }
    // alert(_total);
    setTotal(_total);
  };

  return (
    <Modal
      show={show}
      size={"lg"}
      onHide={hide}
      arialabelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>ລາຍລະອຽດບິນ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <pre style={{ fontSize: 30, fontWeight: "bold", margin: 0 }}>
          ໂຕະ:{tableData?.tableName}
        </pre>
        <pre style={{ fontSize: 16, fontWeight: "bold", margin: 0 }}>
          ລະຫັດ:{tableData?.code}
        </pre>
        <pre style={{ fontSize: 16, fontWeight: "bold", margin: 0 }}>
          ເປີດເມື່ອ:
          {moment(tableData?.createdAt).format("DD-MMMM-YYYY HH:mm:ss")}
        </pre>
        <Table responsive>
          <thead style={{ backgroundColor: "#F1F1F1" }}>
            <tr>
              <th style={{ maxWidth: 20, width: 20 }}>#</th>
              <th>ຊື່ເມນູ</th>
              <th>ລາຄາ</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data?.orderId?.map((orderItem, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {orderItem?.name ?? "-"}
                      <br />
                      ລາຄາ/ອັນ: {moneyCurrency(orderItem?.price)}
                      <br />
                      ຈຳນວນ: {orderItem?.quantity}
                    </td>
                    <td>
                      {orderItem?.price
                        ? moneyCurrency(orderItem?.price * orderItem?.quantity)
                        : "-"}
                    </td>
                  </tr>
                );
              })}

            <tr>
              <td colSpan="2" style={{ textAlign: "center" }}>
                ລາຄາລວມ:
              </td>
              <td colSpan="1">{moneyCurrency(total)} ກີບ</td>
            </tr>
            <tr>
              <td colSpan="2" style={{ textAlign: "center" }}>
                ສ່ວນຫຼຸດ:
              </td>
              <td colSpan="1">
                {moneyCurrency(data?.discount)}{" "}
                {data?.discountType !== "LAK" ? "%" : "ກີບ"}
              </td>
            </tr>
            <tr>
              <td colSpan="2" style={{ textAlign: "center" }}>
                ລາຄາລວມທີຕ້ອງຈ່າຍ:
              </td>
              <td colSpan="1">
                {data && data?.discountType === "LAK"
                  ? moneyCurrency(
                      total * (taxPercent * 0.01 + 1) - data?.discount > 0
                        ? total * (taxPercent * 0.01 + 1) - data?.discount
                        : 0
                    )
                  : moneyCurrency(
                      total * (taxPercent * 0.01 + 1) -
                        (total * (taxPercent * 0.01 + 1) * data?.discount) /
                          100 >
                        0
                        ? total * (taxPercent * 0.01 + 1) -
                            (total * (taxPercent * 0.01 + 1) * data?.discount) /
                              100
                        : 0
                    )}{" "}
                ກີບ
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            width: "100%",
            gap: 10,
          }}
        >
          <Button
            variant="warning"
            style={{
              fontSize: 30,
            }}
            onClick={() => onPrintBill()}
          >
            <FaPrint /> ພິມບິນ
          </Button>
          <Button
            // onClick={hide}
            style={{
              fontSize: 30,
            }}
            onClick={() => onSubmit()}
          >
            <GiCash /> ເຊັກບິນ
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
OrderCheckOut.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  data: PropTypes.array,
};
export default OrderCheckOut;
