import React from "react";
import { useField, FieldHookConfig } from "formik";
import { Form } from "react-bootstrap";

type Option = {
  value: string | number;
  label: string;
};

type CustomSelectProps = FieldHookConfig<string> & {
  label: string;
  options: Option[];
  require?: boolean;
};

const CustomSelect: React.FC<CustomSelectProps> = ({
  label,
  options,
  require,
  ...props
}) => {
  const [field, meta] = useField<string>(props);

  return (
    <div className="form-group">
      <label htmlFor={props.id || props.name}>
        {label} {require && <span style={{ color: "#F91B1B" }}>*</span>}
      </label>
      <Form.Control
        as="select"
        {...(field as any)}
        {...props}
        isInvalid={!!meta.error}
      >
        <option value="">---Select an option---</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Control>
      {meta.error ? (
        <div style={{ color: "red", marginLeft: 5, fontSize: 12 }}>
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

export default CustomSelect;
