import React from "react";

import { Tabs, Tab, Form } from "react-bootstrap";
import { TbComet } from "react-icons/tb";
import { TfiLayoutListThumbAlt } from "react-icons/tfi";
import { RiCheckDoubleFill } from "react-icons/ri";
import { FcCancel } from "react-icons/fc";
import OrderNewPage from "./OrderNewPage";
import OrderConfirmPage from "./OrderConfirmPage";
import OrderSuccessPage from "./OrderSuccessPage";
import OrderCancelPage from "./OrderCancelPage";

export default function OrderTabs() {
  return (
    <div style={{ padding: 10 }}>
      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
        <Form.Switch
          type="switch"
          id="auto-confirm-and-print"
          label="ຍືນຍັນອໍເດີ ແລະ ພິມບິນອັດຕະໂນມັດ"
        />
      </div>
      <Tabs className="nav-tab" defaultActiveKey="order-new">
        <Tab
          eventKey="order-new"
          title={
            <div>
              <TbComet />
              ອໍເດີໃໝ່
            </div>
          }
        >
          <OrderNewPage />
        </Tab>
        <Tab
          eventKey="order-confirm"
          title={
            <div>
              <TfiLayoutListThumbAlt />
              ອໍເດີຍືນຍັນ
            </div>
          }
        >
          <OrderConfirmPage />
        </Tab>
        <Tab
          eventKey="order-success"
          title={
            <div>
              <RiCheckDoubleFill />
              ອໍເດີສຳເລັດ
            </div>
          }
        >
           <OrderSuccessPage />
        </Tab>
        <Tab
          eventKey="order-cancel"
          title={
            <div>
              <FcCancel />
              ອໍເດີຍົກເລີກ
            </div>
          }
        >
          <OrderCancelPage />
        </Tab>
      </Tabs>
    </div>
  );
}
