import React, { useState, ReactNode, ReactElement, Children } from "react";
import { Button, ListGroup } from "react-bootstrap";
import { BsCaretDown } from "react-icons/bs";

interface ButtonDropdownProps {
  children: ReactNode;
  variant?: string;
  onChange?: (value: any) => void; // Replace `any` with the specific type of `value` if known
}

export default function ButtonDropdown({
  children,
  variant = "primary", // Default variant
  onChange,
}: ButtonDropdownProps) {
  const [select, setSelect] = useState<ReactNode>(
    Children.toArray(children)?.[0] ?? "ButtonDropdown"
  );
  const [value, setValue] = useState<any>();
  const [showList, setShowList] = useState(false);

  const handleChange = (value: any, childContent: ReactNode) => {
    setSelect(childContent);
    setValue(value);
    if (onChange) {
      onChange(value);
    }
    setShowList(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <Button
        variant={variant}
        onFocusCapture={() => {
          setShowList(true);
        }}
        onBlurCapture={() => {
          setTimeout(() => setShowList(false), 300);
        }}
      >
        {select} <BsCaretDown />
      </Button>

      <ListGroup
        style={{
          display: showList ? "block" : "none",
          position: "absolute",
          zIndex: 999,
          width: "100%",
        }}
      >
        {Children.map(children, (child, index) => {
          if (React.isValidElement(child)) {
            return (
              <ListGroup.Item
                action
                onClick={() =>
                  handleChange(child.props.value, child.props.children)
                }
              >
                {child.props.children}
              </ListGroup.Item>
            );
          }
          return null; // Safely ignore invalid children
        })}
      </ListGroup>
    </div>
  );
}
