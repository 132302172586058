import React from "react";
// page
import ReportStockPage from "../pages/report/ReportStockPage";
// import MemberPage from "../pages/MemberPage";
import SettingLayout from "../layouts/SettingLayout";
import SettingPage from "../pages/setting/SettingPage";
import StoreSettingPage from "../pages/setting/StoreSettingPage";

// eslint-disable-next-line
export default {
  path: "/setting",
  element: <SettingLayout />,
  children: [
    {
      path: "",
      element: <SettingPage />,
    },
    // {
    //   path: "members-report",
    //   element: <MemberPage />,
    // },
    {
      path: "stock",
      element: <ReportStockPage />,
    },
    {
      path: "store",
      element: <StoreSettingPage />,
    },
  ],
};
