import { Outlet } from "react-router-dom";
// page
import DashboardBanhouse from "../pages/banhouse/report/DashboardBanhouse";
import ProductPage from "../pages/banhouse/product/ProductPage";
import ProductCreatePage from "../pages/banhouse/product/ProductCreatePage";
import ProductOrderPage from "../pages/banhouse/product/ProductOrderPage";

// eslint-disable-next-line
export default {
  path: "/banhouse",
  element: <Outlet />,
  children: [
    {
      path: "report",
      element: <DashboardBanhouse />,
    },
    {
      path: "product",
      element: <Outlet />,
      children: [
        { path: "", element: <ProductPage /> },
        { path: "create-one", element: <ProductCreatePage /> },
        { path: "order", element: <ProductOrderPage /> },
      ],
    },
  ],
};
