import React, { useState } from "react";
import styled from "styled-components";

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  customAttribute?: string;
};

function Image({ ...other }: ImageProps) {
  const [fullScreen, setFullScreen] = useState(false);
  return (
    <div onClick={() => setFullScreen((prev) => !prev)}>
      <ImageCustom className={fullScreen ? "active" : ""} {...other} />
    </div>
  );
}

export default Image;

const ImageCustom = styled("img")({
  "&.active": {
    backgroundColor: "#fff",
    width: "100%",
    height: "100dvh",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    zIndex: 100,
  },
});
