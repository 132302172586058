// @ts-nocheck
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import html2canvas from "html2canvas";
import { base64ToBlob } from "../../helpers";

/**
 * component
 * */
import Loading from "../../components/Loading";
import UserCheckoutModal from "./components/UserCheckoutModal";
import OrderCheckOut from "./components/OrderCheckOut";
import UpdateDiscountOrder from "./components/UpdateDiscountOrder";
import FeedbackOrder from "./components/FeedbackOrder";
import { orderStatus } from "../../helpers";
import BillForCheckOut80 from "../../components/bill/BillForCheckOut80";
import BillForCheckOut58 from "../../components/bill/BillForCheckOut58";
import BillForChef80 from "../../components/bill/BillForChef80";
import BillForChef58 from "../../components/bill/BillForChef58";
import CheckOutPopup from "./components/CheckOutPopup";

/**
 * const
 **/
import { COLOR_APP } from "../../constants/index";
import { useStore } from "../../store";
import {
  END_POINT_SEVER,
  getLocalData,
} from "../../constants/api";
import { successAdd, errorAdd, warningAlert } from "../../helpers/sweetalert";
import { getHeaders } from "../../services/auth";
import { useNavigate, useParams } from "react-router-dom";
import { getBills } from "../../services/bill";
// import _ from "lodash";
import { updateOrderItem } from "../../services/order";
import PopUpAddDiscount from "../../components/popup/PopUpAddDiscount";
import { useTranslation } from "react-i18next";
import PopupOpenTable from "../../components/popup/PopupOpenTable";
import BillQRShortSmartOrdering80 from "../../components/bill/BillQRShortSmartOrdering80";
import TableBar from "../../components/table/TableBar";
import TableDetail from "../../components/table/TableDetail";

export default function TableList() {
  const navigate = useNavigate();
  const params = useParams();
  const number = params?.number;
  const activeTableId = params?.tableId;
  const { t } = useTranslation();

  // state
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [popup, setPopup] = useState({
    CheckOutType: false,
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (e) => {
    setShow1(true);
  };

  const handleSelectedCancelOrder = (e) =>
    setSeletedCancelOrderItem(e.target.value);

  const [openModalSetting, setOpenModalSetting] = useState(false);
  const [dataSettingModal, setDataSettingModal] = useState();
  const [feedbackOrderModal, setFeedbackOrderModal] = useState(false);

  const [checkoutModel, setCheckoutModal] = useState(false);
  const [menuItemDetailModal, setMenuItemDetailModal] = useState(false);
  const [dataBill, setDataBill] = useState({});
  const [modalAddDiscount, setModalAddDiscount] = useState(false);
  const [reload, setReload] = useState(false);
  const [totalAfterDiscount, setTotalAfterDiscount] = useState(0);
  const [total, setTotal] = useState(0);
  const [codeShortLink, setCodeShortLink] = useState(null);

  const { printerCounter, printers } = useStore();
  // provider
  const {
    isTableOrderLoading,
    orderItemForPrintBill,
    tableList,
    selectedTable,
    setSelectedTable,
    openTable,
    tableOrderItems,
    getTableDataStore,
    onSelectTable,
    resetTableOrder,
    storeDetail,
    getTableOrders,
    newTableTransaction,
    setNewTableTransaction,
    newOrderTransaction,
    setNewOrderTransaction,
    newOrderUpdateStatusTransaction,
    setNewOrderUpdateStatusTransaction,
    openTableAndReturnCodeShortLink,
  } = useStore();

  const reLoadData = () => {
    setReload(true);
  };
  useEffect(() => {
    if (reload) {
      getTableOrders(selectedTable);
      setReload(false);
    }
  }, [reload]);

  const [isCheckedOrderItem, setIsCheckedOrderItem] = useState([]);
  const [seletedOrderItem, setSeletedOrderItem] = useState();
  const [seletedCancelOrderItem, setSeletedCancelOrderItem] = useState("");
  const [checkedBox, setCheckedBox] = useState(true);
  const [taxPercent, setTaxPercent] = useState(0);

  useEffect(() => {
    const getDataTax = async () => {
      const { DATA } = await getLocalData();
      const _res = await axios.get(
        END_POINT_SEVER + "/v4/tax/" + DATA?.storeId
      );
      setTaxPercent(_res?.data?.taxPercent);
    };
    getDataTax();
  }, []);

  const canCheckOut = !tableOrderItems.find(
    (e) =>
      e?.status === "DOING" ||
      e?.status === "WAITING" ||
      e?.tableOrderItems?.length === 0
  )?._id;

  useEffect(() => {
    getTableDataStore();
  }, []);

  /**
   * Modify Order Status
   */
  useEffect(() => {
    setIsCheckedOrderItem([...tableOrderItems]);
  }, [selectedTable, tableOrderItems]);

  const _handlecheckout = async () => {
    setCheckoutModal(false);
    navigate(
      `/tables/pagenumber/${number}/tableid/${activeTableId}/${params?.storeId}`
    );
  };
  const _onCheckOut = async () => {
    setMenuItemDetailModal(true);
  };
  const _goToAddOrder = (tableId, code) => {
    navigate(`/addOrder/tableid/${tableId}/code/${code}`);
  };

  useEffect(() => {
    if (tableOrderItems?.length > 0) {
      getData(tableOrderItems[0]?.code);
    } else {
      setDataBill({});
    }
  }, [tableOrderItems]);

  const getData = async (code) => {
    try {
      setDataBill({});
      let header = await getHeaders();
      const headers = {
        "Content-Type": "application/json",
        Authorization: header.authorization,
      };
      let findby = "?";
      findby += `code=${code}`;
      const _bills = await getBills(findby);
      const _billId = _bills?.[0]?.["_id"];
      const _resBill = await axios({
        method: "get",
        url: END_POINT_SEVER + `/v3/bill-group/` + _billId,
        headers: headers,
      });
      setDataBill(_resBill?.data);
    } catch (err) {
      setDataBill({});
    }
  };

  const [selectNewTable, setSelectNewTable] = useState();

  const _changeTable = async () => {
    if (!selectNewTable) {
      handleClose();
      await Swal.fire({
        icon: "warning",
        title: "ກະລຸນາເລືອກໂຕະ",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    try {
      const _billsNew = await getBills(`?_id=${selectNewTable?.billId}`);
      const _billIdNew = _billsNew?.[0]?.["_id"];

      const _billsOld = await getBills(`?_id=${selectedTable?.billId}`);
      const _billIdOld = _billsOld?.[0]?.["_id"];

      // const _codesNew = await getCodes(`?_id=${selectNewTable?._id}`);
      // const _codeIdNew = _codesNew?.[0]?.["_id"];

      let header = await getHeaders();
      const headers = {
        "Content-Type": "application/json",
        Authorization: header.authorization,
      };
      const changTable = await axios({
        method: "put",
        url: END_POINT_SEVER + `/v3/bill-transfer`,
        data: {
          billOld: _billIdOld,
          billNew: _billIdNew ?? "NOT_BILL",
          // codeId: _codeIdNew,
        },
        headers: headers,
      });
      if (changTable?.status === 200) {
        handleClose();
        setSelectedTable();
        getTableDataStore();
        await Swal.fire({
          icon: "success",
          title: "ການປ່ຽນໂຕະສໍາເລັດ",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "ການປ່ຽນໂຕະບໍ່ສໍາເລັດ",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    setSelectNewTable();
  };

  const _openModalSetting = (data) => {
    setDataSettingModal(data);
    setOpenModalSetting(true);
  };

  const _resetTable = async () => {
    try {
      if (tableOrderItems?.length > 0) {
        setOpenModalSetting(false);
        warningAlert("ບໍ່ສາມາດປິດໂຕະໄດ້ເພາະມີການໃຊ້ງານ...!");
        return;
      }
      let header = await getHeaders();
      const headers = {
        "Content-Type": "application/json",
        Authorization: header.authorization,
      };
      const updateTable = await axios({
        method: "put",
        url: END_POINT_SEVER + `/v3/code/update`,
        data: {
          id: dataSettingModal?._id,
          data: {
            isOpened: false,
            isStaffConfirm: false,
          },
        },
        headers: headers,
      });
      setOpenModalSetting(false);
      if (updateTable.status < 300) {
        setSelectedTable();
        getTableDataStore();
        successAdd("ການປິດໂຕະສຳເລັດ");
      }
    } catch (err) {
      errorAdd("ການປິດໂຕະບໍ່ສຳເລັດ");
    }
  };
  const [widthBill80, setWidthBill80] = useState(0);
  const [widthBill58, setWidthBill58] = useState(0);

  let qrSmartOrder80Ref = useRef(null);

  let bill80Ref = useRef(null);
  let bill58Ref = useRef(null);
  useLayoutEffect(() => {
    setWidthBill80(bill80Ref.current.offsetWidth);
    setWidthBill58(bill58Ref.current.offsetWidth);
  }, [bill80Ref, bill58Ref]);

  const onPrintBill = async () => {
    try {
      let urlForPrinter = "";
      const _printerCounters = JSON.parse(printerCounter?.prints);
      const printerBillData = printers?.find(
        (e) => e?._id === _printerCounters?.BILL
      );
      let dataImageForPrint;
      if (printerBillData?.width === "80mm") {
        dataImageForPrint = await html2canvas(bill80Ref.current, {
          useCORS: true,
          scrollX: 10,
          scrollY: 0,
          scale: 530 / widthBill80,
        });
      }

      if (printerBillData?.width === "58mm") {
        dataImageForPrint = await html2canvas(bill58Ref.current, {
          useCORS: true,
          scrollX: 10,
          scrollY: 0,
          scale: 350 / widthBill58,
        });
      }
      if (printerBillData?.type === "ETHERNET") {
        urlForPrinter = "http://localhost:9150/ethernet/image";
      }
      if (printerBillData?.type === "BLUETOOTH") {
        urlForPrinter = "http://localhost:9150/bluetooth/image";
      }
      if (printerBillData?.type === "USB") {
        urlForPrinter = "http://localhost:9150/usb/image";
      }

      const _file = await base64ToBlob(dataImageForPrint.toDataURL());
      var bodyFormData = new FormData();
      bodyFormData.append("ip", printerBillData?.ip);
      bodyFormData.append("port", "9100");
      bodyFormData.append("image", _file);
      bodyFormData.append("beep1", 1);
      bodyFormData.append("beep2", 9);

      await axios({
        method: "post",
        url: urlForPrinter,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      await Swal.fire({
        icon: "success",
        title: "ປິນສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (err) {
      console.log("err printer", err);
      await Swal.fire({
        icon: "error",
        title: "ປິນບໍ່ສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      });
      return err;
    }
  };
  async function delay(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
  useEffect(() => {
    if (codeShortLink) {
      onPrintQR(codeShortLink);
    }
  }, [codeShortLink]);
  const onPrintQR = async (tokenQR) => {
    try {
      if (!tokenQR) {
        return;
      }
      let urlForPrinter = "";
      const _printerCounters = JSON.parse(printerCounter?.prints);
      const printerBillData = printers?.find(
        (e) => e?._id === _printerCounters?.BILL
      );
      let dataImageForPrint;
      if (printerBillData?.width === "80mm") {
        dataImageForPrint = await html2canvas(qrSmartOrder80Ref.current, {
          useCORS: true,
          scrollX: 10,
          scrollY: 0,
          scale: 530 / widthBill80,
        });
      }

      if (printerBillData?.width === "58mm") {
        dataImageForPrint = await html2canvas(qrSmartOrder80Ref.current, {
          useCORS: true,
          scrollX: 10,
          scrollY: 0,
          scale: 350 / widthBill58,
        });
      }
      if (printerBillData?.type === "ETHERNET") {
        urlForPrinter = "http://localhost:9150/ethernet/image";
      }
      if (printerBillData?.type === "BLUETOOTH") {
        urlForPrinter = "http://localhost:9150/bluetooth/image";
      }
      if (printerBillData?.type === "USB") {
        urlForPrinter = "http://localhost:9150/usb/image";
      }

      const _file = await base64ToBlob(dataImageForPrint.toDataURL());
      var bodyFormData = new FormData();
      bodyFormData.append("ip", printerBillData?.ip);
      bodyFormData.append("port", "9100");
      bodyFormData.append("image", _file);
      bodyFormData.append("beep1", 1);
      bodyFormData.append("beep2", 9);

      await axios({
        method: "post",
        url: urlForPrinter,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      setCodeShortLink(null);
      await Swal.fire({
        icon: "success",
        title: "ປິນສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      });
      setCodeShortLink(null);
    } catch (err) {
      setCodeShortLink(null);
      console.log(err);
      await Swal.fire({
        icon: "error",
        title: "ປິນບໍ່ສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const arrLength = isCheckedOrderItem?.filter((e) => e?.isChecked).length;

  const billForCher80 = useRef([]);
  const billForCher58 = useRef([]);

  if (billForCher80.current.length !== arrLength) {
    // add or remove refs
    billForCher80.current = Array(arrLength)
      .fill()
      .map((_, i) => billForCher80.current[i]);
  }
  if (billForCher58.current.length !== arrLength) {
    // add or remove refs
    billForCher58.current = Array(arrLength)
      .fill()
      .map((_, i) => billForCher58.current[i]);
  }
  const [onPrinting, setOnPrinting] = useState(false);
  const onPrintForCher = async () => {
    setOnPrinting(true);
    const orderSelect = isCheckedOrderItem?.filter((e) => e?.isChecked);
    let _index = 0;
    const printDate = [...billForCher80.current];
    let dataUrls = [];
    for (const _ref of printDate) {
      const dataUrl = await html2canvas(_ref, {
        useCORS: true,
        scrollX: 10,
        scrollY: 0,
        scale: 530 / widthBill80,
      });
      dataUrls.push(dataUrl);
    }
    for (const _ref of printDate) {
      const _printer = printers.find((e) => {
        return e?._id === orderSelect?.[_index]?.printer;
      });

      try {
        let urlForPrinter = "";
        let dataUrl = dataUrls[_index];
        if (_printer?.type === "ETHERNET") {
          urlForPrinter = "http://localhost:9150/ethernet/image";
        }
        if (_printer?.type === "BLUETOOTH") {
          urlForPrinter = "http://localhost:9150/bluetooth/image";
        }
        if (_printer?.type === "USB") {
          urlForPrinter = "http://localhost:9150/usb/image";
        }

        const _file = await base64ToBlob(dataUrl.toDataURL());
        var bodyFormData = new FormData();
        bodyFormData.append("ip", _printer?.ip);
        bodyFormData.append("port", "9100");
        bodyFormData.append("image", _file);
        if (_index === 0) {
          bodyFormData.append("beep1", 1);
          bodyFormData.append("beep2", 9);
        }
        await axios({
          method: "post",
          url: urlForPrinter,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (_index === 0) {
          await Swal.fire({
            icon: "success",
            title: "ປິ້ນສຳເລັດ",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (err) {
        console.log(err);
        if (_index === 0) {
          await Swal.fire({
            icon: "error",
            title: "ປິ້ນບໍ່ສຳເລັດ",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
      _index++;
    }
    setOnPrinting(false);
  };

  const onSelect = (data) => {
    const _data = isCheckedOrderItem.map((e) => {
      if (data?._id === e?._id) {
        return data;
      } else {
        return e;
      }
    });
    setIsCheckedOrderItem(_data);

    const _isChecked = _data.filter((e) => {
      if (e?.isChecked) {
        return true;
      }
      return false;
    });

    if (_isChecked.length === 0) {
      setCheckedBox(true);
    } else {
      setCheckedBox(false);
    }
  };

  const onPrintDrawer = async () => {
    try {
      let urlForPrinter = "";
      const _printerCounters = JSON.parse(printerCounter?.prints);
      const printerBillData = printers?.find(
        (e) => e?._id === _printerCounters?.BILL
      );

      if (printerBillData?.type === "ETHERNET") {
        urlForPrinter = "http://localhost:9150/ethernet/drawer";
      }
      if (printerBillData?.type === "BLUETOOTH") {
        urlForPrinter = "http://localhost:9150/bluetooth/drawer";
      }
      if (printerBillData?.type === "USB") {
        urlForPrinter = "http://localhost:9150/usb/drawer";
      }

      var bodyFormData = new FormData();
      bodyFormData.append("ip", printerBillData?.ip);
      bodyFormData.append("port", "9100");

      // await axios({
      //   method: "post",
      //   url: urlForPrinter,
      //   data: bodyFormData,
      //   headers: { "Content-Type": "multipart/form-data" },
      // });

      await axios.post(urlForPrinter, {
        ip: printerBillData?.ip,
        port: 9100,
      });
    } catch (err) {
      console.log(err);
      await Swal.fire({
        icon: "error",
        title: "ເປີດລີ້ນຊັກບໍ່ສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const checkAllOrders = (item) => {
    let _newOrderItems = [];
    if (item?.target?.checked) {
      _newOrderItems = tableOrderItems.map((item) => {
        if (item?.status === "CANCELED") return item;
        return {
          ...item,
          isChecked: true,
        };
      });
    } else {
      _newOrderItems = isCheckedOrderItem.map((item) => {
        return {
          ...item,
          isChecked: false,
        };
      });
    }
    setIsCheckedOrderItem(_newOrderItems);
  };

  const handleUpdateOrderStatus = async (status) => {
    const storeId = storeDetail?._id;
    let menuId;
    let _updateItems = isCheckedOrderItem
      ?.filter((e) => e?.isChecked)
      .map((i) => {
        return {
          status: status,
          _id: i?._id,
          menuId: i?.menuId,
        };
      });
    let _resOrderUpdate = await updateOrderItem(_updateItems, storeId, menuId);
    if (_resOrderUpdate?.data?.message === "UPADTE_ORDER_SECCESS") {
      reLoadData();
      setCheckedBox(!checkedBox);
      Swal.fire({
        icon: "success",
        title: "ອັບເດດສະຖານະອໍເດີສໍາເລັດ",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const handleUpdateOrderStatusgo = async (status) => {
    const storeId = storeDetail?._id;
    let menuId;
    let _updateItems = isCheckedOrderItem
      ?.filter((e) => e?.isChecked)
      .map((i) => {
        return {
          status: status,
          _id: i?._id,
          menuId: i?.menuId,
        };
      });
    let _resOrderUpdate = await updateOrderItem(_updateItems, storeId, menuId);
    if (_resOrderUpdate?.data?.message === "UPADTE_ORDER_SECCESS") {
      reLoadData();
      setCheckedBox(!checkedBox);
      Swal.fire({
        icon: "success",
        title: "ອັບເດດສະຖານະອໍເດີສໍາເລັດ",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const handleUpdateOrderStatuscancel = async (status) => {
    // getOrderItemsStore(CANCEL_STATUS);
    const storeId = storeDetail?._id;
    // let previousStatus = orderItems[0].status;
    let menuId;
    let _updateItems = isCheckedOrderItem
      ?.filter((e) => e?.isChecked)
      .map((i) => {
        return {
          status: status,
          _id: i?._id,
          menuId: i?.menuId,
          // remark: seletedCancelOrderItem
        };
      });
    let _resOrderUpdate = await updateOrderItem(
      _updateItems,
      storeId,
      menuId,
      seletedCancelOrderItem
    );
    if (_resOrderUpdate?.data?.message === "UPADTE_ORDER_SECCESS") {
      handleClose1();
      reLoadData();
      setCheckedBox(!checkedBox);
      // if (previousStatus === CANCEL_STATUS) getOrderItemsStore(CANCEL_STATUS);
      Swal.fire({
        icon: "success",
        title: "ອັບເດດສະຖານະອໍເດີສໍາເລັດ",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const handleMessage = (event) => {
    reLoadData();
  };
  useEffect(() => {
    if (!onPrinting) {
      if (!reload) {
        if (newOrderTransaction || newOrderUpdateStatusTransaction) {
          handleMessage();
          setNewOrderTransaction(false);
          setNewOrderUpdateStatusTransaction(false);
        }
      }
    }
  }, [newOrderTransaction, onPrinting, newOrderUpdateStatusTransaction]);

  useEffect(() => {
    if (newTableTransaction) {
      getTableDataStore();
      setNewTableTransaction(false);
    }
  }, [newTableTransaction]);

  // useEffect
  useEffect(() => {
    _calculateTotal();
    
  }, [dataBill]);
  // function
  const _calculateTotal = () => {
    let _total = 0;
    for (let _data of dataBill?.orderId || []) {
      _total += _data?.quantity * _data?.price;
    }
    if (dataBill?.discount > 0) {
      if (
        dataBill?.discountType === "LAK" ||
        dataBill?.discountType === "MONEY"
      ) {
        setTotalAfterDiscount(_total - dataBill?.discount);
      } else {
        const ddiscount = parseInt((_total * dataBill?.discount) / 100);
        setTotalAfterDiscount(_total - ddiscount);
      }
    } else {
      setTotalAfterDiscount(_total);
    }
    setTotal(_total);
  };
  return (
    <div
      style={{
        backgroundColor: "#F9F9F9",
        height: "calc(100dvh - 66px)",
        overflow: "hidden",
        width: "100%",
      }}
    >
      {isTableOrderLoading ? <Loading /> : ""}
      <div>
        <div
          style={{
            display: "flex",
            overflow: "hidden",
            height: "calc(100dvh - 66px)",
          }}
        >
          <TableDetail
            selectedTable={selectedTable}
            setSelectedTable={setSelectedTable}
            dataBill={dataBill}
            total={total}
            handleUpdateOrderStatusgo={handleUpdateOrderStatusgo}
            onSelect={onSelect}
            checkedBox={checkedBox}
            totalAfterDiscount={totalAfterDiscount}
            isCheckedOrderItem={isCheckedOrderItem}
            onPrintForCher={onPrintForCher}
            onPrinting={onPrinting}
            handleShow={handleShow}
            _openModalSetting={_openModalSetting}
            canCheckOut={canCheckOut}
            _goToAddOrder={_goToAddOrder}
            setPopup={setPopup}
            handleShow1={handleShow1}
            checkAllOrders={checkAllOrders}
            _onCheckOut={_onCheckOut}
            tableOrderItems={tableOrderItems}
            setCodeShortLink={setCodeShortLink}
            handleUpdateOrderStatus={handleUpdateOrderStatus}
            setCheckedBox={setCheckedBox}
            openTable={openTable}
            openTableAndReturnCodeShortLink={openTableAndReturnCodeShortLink}
            orderStatus={orderStatus}
            t={t}
          />
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <TableBar />
            <div
              style={{
                overflowY: "scroll",
                flexGrow: 1,
                width: "100%",
              }}
            >
              <div
                style={{
                  padding: 10,
                  display: "grid",
                  gap: 5,
                  position: "relative",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  width: "100%",
                  minHeight: "calc( 100% - 20px )",
                }}
              >
                {tableList &&
                  tableList?.map((table, index) => (
                    <div
                      style={{
                        border:
                          selectedTable?.code === table?.code
                            ? "1px solid #404258"
                            : "1px solid #87A922",
                        backgroundColor:
                          selectedTable?.code === table?.code
                            ? "#404258"
                            : "#FFF",
                        overflow: "hidden",
                        cursor: "pointer",
                        zIndex: 10,
                        aspectRatio: 1.5,
                      }}
                      key={"table" + index}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          border: "none",
                          background: table?.isStaffConfirm
                            ? table?.editBill
                              ? "red"
                              : COLOR_APP
                            : "white",
                          backgroundImage:
                            "repeating-linear-gradient(45deg, #ffffff1a 0px, #ffffff1a 10px, transparent 10px, transparent 20px, #ffffff1a 20px)",
                          border:
                            selectedTable?.code === table?.code
                              ? "3px solid #30573b"
                              : "3px solid  white",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          overflow: "hidden",
                          // height: 100,
                          padding: 10,
                        }}
                        onClick={() => {
                          onSelectTable(table);
                        }}
                      >
                        <div
                          style={{
                            fontSize: 18,
                            color: table?.isStaffConfirm ? "white" : "#616161",
                            fontWeight: "bold",
                          }}
                        >
                          <div>{table?.tableName}</div>
                          {/* <div>{table?.code}</div> */}
                          <div style={{ fontSize: 10 }}>
                            {table?.isStaffConfirm ? "Open" : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    height: "calc( 100% + 20px )",
                    zIndex: 0,
                  }}
                  onClick={() => setSelectedTable()}
                />
              </div>

              <div style={{ height: 20 }} />
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "80mm", padding: 10 }} ref={bill80Ref}>
        <BillForCheckOut80
          storeDetail={storeDetail}
          selectedTable={selectedTable}
          dataBill={dataBill}
          taxPercent={taxPercent}
        />
      </div>
      <div style={{ width: "80mm", padding: 10 }} ref={qrSmartOrder80Ref}>
        <BillQRShortSmartOrdering80
          tableName={selectedTable?.tableName}
          CodeShortLink={codeShortLink}
        />
      </div>
      <div style={{ width: "58mm", padding: 10 }} ref={bill58Ref}>
        <BillForCheckOut58
          storeDetail={storeDetail}
          selectedTable={selectedTable}
          dataBill={dataBill}
        />
      </div>
      {isCheckedOrderItem
        ?.filter((e) => e?.isChecked)
        .map((val, i) => {
          return (
            <div
              style={{ width: "80mm", padding: 10 }}
              ref={(el) => (billForCher80.current[i] = el)}
            >
              <BillForChef80
                storeDetail={storeDetail}
                selectedTable={selectedTable}
                dataBill={dataBill}
                val={val}
              />
            </div>
          );
        })}
      <div>
        {isCheckedOrderItem
          ?.filter((e) => e?.isChecked)
          .map((val, i) => {
            return (
              <div
                style={{ width: "80mm", padding: 10 }}
                ref={(el) => (billForCher58.current[i] = el)}
              >
                <BillForChef58
                  storeDetail={storeDetail}
                  selectedTable={selectedTable}
                  dataBill={dataBill}
                  val={val}
                />
              </div>
            );
          })}
      </div>

      <CheckOutPopup
        onPrintBill={onPrintBill}
        onPrintDrawer={onPrintDrawer}
        dataBill={dataBill}
        tableData={selectedTable}
        open={popup?.CheckOutType}
        onClose={() => setPopup()}
        setDataBill={setDataBill}
        taxPercent={taxPercent}
      />
      {/* <CheckOutType
        onPrintBill={onPrintBill}
        dataBill={dataBill}
        tableData={selectedTable}
        open={popup?.CheckOutType}
        onClose={() => setPopup()}
        setDataBill={setDataBill}
        taxPercent={taxPercent}
      /> */}

      <OrderCheckOut
        data={dataBill}
        onPrintBill={onPrintBill}
        tableData={selectedTable}
        show={menuItemDetailModal}
        resetTableOrder={resetTableOrder}
        hide={() => setMenuItemDetailModal(false)}
        taxPercent={taxPercent}
        onSubmit={() => {
          setMenuItemDetailModal(false);
          setPopup({ CheckOutType: true });
        }}
      />

      <UpdateDiscountOrder
        data={tableOrderItems}
        tableData={selectedTable}
        show={modalAddDiscount}
        resetTableOrder={resetTableOrder}
        hide={() => setModalAddDiscount(false)}
      />

      <FeedbackOrder
        data={orderItemForPrintBill}
        tableData={selectedTable}
        show={feedbackOrderModal}
        hide={() => setFeedbackOrderModal(false)}
      />

      <UserCheckoutModal
        show={checkoutModel}
        hide={() => setCheckoutModal(false)}
        tableId={selectedTable?.code}
        func={_handlecheckout}
      />
      <PopupOpenTable
        open={popup?.openTable}
        code={selectedTable}
        onClose={() => {
          setPopup();
        }}
        onSubmit={async () => {
          openTable().then(() => {
            navigate(`/staff/tableDetail/${selectedTable?._id}`);
            setPopup();
          });
          // getData();
        }}
      />

      <PopUpAddDiscount
        open={popup?.discount}
        value={isCheckedOrderItem}
        dataBill={dataBill}
        onClose={() => {
          setPopup();
        }}
        onSubmit={async () => {
          handleMessage();
          getData();
        }}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>ລວມໂຕະ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form.Label>ຍ້າຍຈາກໂຕະ : {selectedTable?.tableName}</Form.Label>
            <div style={{ height: 10 }}></div>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>ໄປຫາໂຕະ : </Form.Label>
              <div style={{ height: 10 }}></div>
              <select
                className="form-select form-control"
                aria-label="Default select example"
                value={selectNewTable?._id}
                onChange={(e) => {
                  const _select = tableList?.find(
                    (item) => e.target.value === item?._id
                  );
                  setSelectNewTable(_select);
                }}
              >
                <option selected disabled>
                  ເລືອກໂຕະ
                </option>
                {tableList?.map((item, index) => (
                  <option
                    key={"talbe-" + index}
                    value={item?._id}
                    disabled={
                      selectedTable?.tableName === item?.tableName
                        ? true
                        : false
                    }
                  >
                    ໂຕະ {item?.tableName}
                  </option>
                ))}
              </select>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleClose()}>
            ຍົກເລີກ
          </Button>
          <Button variant="success" onClick={() => _changeTable()}>
            ລວມໂຕະ
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>ເຫດຜົນຍົກເລີກອາຫານ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <select
              size="8"
              style={{ overflow: "auto", border: "none", fontSize: "20px" }}
              className="form-control"
              onChange={handleSelectedCancelOrder}
            >
              {/* value={seletedCancelOrderItem?.remark} */}
              <option
                style={{ borderBottom: "1px #ccc solid", padding: "10px 0" }}
              >
                ເສີບອາຫານຜິດໂຕະ
              </option>
              <option
                style={{ borderBottom: "1px #ccc solid", padding: "10px 0" }}
              >
                ລູກຄ້າຍົກເລີກ
              </option>
              <option
                style={{ borderBottom: "1px #ccc solid", padding: "10px 0" }}
              >
                ຄົວເຮັດອາຫານຜິດ
              </option>
              <option
                style={{ borderBottom: "1px #ccc solid", padding: "10px 0" }}
              >
                ພະນັກງານເສີບ ຄີອາຫານຜິດ
              </option>
              <option
                style={{ borderBottom: "1px #ccc solid", padding: "10px 0" }}
              >
                ອາຫານດົນ
              </option>
              <option
                style={{ borderBottom: "1px #ccc solid", padding: "10px 0" }}
              >
                ອາຫານໝົດ
              </option>
              <option
                style={{ borderBottom: "1px #ccc solid", padding: "10px 0" }}
              >
                drinkIsGone
              </option>
              <option
                style={{ borderBottom: "1px #ccc solid", padding: "10px 0" }}
              >
                ບໍ່ມີອາຫານໃນໂຕະ
              </option>
            </select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleClose1()}>
            ຍົກເລີກ
          </Button>
          <Button
            variant="success"
            onClick={() => handleUpdateOrderStatuscancel("CANCELED")}
          >
            ບັນທຶກ
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={openModalSetting} onHide={() => setOpenModalSetting(false)}>
        <Modal.Header closeButton>
          <Modal.Title>ຕັ້ງຄ່າໂຕະ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            ທ່ານຕ້ອງການປິດໂຕະ {dataSettingModal?.tableName} ນີ້ບໍ ?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setOpenModalSetting(false)}>
            ຍົກເລີກ
          </Button>
          <Button variant="success" onClick={() => _resetTable()}>
            ປິດໂຕະ
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
