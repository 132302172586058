import React from "react";
import { Breadcrumb, Card } from "react-bootstrap";
import ReportChartDayOfWeek from "../../../components/report_chart/ReportChartDayOfWeek";

type Props = {};

export default function ReportPage({}: Props) {
  return (
    <div className="p-4">
      <Breadcrumb>
        <Breadcrumb.Item>THERDY CO</Breadcrumb.Item>
        <Breadcrumb.Item active>ລາຍງານ</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gap: 10,
        }}
      >
        <Card border="primary">
          <Card.Header>MooGold</Card.Header>
          <Card.Body>
            <li>0 USD</li>
            <li>0 THB</li>
          </Card.Body>
        </Card>
        <Card border="primary">
          <Card.Header>MBLaos</Card.Header>
          <Card.Body>
            <li>0 LAK</li>
          </Card.Body>
        </Card>
        <Card border="primary">
          <Card.Header>Binance</Card.Header>
          <Card.Body>
            <li>0 USDT</li>
            <li>0 TRX</li>
            <li>0 ETH</li>
          </Card.Body>
        </Card>
        <Card border="primary">
          <Card.Header>Profit</Card.Header>
          <Card.Body>
            <li>0 USDT</li>
            <li>0 LAK</li>

            <hr/>
            <li>Total ≈ 0 USD</li>
          </Card.Body>
        </Card>
        <Card border="primary" style={{ gridColumn: "span 2" }}>
          <Card.Header>ລາຍຮັບ/ລາຍຈ່າຍ</Card.Header>
          <Card.Body>
            <ReportChartDayOfWeek />
          </Card.Body>
        </Card>
        <Card border="primary" style={{ gridColumn: "span 2" }}>
          <Card.Header>ຍອດເງິນທັງໝົດ</Card.Header>
          <Card.Body>
            <ReportChartDayOfWeek />
          </Card.Body>
        </Card>
        <Card border="primary" style={{ gridColumn: "span 2" }}>
          <Card.Header>Lock Price</Card.Header>
          <Card.Body>
            <ReportChartDayOfWeek />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
