import React from "react";
import styled from "styled-components";
import moment from "moment";
import { moneyCurrency } from "../../helpers";

interface BillForChef80Props {
  selectedTable: {
    name: string;
  };
  dataBill: any;
  val: {
    tableId?: {
      name: string;
    };
    code?: string;
    name?: string;
    quantity?: number;
    price?: number;
    createdBy?: {
      firstname: string;
    };
    createdAt?: string;
  };
}

const BillForChef80: React.FC<BillForChef80Props> = ({
  selectedTable,
  dataBill,
  val,
}) => {
  return (
    <div style={{ background: "#fff" }}>
      <Container>
        <table
          style={{
            tableLayout: "fixed",
            borderCollapse: "collapse",
            width: "100%",
            lineHeight: 0,
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  background: "#000",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                {val?.tableId?.name || selectedTable?.name}
              </td>
              <td
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: 14,
                }}
              >
                {val?.code}
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: 20,
                  textAlign: "left",
                }}
              >
                {val?.name} ({val?.quantity})
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{
                  color: "#000",
                  fontSize: 14,
                  textAlign: "left",
                }}
              >
                {moneyCurrency(val?.price)} x {val?.quantity}
              </td>
            </tr>
            <tr>
              <td colSpan={2} style={{ borderTop: "1px dotted #000" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: 14,
                    }}
                  >
                    {val?.createdBy?.firstname}
                  </div>
                  <div style={{ fontSize: 12 }}>
                    {moment(val?.createdAt).format("DD/MM/YY")} |{" "}
                    {moment(val?.createdAt).format("LT")}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Container>
      <div style={{ height: 10 }} />
    </div>
  );
};

const Container = styled.div`
  width: 100%;
  min-width: 200px;
  max-width: 200px;
  border: 1px solid #000;
  background-color: #fff;
`;

export default BillForChef80;
