import React, { useEffect, useState } from "react";
import { COLOR_APP } from "../../constants";
import { Button, Form, Card } from "react-bootstrap";
import { getLocalData } from "../../constants/api";
import { Breadcrumb, Tab, Tabs } from "react-bootstrap";
import { MdAssignmentAdd } from "react-icons/md";
import { IoBeerOutline } from "react-icons/io5";
import { getBillFarks } from "../../services/fark";
import { useStore } from "../../store";
import { useNavigate } from "react-router-dom";
import moment from "moment";
// import PopUpDetaillBillFark from "../../components/popup/PopUpDetaillBillFark";
import { convertBillFarkStatus } from "../../helpers/convertBillFarkStatus";

interface BillFarkData {
  _id: string;
  code: string;
  stockStatus: string;
  createdAt: string;
  endDate: string;
  outStockDate?: string;
}

interface PopupState {
  PopUpDetaillBillFark?: boolean;
}


export default function FarkPage() {
  const navigate = useNavigate();

  // state
  const [billFarkData, setBillFarkData] = useState<BillFarkData[]>();
  const [selectBillFark, setSelectBillFark] = useState<
    BillFarkData | undefined
  >();
  const [popup, setPopup] = useState<PopupState>();

  // store
  const { storeDetail }: any = useStore();

  // useEffect
  useEffect(() => {
    getData();
  }, []);

  // function
  const getData = async () => {
    try {
      const { TOKEN } = await getLocalData();
      let findby = "?";
      findby += `storeId=${storeDetail?._id}`;
      const data = await getBillFarks(findby, TOKEN);
      setBillFarkData(data);
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <div style={{ padding: 20 }}>
        <Breadcrumb>
          <Breadcrumb.Item>ຝັງຊັ້ນຝາກ</Breadcrumb.Item>
          <Breadcrumb.Item active>ລາຍການຝາກ</Breadcrumb.Item>
        </Breadcrumb>
        <Tabs defaultActiveKey="billFark-list">
          <Tab
            eventKey="billFark-list"
            title="ລາຍການຝາກທັງໝົດ"
            style={{ paddingTop: 20 }}
          >
            <div style={{ display: "flex", gap: 10, padding: "10px 0" }}>
              <Form.Control
                style={{ maxWidth: 220 }}
                placeholder="ຄົ້ນຫາລະຫັດບິນ"
              />
              <Button variant="primary">ຄົ້ນຫາ</Button>
            </div>

            <Card border="primary" style={{ margin: 0 }}>
              <Card.Header
                style={{
                  backgroundColor: COLOR_APP,
                  color: "#fff",
                  fontSize: 18,
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                <span>
                  <IoBeerOutline /> ລາຍການຝາກ
                </span>
                <Button variant="dark" onClick={() => navigate("/fark/create")}>
                  <MdAssignmentAdd /> ເພີ່ມລາຍການ
                </Button>
              </Card.Header>
              <Card.Body>
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>ລະຫັດບິນ</th>
                      {/* <th>ຈຳນວນອໍເດີ</th> */}
                      <th>ສະຖານະ</th>
                      <th>ວັນທີສ້າງລາຍການ</th>
                      <th>ໝົດກຳນົດ</th>
                      <th>ວັນທີມາເອົາ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {billFarkData?.map((e, i) => (
                      <tr
                        key={e._id}
                        onClick={() => {
                          setPopup({ PopUpDetaillBillFark: true });
                          setSelectBillFark(e);
                        }}
                      >
                        <td style={{ textAlign: "start" }}>{i + 1}</td>
                        <td style={{ textAlign: "start" }}>{e?.code}</td>
                        {/* <td style={{ textAlign: "start" }}>0</td> */}
                        <td style={{ textAlign: "start" }}>
                          <div>{convertBillFarkStatus(e?.stockStatus)}</div>
                        </td>
                        <td style={{ textAlign: "start" }}>
                          {moment(e?.createdAt).format("DD/MM/YYYY")}
                        </td>
                        <td style={{ textAlign: "start" }}>
                          {moment(e?.endDate).format("DD/MM/YYYY")}
                        </td>
                        <td style={{ textAlign: "start" }}>
                          {e?.outStockDate
                            ? moment(e?.outStockDate).format("DD/MM/YYYY")
                            : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </Tab>
          <Tab
            disabled
            eventKey="currency-list"
            title="ປະຫວັດລາຍການຝາກ"
            style={{ paddingTop: 20 }}
          >
            <div style={{ display: "flex", gap: 10, padding: "10px 0" }}>
              <Form.Control
                style={{ maxWidth: 220 }}
                placeholder="ຄົ້ນຫາລະຫັດບິນ"
              />
              <Button variant="primary">ຄົ້ນຫາ</Button>
            </div>

            <Card border="secondary" bg="light" style={{ margin: 0 }}>
              <Card.Header
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                <IoBeerOutline /> ປະຫວັດລາຍການຝາກ
              </Card.Header>
              <Card.Body>
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>ລະຫັດບິນ</th>
                      <th>ຈຳນວນອໍເດີ</th>
                      <th>ສະຖານະ</th>
                      <th>ວັນທີສ້າງລາຍການ</th>
                      <th>ໝົດກຳນົດ</th>
                      <th>ຈັດການຂໍ້ມູນ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "start" }}>1</td>
                      <td style={{ textAlign: "start" }}>1</td>
                      <td style={{ textAlign: "start" }}>1</td>
                      <td style={{ textAlign: "start" }}>
                        <div>
                          <Button
                            variant="dark"
                            disabled
                            style={{ backgroundColor: "green" }}
                          >
                            ຝາກ
                          </Button>
                        </div>
                      </td>
                      <td style={{ textAlign: "start" }}>1</td>
                      <td style={{ textAlign: "start" }}>1</td>
                      <td style={{ textAlign: "start" }}>1</td>
                    </tr>
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </Tab>
        </Tabs>
      </div>

      {/* {popup?.PopUpDetaillBillFark && (
        <PopUpDetaillBillFark
          data={selectBillFark}
          closePopup={() => setPopup({})}
        />
      )} */}
    </>
  );
}
