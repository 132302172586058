import React from "react";
// icons
import { PiNewspaperClippingThin } from "react-icons/pi";

export default function EmptyComponent() {
  return (
    <div
      style={{
        dislpay: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        fontSize: 28,
        padding: "40px 10px",
        textAlign: "center",
      }}
    >
      <div>
        <PiNewspaperClippingThin style={{ width: 80, height: 80 }} />
      </div>
      <div>ຍັງບໍ່ມີລາຍການ</div>
    </div>
  );
}
