import React from "react";
import { Button } from "react-bootstrap";
// icons
import { HiMiniPlus } from "react-icons/hi2";
import { RiDeleteBin4Fill } from "react-icons/ri";
import { FaExchangeAlt } from "react-icons/fa";
import { ImMakeGroup } from "react-icons/im";

/**
 * ແທັບເຄືອງມື້ຂອງໜ້າ ຈັດການໂຕະ
 * - ລວມໂຕະ
 * - ຍ້າຍໂຕະ
 * - ເພີ່ມໂຕະ
 * - ລົບໂຕະ
 * @returns
 */
export default function TableBar() {
  return (
    <div
      style={{
        height: 64,
        boxShadow: "3px 0px 5px 5px rgba(0,0,0,0.05)",
        backgroundColor: "#FFF",
        display: "flex",
        alignItems: "center",
        padding: 10,
        gap: 10,
      }}
    >
      <Button variant="primary" disabled>
        <RiDeleteBin4Fill /> ລົບຫ້ອງ
      </Button>
      <Button variant="primary" disabled>
        <HiMiniPlus /> ເພີ່ມຫ້ອງ
      </Button>
      <div style={{ flex: 1 }} />
      <Button variant="primary" disabled>
        <FaExchangeAlt /> ຍ້າຍ
      </Button>
      <Button variant="primary" disabled>
        <ImMakeGroup /> ລວມ
      </Button>
    </div>
  );
}
