import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

type Props = {};

function TestIframe({}: Props) {
  const [data, setData] = useState();
  useEffect(() => {
    const handleMessage = (event: any) => {
      //   if (event.origin !== "https://example.com") return;

      // Send result back to iframe
      console.log(event);
      console.log(event.data);
      // event.source.postMessage(
      //   "Rendered component with message: " + event.data,
      //   event.origin
      // );
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  const handleClick = () => {
    window.postMessage(
      "Rendered component with message: ",
      "http://localhost:3001"
    );
  };
  const sendMessageToIframe = () => {
    const iframe = document.getElementById("myIframe") as HTMLIFrameElement;

    // Send a message to the iframe
    if (iframe && iframe.contentWindow) {
      console.log("run");
      const message = "Hello from parent!";
      iframe.contentWindow.postMessage(message, "http://localhost:3001");
    }
  };
  return (
    <div>
      <Button onClick={sendMessageToIframe}>button in spb</Button>
      <iframe id="myIframe" src="http://localhost:3001/test" />
    </div>
  );
}

export default TestIframe;
