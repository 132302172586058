import React, { Component } from "react";
import LoadingComponent from "./LoadingComponent";

export default class Loading extends Component {
  render() {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 100000,
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            height: "100%",
            width: "100%",
            opacity: 0.7,
            // backdropFilter:"blur(15px)",
          }}
        ></div>
        <LoadingComponent />
      </div>
    );
  }
}
