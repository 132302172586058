import { Modal, Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import UploadImageFormik from "../../UploadImageFormik";
import CustomInput from "../../CustomInput";
import CustomSelect from "../../CustomSelect";
import validationSchema from "../../../utils/validationSchema";
import { productCreateOne } from "../../../services/therdy/product.service";
import { errorAdd, successAdd } from "../../../helpers/sweetalert";

interface PopUpAddProductProps {
  open: boolean;
  onClose: () => void;
}

export default function PopUpAddProduct({
  open,
  onClose,
}: PopUpAddProductProps) {
  const onSubmit = async (values: any) => {
    const { error } = await productCreateOne({ ...values }, "");
    if (error) {
      errorAdd("error something");
    } else {
      successAdd("success");
      onClose();
    }
  };
  return (
    <Modal show={open} onHide={onClose} keyboard={false}>
      <Modal.Header>
        <Modal.Title>Create Product</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{}}
        validationSchema={validationSchema.createProduct}
        onSubmit={onSubmit}
      >
        <Form>
          <Modal.Body>
            <div className="gap-[10px]">
              <UploadImageFormik
                commentText="500x500"
                name="image"
                style={{ width: "200px", height: "200px" }}
              />
              <CustomInput
                require
                label="Product Name"
                name="name"
                type="text"
                placeholder="-- Package Name --"
              />

              <CustomSelect
                require
                label="Show on"
                name="active"
                options={[
                  {
                    value: "ADMIN",
                    label: "Admin Only",
                  },
                  {
                    value: "VIP",
                    label: "VIP Only",
                  },
                  {
                    value: "PUBLIC",
                    label: "ALL",
                  },
                ]}
              />
              <CustomSelect
                require
                label="Platform"
                name="type"
                options={[
                  {
                    label: "Control By Admin",
                    value: "ADMIN",
                  },
                  {
                    label: "moogold.com",
                    value: "MOOGOLD",
                  },
                ]}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              ບັນທຶກ
            </Button>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  );
}
