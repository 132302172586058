const data = {
  defaultPath: "-PATH-",
  reportManagement: true,
  tableManagement: true,
  orderManagement: true,
  reservationManagement: true,
  menuManagement: true,
  settingManagement: true,
  themeManagement: true,
};

/**
 * ຈັດການສິດການເຂົ້າເຖິງຕາມ Role
 * @param {String} role
 * @param {Object} user
 * @returns {data}
 */
const role = (role, user) => {
  switch (role) {
    case "BANHOUSE_ADMIN":
      return {
        defaultPath: `/banhouse/report`,
        // reportManagement: true,
        // tableManagement: true,
        // orderManagement: true,
        // reservationManagement: true,
        // menuManagement: true,
        // settingManagement: true,
        // themeManagement: true,
        reportReviewManagement: true,
        banhouse: true,
      };
    case "ADMIN":
      return {
        defaultPath: `/report/review-report`,
        // reportManagement: true,
        // tableManagement: true,
        // orderManagement: true,
        // reservationManagement: true,
        // menuManagement: true,
        // settingManagement: true,
        // themeManagement: true,
        reportReviewManagement: true,
      };
    case "REVIEW_ADMIN":
      return {
        defaultPath: `/report/review-report`,
        // reportManagement: true,
        // tableManagement: true,
        // orderManagement: true,
        // reservationManagement: true,
        // menuManagement: true,
        // settingManagement: true,
        // themeManagement: true,
        reportReviewManagement: true,
      };
    case "RESTAURANT_ADMIN":
      return {
        defaultPath: `/tables`,
        reportManagement: true,
        tableManagement: true,
        orderManagement: true,
        reservationManagement: true,
        menuManagement: true,
        settingManagement: true,
        themeManagement: true,
        // reportReviewManagement: true,
      };
    case "BANHOUSE_ADMIN":
      return {
        defaultPath: `/report/review-report`,
        reportManagement: true,
        tableManagement: true,
        orderManagement: true,
        reservationManagement: true,
        menuManagement: true,
        settingManagement: true,
        themeManagement: true,
        // reportReviewManagement: true,
      };
    case "CF_ADMIN":
      return {
        defaultPath: `/report/review-report`,
        reportManagement: true,
        tableManagement: true,
        orderManagement: true,
        reservationManagement: true,
        menuManagement: true,
        settingManagement: true,
        themeManagement: true,
        reportReviewManagement: true,
      };
    case "APPZAP_ADMIN":
      return {
        defaultPath: `/settingStore/storeDetail/${user?.storeId}`,
        reportManagement: true,
        tableManagement: true,
        orderManagement: true,
        reservationManagement: true,
        menuManagement: true,
        settingManagement: true,
        themeManagement: true,
      };
    case "APPZAP_STAFF":
      return {
        defaultPath: `/tables`,
        // reportManagement: true,
        tableManagement: true,
        // orderManagement: true,
        // reservationManagement: true,
        // menuManagement: true,
        // settingManagement: true,
        // themeManagement: true,
      };
    case "APPZAP_RESTAURANT":
      return {
        defaultPath: `/settingStore/storeDetail/${user?.storeId}`,
        reportManagement: true,
        tableManagement: true,
        orderManagement: true,
        reservationManagement: true,
        menuManagement: true,
        settingManagement: true,
        themeManagement: true,
      };
    case "APPZAP_USER":
      return {
        // defaultPath: "-PATH-",
        // reportManagement: true,
        // tableManagement: true,
        // orderManagement: true,
        // reservationManagement: true,
        // menuManagement: true,
        // settingManagement: true,
        // themeManagement: true,
      };
    case "APPZAP_FREELANCER":
      return {
        // defaultPath: "-PATH-",
        // reportManagement: true,
        // tableManagement: true,
        // orderManagement: true,
        // reservationManagement: true,
        // menuManagement: true,
        // settingManagement: true,
        // themeManagement: true,
      };
    case "APPZAP_DEALER":
      return {
        // defaultPath: "-PATH-",
        // reportManagement: true,
        // tableManagement: true,
        // orderManagement: true,
        // reservationManagement: true,
        // menuManagement: true,
        // settingManagement: true,
        // themeManagement: true,
      };
    case "APPZAP_KITCHEN":
      return {
        defaultPath: "/orders",
        // reportManagement: true,
        // tableManagement: true,
        orderManagement: true,
        // reservationManagement: true,
        // menuManagement: true,
        // settingManagement: true,
        // themeManagement: true,
      };
    case "APPZAP_COUNTER":
      return {
        defaultPath: "/tables",
        // reportManagement: true,
        tableManagement: true,
        orderManagement: true,
        reservationManagement: true,
        // menuManagement: true,
        // settingManagement: true,
        // themeManagement: true,
      };
    default:
      return {};
  }
};
export default role;
