import React from "react";
import { Outlet } from "react-router-dom";
// page
// import MemberPage from "../pages/MemberPage";
import TestIframe from "../pages/test/TestIframe";

// eslint-disable-next-line
export default {
  path: "/test",
  element: <Outlet />,
  children: [
    {
      path: "test-iframe",
      element: <TestIframe />,
    },
  ],
};
