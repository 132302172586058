import { COLOR_APP } from "../../constants";

import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { converMoney } from "../../helpers/converMoney";

const ReportChartDayOfWeek = () => {
  // state
  const [state, setState] = useState({
    series: [
      {
        name: "ລາຍຈ່າຍ",
        data: [8000, 9000, 5700, 5600, 61, 58, 63, 60, 66, 34, 54, 23],
      },
      {
        name: "ລາຍຮັບ",
        data: [7600, 8500, 10100, 98, 87, 105, 91, 114, 94, 34, 54, 23],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },

      colors: [COLOR_APP, "gray"],
      xaxis: {
        categories: ["ຈັນ", "ຄານ", "ພຸດ", "ພະຫັດ", "ສຸກ", "ເສົາ", "ທິດ"],
      },
      yaxis: {
        title: {
          text: "ຈຳນວນເງິນເປັນ (ກີບ)",
        },
        labels: {
          formatter: (value) => {
            return converMoney(value) + " ກີບ";
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return converMoney(val) + " ກີບ";
          },
        },
      },
    },
  });

  // useEffect

  // function

  return (
    <div id="chart">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={350}
        width={"100%"}
      />
    </div>
  );
};

export default ReportChartDayOfWeek;
