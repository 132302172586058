import React, { useEffect, useState } from "react";
import { Button, Form, Card, Pagination, Table } from "react-bootstrap";
import { getLocalData } from "../../../constants/api";
import { Breadcrumb, Tab, Tabs } from "react-bootstrap";
import { MdAssignmentAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { userGetMany } from "../../../services/therdy/user.service";
import { productGetMany } from "../../../services/therdy/product.service";
import { URL_PHOTO_AW3 } from "../../../constants";
import PopUpAddProduct from "../../../components/popup/therdy/PopUpAddProduct";
import PopUpDeleteProduct from "../../../components/popup/therdy/PopUpDeleteProduct";

export default function ProductPage() {
  const navigate = useNavigate();
  // state
  const [pagination, setPagination] = useState(1);
  const [totalPagination, setTotalPagination] = useState(10);
  const [usersData, setUsersData] = useState<any[]>([]);
  const [popup, setPopup] = useState<any>({});

  // store
  // const { storeDetail }: any = useStore();

  // useEffect
  useEffect(() => {
    getData();
  }, []);
  // function
  const getData = async () => {
    try {
      const { TOKEN } = await getLocalData();
      const findby = "";
      const data = await productGetMany(findby, TOKEN);
      console.log(data);
      setUsersData(data);
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <React.Fragment>
      <div className="p-4">
        <Breadcrumb>
          <Breadcrumb.Item>THERDY CO</Breadcrumb.Item>
          <Breadcrumb.Item active>ລາຍການສິນຄ້າ</Breadcrumb.Item>
        </Breadcrumb>
        <Tabs defaultActiveKey="user-list">
          <Tab eventKey="user-list" title="ລາຍການສິນຄ້າທັງໝົດ">
            <div className="flex gap-2 py-2">
              <Form.Control style={{ maxWidth: 220 }} placeholder="ຄົ້ນຫາ" />
              <Button variant="primary">ຄົ້ນຫາ</Button>
            </div>

            <Card border="primary">
              <Card.Header className="flex items-center">
                <div>ລາຍການສິນຄ້າ</div>
                <div className="flex-1" />
                <Button
                  onClick={() => {
                    setPopup({ PopUpAddProduct: true });
                  }}
                >
                  <MdAssignmentAdd /> ເພີ່ມລາຍການ
                </Button>
              </Card.Header>
              <Card.Body>
                <Table>
                  <table>
                    <tr>
                      <th className="text-start">#</th>
                      <th className="text-start">Image</th>
                      <th className="text-start">Name</th>
                      <th className="text-start">Type</th>
                      <th className="text-start">Active</th>
                      <th className="text-start">Package</th>
                      <th className="text-start">tool</th>
                    </tr>
                    {usersData.map((value: any, index: any) => (
                      <tr key={index}>
                        <td className="text-start">{index + 1}</td>
                        <td className="text-start">
                          <img
                            src={URL_PHOTO_AW3 + value?.image}
                            alt=""
                            className="w-[55px] h-[55px] object-cover rounded-lg"
                          />
                        </td>
                        <td className="text-start">{value?.name ?? "--"}</td>
                        <td className="text-start">{value?.type ?? "--"}</td>
                        <td className="text-start">{value?.active ?? "--"}</td>
                        <td className="text-start">
                          <div className="text-primary underline cursor-pointer">
                            ({value?.packages?.length ?? "--"})
                          </div>
                        </td>
                        <td>
                          <div className="flex gap-2">
                            <Button
                              variant="outline-primary"
                              onClick={() =>
                                navigate(
                                  "/therdy/product/detail?productId=" +
                                    value?._id
                                )
                              }
                            >
                              Detail
                            </Button>
                            <Button
                              variant="outline-danger"
                              onClick={() => {
                                setPopup({ PopUpDeleteProduct: value?._id });
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </table>
                </Table>
              </Card.Body>
              <Card.Footer>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    bottom: 20,
                  }}
                >
                  <ReactPaginate
                    previousLabel={
                      <span className="glyphicon glyphicon-chevron-left">{`ກ່ອນໜ້າ`}</span>
                    }
                    nextLabel={
                      <span className="glyphicon glyphicon-chevron-right">{`ຕໍ່ໄປ`}</span>
                    }
                    breakLabel={<Pagination.Item disabled>...</Pagination.Item>}
                    breakClassName={"break-me"}
                    pageCount={totalPagination} // Replace with the actual number of pages
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={(e) => {
                      console.log(e);
                      setPagination(e?.selected + 1);
                    }}
                    containerClassName={"pagination justify-content-center"} // Bootstrap class for centering
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                  />
                </div>
              </Card.Footer>
            </Card>
          </Tab>
          <Tab eventKey="history" title="ລາຍການກຸ່ມ"></Tab>
        </Tabs>
      </div>
      <PopUpAddProduct
        open={popup?.PopUpAddProduct}
        onClose={() => {
          setPopup({});
        }}
      />
      <PopUpDeleteProduct
        open={popup?.PopUpDeleteProduct}
        onClose={() => {
          setPopup({});
        }}
        productId={popup?.PopUpDeleteProduct}
      />
    </React.Fragment>
  );
}
