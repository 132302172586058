import React, { useEffect, useState } from "react";
import { COLOR_APP } from "../../../constants";
import { Button, Form, Card, Pagination, Table } from "react-bootstrap";
import { getLocalData } from "../../../constants/api";
import { Breadcrumb, Tab, Tabs } from "react-bootstrap";
import { MdAssignmentAdd } from "react-icons/md";
import { useStore } from "../../../store";
import { useNavigate } from "react-router-dom";
import { BiArchiveIn } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import { productGetMany } from "../../../services/banhouse-store/product.service";
import { Product } from "../../../interfaces/Product";

const limitData = 50;

export default function ProductPage() {
  const navigate = useNavigate();
  // state
  const [isLoading, setIsLoading] = useState(true);
  const [loanDataList, setLoanDataList] = useState([]);
  const [pagination, setPagination] = useState(1);
  const [totalPagination, setTotalPagination] = useState(10);
  const [productsData, setProductsData] = useState<Product[]>([]);

  // store
  const { storeDetail }: any = useStore();

  // useEffect
  useEffect(() => {
    getData();
  }, []);
  // function
  const getData = async () => {
    try {
      const { DATA, TOKEN } = await getLocalData();
      const findby = "";
      const data = await productGetMany(findby, TOKEN);
      setProductsData(data);
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <>
      <div style={{ padding: 20 }}>
        <Breadcrumb>
          <Breadcrumb.Item active>ຈັດການສິນຄ້າ</Breadcrumb.Item>
        </Breadcrumb>
        <Tabs defaultActiveKey="billFark-list">
          <Tab eventKey="billFark-list" title="ລາຍການສິນຄ້າທັງໝົດ">
            <div style={{ display: "flex", gap: 10, padding: "10px 0" }}>
              <Form.Control
                style={{ maxWidth: 220 }}
                placeholder="ຄົ້ນຫາລະຫັດບິນ"
              />
              <Button variant="primary">ຄົ້ນຫາ</Button>
            </div>

            <Card border="primary" style={{ margin: 0 }}>
              <Card.Header
                style={{
                  backgroundColor: COLOR_APP,
                  color: "#fff",
                  fontSize: 18,
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                <span>
                  <BiArchiveIn /> ລາຍການສິນຄ້າ
                </span>
                <Button
                  variant="dark"
                  onClick={() => navigate("/banhouse/product/create-one")}
                >
                  <MdAssignmentAdd /> ເພີ່ມລາຍການ
                </Button>
              </Card.Header>
              <Table>
                <table style={{ width: "100%" }}>
                  <tr
                    style={{
                      fontWeight: 700,
                      fontSize: 18,
                      // backgroundColor: COLOR_APP,
                      // color: "#FFFFFF",
                      textDecoration: "underline",
                    }}
                  >
                    <th>#</th>
                    <th>ລະຫັດສິນຄ້າ</th>
                    <th>ຊື່</th>
                    <th>ລາຄາ</th>
                    <th>ໃນສະຕ໊ອກ</th>
                    <th>ສະຖານະ</th>
                    <th>ລາຍລະອຽດ</th>
                    <th>ປະຫວັດ</th>
                  </tr>
                  {productsData.map((value, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "start" }}>{index + 1}</td>
                      <td style={{ textAlign: "start" }}>{value.code}</td>
                      <td style={{ textAlign: "start" }}>{value.name}</td>
                      <td style={{ textAlign: "start" }}>
                        {value.price} ພັນກີບ
                      </td>
                      <td style={{ textAlign: "start" }}>{value.stock}</td>
                      <td style={{ textAlign: "start" }}>
                        <div>ພ້ອມໃຊ້ງານ</div>
                      </td>
                      <td style={{ textAlign: "start", maxWidth: 250 }}>
                        {value.description}
                      </td>
                      <td style={{ textAlign: "start" }}>ແກ້ໄຂລາສຸດ</td>
                    </tr>
                  ))}
                </table>
              </Table>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  bottom: 20,
                }}
              >
                <ReactPaginate
                  previousLabel={
                    <span className="glyphicon glyphicon-chevron-left">{`ກ່ອນໜ້າ`}</span>
                  }
                  nextLabel={
                    <span className="glyphicon glyphicon-chevron-right">{`ຕໍ່ໄປ`}</span>
                  }
                  breakLabel={<Pagination.Item disabled>...</Pagination.Item>}
                  breakClassName={"break-me"}
                  pageCount={totalPagination} // Replace with the actual number of pages
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={3}
                  onPageChange={(e) => {
                    console.log(e);
                    setPagination(e?.selected + 1);
                  }}
                  containerClassName={"pagination justify-content-center"} // Bootstrap class for centering
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  activeClassName={"active"}
                  previousClassName={"page-item"}
                  nextClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                />
              </div>
            </Card>
          </Tab>
          <Tab eventKey="history" title="ປະຫວັດລາຍການສິນຄ້າ"></Tab>
        </Tabs>
      </div>
    </>
  );
}
