import React, { useState } from "react";
import { Breadcrumb, Button, Card, Form, InputGroup } from "react-bootstrap";
import { COLOR_APP } from "../../../constants";
import { Product } from "../../../interfaces/Product";
import { productCreateOne } from "../../../services/banhouse-store/product.service";
import { useNavigate } from "react-router-dom";

type Props = {};

function ProductCreatePage({}: Props) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<Product>({});

  const handleCreate = async () => {
    const data = await productCreateOne(formData, "");
    navigate("../", { replace: true });
  };
  return (
    <div
      style={{
        height: "100%",
        padding: 20,
      }}
    >
      <Breadcrumb>
        <Breadcrumb.Item>ຈັດການສິນຄ້າ</Breadcrumb.Item>
        <Breadcrumb.Item active>ເພີ່ມສິນຄ້າ</Breadcrumb.Item>
      </Breadcrumb>
      <Card style={{ maxWidth: 600, width: "100%" }}>
        <Card.Header
          style={{
            backgroundColor: COLOR_APP,
            color: "#fff",
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          ເພີ່ມສິນຄ້າ
        </Card.Header>
        <Card.Body>
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <Form.Control
              placeholder="ຊື່ສິນຄ້າ"
              value={formData.name}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, name: e.target.value }))
              }
            />
            <InputGroup>
              <Form.Control placeholder="ລາຄາ" />
              <InputGroup.Text>ກີບ</InputGroup.Text>
            </InputGroup>
            <select className="form-control">
              <option value="">ທັງໝົດ</option>
              <option value="sheet">Google sheet</option>
              <option value="other">Pther</option>
              <option value="video">Video</option>
            </select>
            <InputGroup>
              <InputGroup.Text>ຈຳນວນສິນຄ້າໃນສະຕ໊ອກ</InputGroup.Text>
              <Form.Control placeholder="1234" />
            </InputGroup>
            <textarea
              className="form-control"
              placeholder="ລາຍລະອຽດສິນຄ້າ"
            ></textarea>
          </div>
        </Card.Body>
        <Card.Footer>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleCreate}>ບັນທຶກ</Button>
          </div>
        </Card.Footer>
      </Card>
    </div>
  );
}

export default ProductCreatePage;
