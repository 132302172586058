import React, { Component } from "react";

export default class LoadingComponent extends Component {
  render() {
    return (
      <div
        style={{
          zIndex: 2,
          position: "absolute",
          alignSelf: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="loadingio-spinner-blocks-5d262aqlwdw">
          <div className="ldio-psz4i25oysh">
            <div
              style={{
                left: "-55.5px",
                top: "-55.5px",
                animationDelay: "0s",
              }}
            />
            <div
              style={{
                left: "55.5px",
                top: "-55.5px",
                animationDelay: "0.25510204081632654s",
              }}
            />
            <div
              style={{
                left: "-55.5px",
                top: "55.5px",
                animationDelay: "0.7653061224489796s",
              }}
            />
            <div
              style={{
                left: "55.5px",
                top: "55.5px",
                animationDelay: "0.5102040816326531s",
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
