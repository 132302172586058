import React from "react";
import { Button } from "react-bootstrap";
// icons
import { ImMakeGroup } from "react-icons/im";
import { IoCaretBackOutline } from "react-icons/io5";

/**
 * ແທັບເຄືອງມື້ຂອງໜ້າ ຈັດການໂຕະ
 * - ລວມໂຕະ
 * - ຍ້າຍໂຕະ
 * - ເພີ່ມໂຕະ
 * - ລົບໂຕະ
 * @returns
 */
export default function TableDetailBar({ handleClickBlack }) {
  return (
    <div
      style={{
        height: 64,
        boxShadow: "3px 0px 5px 5px rgba(0,0,0,0.05)",
        backgroundColor: "#FFF",
        display: "flex",
        alignItems: "center",
        padding: 10,
        gap: 10,
      }}
    >
      <Button variant="primary" onClick={handleClickBlack}>
        <IoCaretBackOutline /> ກັບຄືນ
      </Button>
      <div style={{ flex: 1 }} />
      <Button variant="primary" disabled>
        <ImMakeGroup /> ລວມ
      </Button>
    </div>
  );
}
