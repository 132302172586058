import React, { useEffect, useState } from "react";
import { Button, Form, Card, Pagination, Table } from "react-bootstrap";
import { getLocalData } from "../../../constants/api";
import { Breadcrumb, Tab, Tabs } from "react-bootstrap";
import { MdAssignmentAdd } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { userGetMany } from "../../../services/therdy/user.service";
import {
  productGetOne,
  productPackageGetMany,
} from "../../../services/therdy/product.service";
import { URL_PHOTO_AW3 } from "../../../constants";
import moment from "moment";
import PopUpAddPackage from "../../../components/popup/therdy/PopUpAddPackage";
import useQuery from "../../../helpers/useQuery";

export default function ProductDetailPage() {
  const { productId } = useQuery();

  const _id = productId;

  const navigate = useNavigate();
  // state
  const [pagination, setPagination] = useState(1);
  const [totalPagination, setTotalPagination] = useState(10);
  const [productData, setProductData] = useState<any>();
  const [productPackageData, setProductPackageData] = useState<any[]>([]);

  const [popup, setPopup] = useState<any>({});

  // store
  // const { storeDetail }: any = useStore();

  // useEffect
  useEffect(() => {
    getData();
  }, []);
  // function
  const getData = async () => {
    try {
      const data = await productGetOne(_id, "");
      const _packages = await productPackageGetMany(_id, "", "");
      setProductPackageData(_packages);
      console.log(data);
      setProductData(data);
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <React.Fragment>
      <div className="p-4">
        <Breadcrumb>
          <Breadcrumb.Item>THERDY CO</Breadcrumb.Item>
          <Breadcrumb.Item>ລາຍການສິນຄ້າ</Breadcrumb.Item>
          <Breadcrumb.Item active>{productData?.name || _id}</Breadcrumb.Item>
        </Breadcrumb>
        <Tabs defaultActiveKey="user-list">
          <Tab eventKey="user-list" title="ລາຍລະອຽດສິນຄ້າ" className="pt-2">
            <Card border="primary" className="mb-2">
              <Card.Header className="flex items-center">
                ລາຍລະອຽດສິນຄ້າ
              </Card.Header>
              <Card.Body>
                <div className="flex gap-4">
                  <div>
                    <img
                      src={URL_PHOTO_AW3 + productData?.image}
                      alt=""
                      className="w-[250px] h-[250px] object-cover rounded-lg"
                    />
                  </div>
                  <div className="flex-1">
                    <div>_id: {productData?._id || "--"}</div>
                    <div>name: {productData?.name || "--"}</div>
                    <div>type: {productData?.type || "--"}</div>
                    <div>active: {productData?.active || "--"}</div>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card border="primary">
              <Card.Header className="flex items-center">
                <div>ລາຍການແພັກເກດ</div>
                <div className="flex-1" />
                <Button onClick={() => setPopup({ PopUpAddPackage: true })}>
                  Add Package
                </Button>
              </Card.Header>
              <Card.Body>
                <Table>
                  <table>
                    <tr>
                      <th className="text-start">#</th>
                      <th className="text-start">Image</th>
                      <th className="text-start">Name</th>
                      <th className="text-start">Price</th>
                      <th className="text-start">Active</th>
                      <th className="text-start">Cost</th>
                      <th className="text-start">Profit</th>
                      <th className="text-start">UpdatedAt</th>
                      <th className="text-start">Tool</th>
                    </tr>
                    {productPackageData?.map((value: any, index: any) => (
                      <tr key={index}>
                        <td className="text-start">{index + 1}</td>
                        <td className="text-start">
                          <img
                            src={URL_PHOTO_AW3 + value?.image}
                            alt=""
                            className="w-[55px] h-[55px] object-cover rounded-lg"
                          />
                        </td>
                        <td className="text-start">{value?.name ?? "--"}</td>
                        <td className="text-start">{value?.price ?? "--"}</td>
                        <td className="text-start">{value?.active ?? "--"}</td>
                        <td className="text-start">{"--"}</td>
                        <td className="text-start">{"--"}</td>
                        <td className="text-start">
                          {moment(value?.UpdatedAt).format("DD/MM/YYYY LT")}
                        </td>
                        <td>
                          <Button>Edit</Button>
                        </td>
                      </tr>
                    ))}
                  </table>
                </Table>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    bottom: 20,
                  }}
                >
                  <ReactPaginate
                    previousLabel={
                      <span className="glyphicon glyphicon-chevron-left">{`ກ່ອນໜ້າ`}</span>
                    }
                    nextLabel={
                      <span className="glyphicon glyphicon-chevron-right">{`ຕໍ່ໄປ`}</span>
                    }
                    breakLabel={<Pagination.Item disabled>...</Pagination.Item>}
                    breakClassName={"break-me"}
                    pageCount={totalPagination} // Replace with the actual number of pages
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={(e) => {
                      console.log(e);
                      setPagination(e?.selected + 1);
                    }}
                    containerClassName={"pagination justify-content-center"} // Bootstrap class for centering
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                  />
                </div>
              </Card.Body>
            </Card>
          </Tab>
          <Tab eventKey="history" title="ປະຫວັດລາຍການ" disabled></Tab>
        </Tabs>
      </div>
      <PopUpAddPackage
        open={popup?.PopUpAddPackage}
        onClose={() => {
          setPopup({});
        }}
        productId={_id}
      />
    </React.Fragment>
  );
}
