import { useEffect, useState } from "react";
import { Button, Form, Card, Pagination, Table } from "react-bootstrap";
import { getLocalData } from "../../../constants/api";
import { Breadcrumb, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { billGetMany } from "../../../services/therdy/bill.service";
import moment from "moment";

export default function BillPage() {
  const navigate = useNavigate();
  // state
  const [pagination, setPagination] = useState(1);
  const [totalPagination, setTotalPagination] = useState(10);
  const [ordersData, setOrdersData] = useState<any[]>([]);

  // store
  // const { storeDetail }: any = useStore();

  // useEffect
  useEffect(() => {
    getData();
  }, []);
  // function
  const getData = async () => {
    try {
      const { TOKEN } = await getLocalData();
      const findby = "p=userId";
      const data = await billGetMany(findby, TOKEN);
      if (!data?.error) {
        setOrdersData(data);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <>
      <div className="p-4">
        <Breadcrumb>
          <Breadcrumb.Item>THERDY CO</Breadcrumb.Item>
          <Breadcrumb.Item active>ລາຍການອໍເດີ</Breadcrumb.Item>
        </Breadcrumb>
        <Tabs defaultActiveKey="order-list">
          <Tab eventKey="order-list" title="ລາຍການບິນທັງໝົດ">
            <div className="flex gap-2 py-2">
              <Form.Control style={{ maxWidth: 220 }} placeholder="ຄົ້ນຫາ" />
              <Button variant="primary">ຄົ້ນຫາ</Button>
            </div>

            <Card border="primary">
              <Card.Header className="flex items-center">
                <div>ລາຍການສິນຄ້າ</div>
                <div className="flex-1" />
              </Card.Header>
              <Card.Body>
                <Table>
                  <table>
                    <tr>
                      <th className="text-start">#</th>
                      <th className="text-start">Bill</th>
                      <th className="text-start">Customer</th>
                      <th className="text-start">Order</th>
                      <th className="text-start">Code</th>
                      <th className="text-start">Status</th>
                      <th className="text-start">Total</th>
                      <th className="text-start">CreatedAt</th>
                      <th className="text-start">Tool</th>
                    </tr>
                    {ordersData?.map((value: any, index: any) => (
                      <tr key={index}>
                        <td className="text-start">{index + 1}</td>
                        <td className="text-start">{value?._id ?? "--"}</td>
                        <td className="text-start">
                          <div className="text-primary underline cursor-pointer">
                            {value?.userId?.username ?? "--"}
                          </div>
                        </td>
                        <td className="text-start">
                          <div className="text-primary underline cursor-pointer">
                            {value?.orders?.length ?? "--"}
                          </div>
                        </td>
                        <td className="text-start">{value?.code ?? "--"}</td>
                        <td className="text-start">{value?.status ?? "--"}</td>
                        <td className="text-start">{value?.total ?? "--"}</td>
                        <td className="text-start">
                          {value?.createdAt
                            ? moment(value?.createdAt).format("DD/MM/YYYY LT")
                            : "--"}
                        </td>

                        <td>
                          <Button
                            variant="outline-primary"
                            onClick={() =>
                              navigate(
                                "/therdy/bill/detail?billId=" + value?._id
                              )
                            }
                          >
                            Detail
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </table>
                </Table>
              </Card.Body>
              <Card.Footer>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    bottom: 20,
                  }}
                >
                  <ReactPaginate
                    previousLabel={
                      <span className="glyphicon glyphicon-chevron-left">{`ກ່ອນໜ້າ`}</span>
                    }
                    nextLabel={
                      <span className="glyphicon glyphicon-chevron-right">{`ຕໍ່ໄປ`}</span>
                    }
                    breakLabel={<Pagination.Item disabled>...</Pagination.Item>}
                    breakClassName={"break-me"}
                    pageCount={totalPagination} // Replace with the actual number of pages
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={(e) => {
                      console.log(e);
                      setPagination(e?.selected + 1);
                    }}
                    containerClassName={"pagination justify-content-center"} // Bootstrap class for centering
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                  />
                </div>
              </Card.Footer>
            </Card>
          </Tab>
          <Tab eventKey="history" title="ລາຍການບິນເກີດປັນຫາ"></Tab>
        </Tabs>
      </div>
    </>
  );
}
