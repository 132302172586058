import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { Formik, Field, Form as FormikForm } from "formik";
import { COLOR_APP } from "../../constants";
import { useStore } from "../../store/useStore";
import Upload from "../Upload";

interface PopUpAddStoreProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: StoreFormValues) => Promise<void>;
  value: StoreFormValues;
}

interface StoreFormValues {
  storeId: string | undefined;
  name: string;
  image: string;
}

const PopUpAddStore: React.FC<PopUpAddStoreProps> = ({
  open,
  onClose,
  onSubmit,
  value,
}) => {
  // state
  const { storeDetail }: any = useStore();

  return (
    <Modal show={open} onHide={onClose} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>ເພີ່ມຮ້ານ</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        initialValues={{
          storeId: storeDetail?._id,
          name: value?.name || "",
          image: value?.image || "",
        }}
        validate={(values) => {
          const errors: { [key: string]: string } = {};
          if (!values.name) {
            errors.name = "-";
          }
          if (!values.image) {
            errors.image = "-";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values).then(() => setSubmitting(false));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          isSubmitting,
        }) => (
          <FormikForm onSubmit={handleSubmit}>
            <Modal.Body>
              <Upload
                src={values?.image || ""}
                removeImage={() => setFieldValue("image", "")}
                onChange={(e) => {
                  setFieldValue("image", e.name);
                }}
              />
              <Form.Group>
                <Form.Label>
                  ຊື່ຮ້ານ <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.name}
                  placeholder="ຊື່ຮ້ານ..."
                  isInvalid={!!errors?.name && touched?.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{
                  backgroundColor: COLOR_APP,
                  color: "#ffff",
                  border: 0,
                }}
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                ບັນທຶກຮ້ານ
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default PopUpAddStore;
