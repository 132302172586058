import { useState } from "react";

export const useLayoutState = () => {
  const [mobileView, setMobileView] = useState(false);
  const [sidebarTogle, setSidebarTogle] = useState(true);

  return {
    sidebarTogle,
    setSidebarTogle,
    mobileView,
    setMobileView,
  };
};
