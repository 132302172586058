import React from "react";
import styled from "styled-components";
import { moneyCurrency } from "../../helpers/index";

interface ReportItem {
  name: string;
  value: number;
  type?: string;
}

interface TableRowData {
  code?: string;
  transfer?: number;
  value?: number;
}

export default function BillForReport80() {
  const reportData: ReportItem[] = [
    { name: "ຈຳນວນບິນ:", value: 66 },
    { name: "ຍອດທັງຫມົດ:", value: 900000, type: "ກີບ" },
    { name: "ຈ່າຍເງິນສົດ:", value: 8000, type: "ກີບ" },
    { name: "ຈ່າຍເງິນໂອນ:", value: 40000, type: "ກີບ" },
    { name: "ບິນສ່ວນຫຼຸດ:", value: 666 },
    { name: "ສ່ວນຫຼຸດ:", value: 666, type: "ກີບ" },
    { name: "ບິນຄ້າງ:", value: 10 },
    { name: "ເງິນຄ້າງ:", value: 6669999, type: "ກີບ" },
  ];

  const tableData: TableRowData[] = Array.from({ length: 20 }, (_, i) => ({
    code: "ERT1T3Z",
    transfer: 23,
    value: 9000000,
  }));

  return (
    <Container>
      <div style={{ fontWeight: "bold", fontSize: 24 }}>ລາຍງານຍອດຂາຍ</div>
      <div style={{ fontWeight: "bold" }}>ເລີ່ມ: 2023/07/17 00:00:00</div>
      <div style={{ fontWeight: "bold" }}>ຫາ: 2023/07/17 23:59:59</div>
      <hr style={{ borderBottom: "1px dotted #000" }} />

      {reportData.map((item, index) => (
        <div
          key={index}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span style={{ textAlign: "left", fontWeight: "bold" }}>
            {item.name}
          </span>
          <span style={{ textAlign: "right", fontWeight: "bold" }}>
            {moneyCurrency(item.value)} {item.type}
          </span>
        </div>
      ))}

      <hr style={{ borderBottom: "1px dotted #000" }} />

      <TableComponent>
        <tbody>
          <tr style={{ fontWeight: "bold" }}>
            <td style={{ textAlign: "left" }}>#</td>
            <td style={{ textAlign: "center" }}>ລະຫັດ</td>
            <td style={{ textAlign: "center" }}>ອໍເດີ</td>
            <td style={{ textAlign: "center" }}>ສ່ວນຫຼຸດ</td>
            <td style={{ textAlign: "right" }}>ບິນລວມ</td>
          </tr>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td style={{ textAlign: "left" }}>{index + 1}</td>
              <td style={{ textAlign: "center" }}>{row.code}</td>
              <td style={{ textAlign: "center" }}>{row.transfer}</td>
              <td style={{ textAlign: "center" }}>
                {moneyCurrency(row.transfer || 0)}
              </td>
              <td style={{ textAlign: "right" }}>
                {moneyCurrency(row.value || 0)}
              </td>
            </tr>
          ))}
        </tbody>
      </TableComponent>
    </Container>
  );
}

const TableComponent = styled.table`
  width: 100%;
  color: #000;
  td {
    padding: 0;
    color: #000;
  }
`;

const Container = styled.div`
  color: #000;
  width: 100%;
  max-width: 330px;
  padding-bottom: 30px;
`;
