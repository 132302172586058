import React from "react";
import { Button, Card, ProgressBar } from "react-bootstrap";
import { COLOR_APP } from "../../constants";

export default function StoreDetail() {
  return (
    <div>
      <div
        style={{
          padding: 20,
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: 20,
        }}
      >
        <div>
          <Card>
            <Card.Header>ຂໍມູນບັນຊີທຸລະກິດ</Card.Header>
            <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
              <div style={{ aspectRatio: 3 }}>
                <img
                  src="https://images.unsplash.com/photo-1575936123452-b67c3203c357?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
            </div>
            <Card.Body>
              <Card.Title>ຮ້ານອາຫານວຽງຈັນກາເກີນ</Card.Title>
              <Card.Text>
                {/* gen lorem */}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                consectetur, eros et semper tincidunt, lectus elit tincidunt
                libero, eget ultricies lectus elit et lectus. Sed euismod,
                lectus non tincidunt tincidunt, lectus elit tincidunt libero,
                eget ultricies lectus elit et lectus. Sed euismod, lectus non
                tincidunt tincidunt, lectus elit tincidunt libero, eget
                ultricies lectus elit et lectus. Sed euismod, lectus non
                tincidunt tincidunt, lectus elit tincidunt libero, eget
                ultricies lectus elit et lectus. Sed euismod,
              </Card.Text>
              <Card.Text>
                {/* gen lorem */}
                Map: <a href="https://google.map/">Google map</a>
                <br />
                Phone: +856 20 97015341
                <br />
                WA: +856 20 97015341
                <br />
                Page: <a href="https://google.map/">facebook.com/vengc</a>
                <br />
              </Card.Text>
            </Card.Body>
            <Card.Footer></Card.Footer>
          </Card>
        </div>
        <div>
          <Card>
            <Card.Header>ແພັກເກັດທີ່ນຳໃຊ້</Card.Header>
            <Card.Body>
              <Card.Title>
                ຊື່ແພັກເກັດ: <a href="https://google.map/">START BUSINESS</a>
              </Card.Title>
              <div style={{ marginBottom: 10 }}>
                <div>
                  ອໍເດີສູງສຸດ 1,000/{" "}
                  <span style={{ color: COLOR_APP }}>ນຳໃຊ້ແລ້ວ 400</span>
                </div>
                <div>
                  <ProgressBar
                    striped
                    variant="primary"
                    now={40}
                    label={`${40}%`}
                  />
                </div>
              </div>
              <div style={{ marginBottom: 10 }}>
                <div>
                  user 5/ <span style={{ color: COLOR_APP }}>ນຳໃຊ້ແລ້ວ 4</span>
                </div>
                <div>
                  <ProgressBar
                    striped
                    variant="primary"
                    now={80}
                    label={`${80}%`}
                  />
                </div>
              </div>
              <div style={{ marginBottom: 10 }}>
                <div>
                  ເມນູສິນຄ້າສູງສຸດ 100/{" "}
                  <span style={{ color: COLOR_APP }}>ນຳໃຊ້ແລ້ວ 50</span>
                </div>
                <div>
                  <ProgressBar
                    striped
                    variant="primary"
                    now={50}
                    label={`${50}%`}
                  />
                </div>
              </div>
              <div style={{ marginBottom: 10 }}>
                <div>
                  ປະຫວັດບິນຍ້ອນຫຼັງ 2,000/{" "}
                  <span style={{ color: COLOR_APP }}>ນຳໃຊ້ແລ້ວ 1,000</span>
                </div>
                <div>
                  <ProgressBar
                    striped
                    variant="primary"
                    now={50}
                    label={`${50}%`}
                  />
                </div>
              </div>
              <div style={{ marginBottom: 10 }}>
                <div>
                  ມື້ໝົດອາຍຸ:{" "}
                  <span style={{ color: COLOR_APP }}>ບໍ່ມີມີໝົດ</span>
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
              <Button style={{ width: "100%" }}>ເບິ່ງແພັກເກັດທັງໝົດ</Button>
            </Card.Footer>
          </Card>
        </div>
      </div>
    </div>
  );
}
