import moment from "moment";
import React from "react";
import { Table } from "react-bootstrap";
import {
  COLOR_APP,
  COLOR_APP_CANCEL,
  COLOR_APP_DOING,
  COLOR_APP_WAITING,
} from "../constants";
import { LiaCommentDots } from "react-icons/lia";

export default function OrderListComponent({ orders, handleSelectOrder }) {
  return (
    <Table style={{ width: "100%", maxWidth: 800 }}>
      <thead>
        <tr>
          <th style={{ maxWidth: "10px", padding: 0 }}></th>
          <th style={{ textAlign: "start" }}>
            ເລືອກ(0) -{" "}
            <span
              style={{
                textDecoration: "underline",
                color: COLOR_APP,
                cursor: "pointer",
              }}
            >
              ເລືອກທັງໝົດ
            </span>
          </th>
          <th style={{ textAlign: "end", whiteSpace: "pre" }}>ຈຳນວນ/ລາຄາ</th>
        </tr>
      </thead>
      <tbody>
        {orders?.map((e, i) => (
          <tr
            style={{
              backgroundColor: e?._select ? "#87a92236" : "#fff",
              opacity: e?.status === "CANCELED" ? 0.4 : 1,
            }}
            onClick={() => {
              if (e?.status === "CANCELED") return;
              handleSelectOrder(e);
            }}
            key={i}
          >
            <td
              style={{
                width: "10px",
                maxWidth: "10px",
                padding: 0,
                backgroundColor:
                  e?.status === "CANCELED"
                    ? COLOR_APP_CANCEL
                    : e?.status === "DOING"
                    ? COLOR_APP_DOING
                    : e?.status === "WAITING"
                    ? COLOR_APP_WAITING
                    : e?.status === "SERVED"
                    ? COLOR_APP
                    : "#ffffff",
              }}
            ></td>
            <td
              style={{
                textAlign: "start",
                padding: 5,
              }}
            >
              <div>
                ໂຕະ:{" "}
                <span style={{ color: COLOR_APP, fontWeight: "bold" }}>
                  {e?.tableId?.name}
                </span>
                <span style={{ fontSize: 10 }}> ({e?.code})</span>
                <br />
                <span
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                  }}
                >
                  {e?.name}
                </span>
                <br />
                <LiaCommentDots />: {e?.note}
                <br />
                <span style={{ fontSize: 10, color: "rgba(0,0,0,0.5)" }}>
                  {moment(e?.createdAt).format("DD-MM-YYYY HH:ss")}
                </span>
              </div>
            </td>
            <td style={{ textAlign: "end", whiteSpace: "pre" }}>
              6<br />
              18,000
              <br />
              {e?.status}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
