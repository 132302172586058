import React from "react";
import Login from "../pages/login/Login";
import { useRoutes } from "react-router-dom";

import MainLayout from "../layouts/MainLayout";
import Dashboard from "../pages/dashboard/Dashboard";
import MenuListMobile from "../pages/table/mobileView/MenuList";
import StoreDetail from "../pages/store/StoreDetail";
import Table from "../pages/table/TableList";
import AddOrder from "../pages/table/AddOrder";
import MenuList from "../pages/menu/MenuList";
import FormAddMenuStock from "../pages/menu/form/FormAddMenuStock";
import StockList from "../pages/stock/StockList";
import StockCategory from "../pages/stock/Categorylist";
import StockHistory from "../pages/stock/HistoryList";
import SettingList from "../pages/settingStore/SettingList";
import SettingPromotion from "../pages/settingStore/settingPromotion";
import _404 from "../pages/404";

import MenuRoutes from "./Menu.routes";
import PrinterRoutes from "./Printer.routes";
import OrderRoutes from "./Order.routes";
import DesignRoutes from "./Design.routes";
import TableRoutes from "./Table.routes";
import ReportRoutes from "./Report.routes";
import DashboardRoutes from "./Dashboard.routes";
import StaffRoutes from "./Staff.routes";
import AudioRoutes from "./Audio.routes";
import ComponentRoutes from "./Component.routes";
import AddOrderRoutes from "./AddOrder.routes";
import FarkRoutes from "./Fark.routes";
import UserRoutes from "./User.routes";

import ConfigRoutes from "./Config.routes";
import SelfOrderingOrderRoutes from "./SelfOrderingOrder.routes";
import CurrencyList from "../pages/currency/CurrencyList";
import BannerList from "../pages/banner/BannerList";
import SettingRoutes from "./Setting.routes";
import StoreRoutes from "./Store.routes";

import { useStore } from "../store";
import BanhouseRoutes from "./Banhouse.routes";
import TestRoutes from "./Test.routes";
import TherdyRoutes from "./Therdy.routes";

function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/menus/:storeId/:tableId",
      element: <MenuListMobile />,
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/report/:storeId",
          element: <Dashboard />,
        },
        {
          path: "/settingStore",
          element: <SettingList />,
        },
        {
          path: "/settingStore/storeDetail/:id",
          element: <StoreDetail />,
        },
        {
          path: "/settingStore/banner",
          element: <BannerList />,
        },
        {
          path: "/tables",
          element: <Table />,
        },
        {
          path: "/addOrder/tableid/:tableId/code/:code",
          element: <AddOrder />,
        },
        {
          path: "/settingStore/currency/:id",
          element: <CurrencyList />,
        },
        {
          path: "/settingStore/menu/limit/:limit/page/:page/:id",
          element: <MenuList />,
        },
        {
          path: "/settingStore/menu/menu-stock/:id",
          element: <FormAddMenuStock />,
        },
        {
          path: "/settingStore/stock/limit/:limit/page/:page/:id",
          element: <StockList />,
        },
        {
          path: "/settingStore/settingPromotion/:id",
          element: <SettingPromotion />,
        },
        {
          path: "/settingStore/stock/category/limit/:limit/page/:page/:id",
          element: <StockCategory />,
        },
        {
          path: "/settingStore/stock/history/limit/:limit/page/:page/:id",
          element: <StockHistory />,
        },
        {
          path: "/settingStore/:id",
          element: <SettingList />,
        },
        {
          path: "/menu",
          element: <MenuList />,
        },
        // {
        //   path: "/settingTheme",
        //   element: <SettingTheme />,
        // },
        OrderRoutes,
        PrinterRoutes,
        DesignRoutes,
        TableRoutes,
        ReportRoutes,
        DashboardRoutes,
        MenuRoutes,
        StaffRoutes,
        AddOrderRoutes,
        AudioRoutes,
        ConfigRoutes,
        SelfOrderingOrderRoutes,
        FarkRoutes,
        UserRoutes,
        SettingRoutes,
        StoreRoutes,
        BanhouseRoutes,
        TestRoutes,
        TherdyRoutes,
      ],
    },
    ComponentRoutes,
    {
      path: "*",
      element: <_404 />,
    },
  ]);
}

export default Router;
