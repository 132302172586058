import React from "react";

import { Link, useNavigate } from "react-router-dom";

import { COLOR_APP } from "../constants";

// icons
import { TbError404 } from "react-icons/tb";

// images
// import Logo from "../../components/Logo";

function Login() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100dvh",
        width: "100dvw",
        // backgroundColor: COLOR_APP,
        // backgroundImage:
        //   "repeating-linear-gradient(45deg, #ffffff1a 0px, #ffffff1a 10px, transparent 10px, transparent 20px, #ffffff1a 20px)",
        padding: 10,
      }}
    >
      <div
        style={{
          flex: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: COLOR_APP,
        }}
      >
        <TbError404
          style={{ width: "400px", height: "200px", color: COLOR_APP }}
        />
        <div style={{ fontSize: 32, fontWeight: 700 }}>Page not found !</div>
        <Link to="/">
          <a style={{ fontSize: 22 }}>
            {">> back to home <<"}
          </a>
        </Link>
      </div>

      {/* spase */}
      <div style={{ flex: 2 }} />
      {/* Footer section  ------------------------------------------------------------------------------------------ */}
      <div
        style={{
          textAlign: "center",
          color: COLOR_APP,
          fontSize: 14,
          opacity: 0.7,
        }}
      >
        Copyright © 2024 banhouse.la
      </div>
    </div>
  );
}

export default Login;
