import React, { useState, useRef, useLayoutEffect } from "react";
import BillForReport80 from "./BillForReport80";
import Swal from "sweetalert2";
import { base64ToBlob } from "../../helpers";
import html2canvas from "html2canvas";
import axios from "axios";

const PrintTest: React.FC = () => {
  const bill80Ref = useRef<HTMLDivElement>(null);
  const [widthBill80, setWidthBill80] = useState<number>(0);

  useLayoutEffect(() => {
    if (bill80Ref.current) {
      setWidthBill80(bill80Ref.current.offsetWidth);
    }
  }, [bill80Ref]);

  const onPrintBill = async () => {
    try {
      const urlForPrinter = "http://localhost:9150/usb/image";

      if (!bill80Ref.current) return;
      const dataImageForPrint = await html2canvas(bill80Ref.current, {
        useCORS: true,
        scrollX: 10,
        scrollY: 0,
        scale: 530 / widthBill80,
      });

      const _file = await base64ToBlob(dataImageForPrint.toDataURL());
      const bodyFormData = new FormData();
      bodyFormData.append("port", "9100");
      bodyFormData.append("image", _file);
      bodyFormData.append("beep1", "1");
      bodyFormData.append("beep2", "9");

      await axios.post(urlForPrinter, bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      await Swal.fire({
        icon: "success",
        title: "ປິນສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (err) {
      console.error(err);
      await Swal.fire({
        icon: "error",
        title: "ປິນບໍ່ສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <div>
      <div ref={bill80Ref} style={{ maxWidth: 330, width: 330 }}>
        <BillForReport80 />
      </div>
      <button onClick={onPrintBill}>Print</button>
    </div>
  );
};

export default PrintTest;
