import React from "react";
import { Outlet } from "react-router-dom";
// page
import SettingTable from "../pages/settingStore/SettingTable";

const TableRoutes = {
  path: "/settingStore",
  element: <Outlet />,
  children: [
    {
      path: "settingTable/:id",
      element: <SettingTable />,
    },
  ],
};
export default TableRoutes;
