import React from "react";
import { Outlet } from "react-router-dom";
// page
import DashboardPage from "../pages/dashboardnew/DashboardPage";
import ReportStockPage from "../pages/report/ReportStockPage";
// import MemberPage from "../pages/MemberPage";
import ReportReviewPage from "../pages/report/ReportReviewPage";
import ReportReviewStorePage from "../pages/report/ReportReviewStorePage";

// eslint-disable-next-line
export default {
  path: "/report",
  element: <Outlet />,
  children: [
    {
      path: "sales-report",
      element: <DashboardPage />,
    },
    // {
    //   path: "members-report",
    //   element: <MemberPage />,
    // },
    {
      path: "review-report",
      element: <ReportReviewPage />,
    },
    {
      path: "review-report/store/:storeId",
      element: <ReportReviewStorePage />,
    },
    {
      path: "stock",
      element: <ReportStockPage />,
    },
  ],
};
