import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Form, Table } from "react-bootstrap";
import { AiFillPrinter } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import QRCode from "react-qr-code";
import PopUpAddStore from "../../components/popup/PopUpAddStore";
import { ErrorSwal, SuccessSwal } from "../../helpers/alertsweet";
import axios from "axios";
import { END_POINT, URL_PHOTO_AW3 } from "../../constants";
import { getHeaders } from "../../services/auth";
import { useStore } from "../../store";

export default function StoreListPage() {
  // state
  const [popup, setPopup] = useState();
  const [stores, setStores] = useState();

  const { storeDetail } = useStore();
  // useEffect
  useEffect(() => {
    getStoresData();
  }, []);
  // function
  const handleCreateStore = async (formData) => {
    try {
      const _res = await axios.post(END_POINT + "/store/create-one", formData, {
        headers: await getHeaders(),
      });
      if (_res.status >= 300) throw new Error("");
      SuccessSwal("ເພີ່ມຮ້ານສຳເລັດ");
      setPopup();
      getStoresData();
    } catch (err) {
      ErrorSwal("ບໍ່ສຳເລັດກະລຸນາລອງໃໝ່");
    }
  };
  const getStoresData = async () => {
    try {
      const _res = await axios.get(
        END_POINT + "/store/get-many?storeId=" + storeDetail?._id
      );
      if (_res.status >= 300) throw new Error("");
      setStores(_res.data);
    } catch (err) {
      console.log("err:", err);
    }
  };
  return (
    <div
      style={{ padding: 20, overflowY: "auto", height: "calc(100dvh - 64px)" }}
    >
      <Breadcrumb>
        <Breadcrumb.Item>ລາຍງານ</Breadcrumb.Item>
        <Breadcrumb.Item active>ລາຍງານລູກຄ້າລີວິວ</Breadcrumb.Item>
      </Breadcrumb>

      <div style={{ marginBottom: 20 }}>
        <Card border="primary">
          <Card.Header>ລາຍການຮ້ານ</Card.Header>
          <Card.Body>
            <div
              style={{
                marginBottom: 20,
                display: "flex",
                flexDirection: "row",
                gap: 10,
              }}
            >
              <div style={{ display: "flex", gap: 10 }}>
                <Form.Control placeholder="ຄົ້ນຫາຊື່ຮ້ານ" />
                <Button
                  variant="primary"
                  style={{ display: "flex", gap: 10, alignItems: "center" }}
                >
                  <FaSearch /> ຄົ້ນຫາ
                </Button>
              </div>
              <div style={{ flex: 1 }} />
              <Button
                variant="outline-primary"
                style={{ display: "flex", gap: 10, alignItems: "center" }}
                onClick={() => setPopup({ PopUpAddStore: true })}
              >
                <AiFillPrinter /> ເພີ່ມຮ້ານ
              </Button>
            </div>
            <Table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ width: 60 }}>#</th>
                  <th>ຊື່ຮ້ານ</th>
                  <th>ຮູບຮ້ານ</th>
                  <th>QR Code</th>
                  <th>ສະຖານະ ເປີດ/ປິດ</th>
                  <th>ແກ້ໄຂ</th>
                </tr>
              </thead>
              <tbody>
                {stores?.map((e, i) => (
                  <tr>
                    <td style={{ width: 60 }}>{i + 1}</td>
                    <td>{e?.name}</td>
                    <td>
                      <div>
                        <img
                          src={URL_PHOTO_AW3 + e?.image}
                          alt=""
                          style={{
                            width: 200,
                            height: 100,
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <QRCode
                        value={"https://review.banhouse.la/store/" + e?._id}
                        size={80}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={true}
                        label="ເປີດນຳໄຊ້ລະບົບ"
                      />
                    </td>
                    <td>
                      <Button>ແກ້ໄຂ</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
      <PopUpAddStore
        open={popup?.PopUpAddStore}
        onClose={() => setPopup()}
        onSubmit={handleCreateStore}
      />
    </div>
  );
}
