import React, { useEffect, useState } from "react";
import { PiStarFill } from "react-icons/pi";

export default function StarComponent({
  star,
  onChangeStar,
  color1 = "#e8e8e8",
  color2 = "#9BCF53",
  rootStyle,
  starStyle,
}) {
  // state
  const [stars, setStars] = useState([true, true, true, true, true]);

  // useEffect
  useEffect(() => {
    if (star < 0 || star > 5) {
      onChangeStar(5);
      return;
    }
    setStars((prev) => {
      let resault = [];
      for (let [index, value] of prev.entries()) {
        if (index + 1 <= Math.ceil(star)) {
          resault.push(true);
        } else {
          resault.push(false);
        }
      }
      return resault;
    });
  }, [star]);
  return (
    <div style={{ display: "flex", gap: 10, ...rootStyle }}>
      <PiStarFill
        style={{ color: stars?.[0] ? color2 : color1, ...starStyle }}
        onClick={() => onChangeStar(1)}
      />
      <PiStarFill
        style={{ color: stars?.[1] ? color2 : color1, ...starStyle }}
        onClick={() => onChangeStar(2)}
      />
      <PiStarFill
        style={{ color: stars?.[2] ? color2 : color1, ...starStyle }}
        onClick={() => onChangeStar(3)}
      />
      <PiStarFill
        style={{ color: stars?.[3] ? color2 : color1, ...starStyle }}
        onClick={() => onChangeStar(4)}
      />
      <PiStarFill
        style={{ color: stars?.[4] ? color2 : color1, ...starStyle }}
        onClick={() => onChangeStar(5)}
      />
    </div>
  );
}
