import React, { useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { COLOR_APP } from "../../../constants";
import {
  lockPriceGetMany,
  paymentGetMany,
} from "../../../services/therdy/payment.service";
import Countdown from "../../../components/Countdown";
import moment from "moment";
import { converMoney } from "../../../helpers/converMoney";

type Props = {};

export default function LockPricePage({}: Props) {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const data = await lockPriceGetMany("", "");
    console.log("data", data);
    setData(data);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="p-4">
      <Card border="primary">
        <Card.Header>Lock Price</Card.Header>
        <Card.Body>
          <Table>
            <table>
              <tr
                style={{
                  fontWeight: 700,
                  fontSize: 18,
                  textDecoration: "underline",
                }}
              >
                <th>#</th>
                <th>Bank</th>
                <th>Price</th>
                <th>CreatedAt</th>
                <th>ExpiredAt</th>
                <th>Status</th>
                <th>Customer</th>
              </tr>
              {data?.map((e: any, i: any) => (
                <tr key={i}>
                  <td className="text-left">{i + 1}</td>
                  <td className="text-left">{e?.platform || "--"}</td>
                  <td className="text-left">
                    {e?.price ? converMoney(e?.price) : "--"}
                  </td>
                  <td>
                    {e?.createdAt
                      ? moment(e?.createdAt).format("DD/MM/YYYY LT")
                      : "--"}
                  </td>
                  <td>
                    <Countdown
                      date={moment(e?.createdAt).add(2, "days").format()}
                    />
                  </td>
                  <td>{e?.status || "--"}</td>
                  <td>{e?.userId || "--"}</td>
                </tr>
              ))}
            </table>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
}
