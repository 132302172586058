import React, { useState } from "react";
import Navbar from "./Navbar";
import Sidenav from "./SideNav";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

interface MainLayoutProps {
  children?: React.ReactNode;
}

export default function MainLayout({ children }: MainLayoutProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-[auto_1fr] w-full">
      <div className="overflow-auto h-[100dvh] hidden md:block">
        <Sidenav />
      </div>
      <div
        style={{
          width: "100%",
          minHeight: "100dvh",
          height: "100dvh",
          maxHeight: "100dvh",
          overflow: "auto",
          overflowY: "scroll",
          overflowX: "hidden",
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Navbar />
        <div className="flex-1">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
