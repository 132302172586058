import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { COLOR_APP } from "../../constants";
import { master_menu_api_dev } from "../../constants/api";

interface Category {
  _id: string;
  name: string;
}

interface MenuRestaurantType {
  _id: string;
  image: string;
  name: string;
  name_en: string;
  name_ch: string;
  name_kr: string;
  isChecked: boolean;
}

interface PopUpAddMenusProps {
  open: boolean;
  text: string;
  onClose: () => void;
  onSubmit: () => Promise<void>;
  categoriesRestaurant: Category[];
}

const PopUpAddMenus: React.FC<PopUpAddMenusProps> = ({
  open,
  text,
  onClose,
  onSubmit,
  categoriesRestaurant,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [menuWithRestaurant, setMenuWithRestaurant] = useState<string>("");
  const [menuRestaurantType, setMenuRestaurantType] = useState<MenuRestaurantType[]>([]);
  console.log("menuRestaurantType: ", menuRestaurantType);

  useEffect(() => {
    getCategory(menuWithRestaurant);
  }, [menuWithRestaurant]);

  const getCategory = async (id: string) => {
    try {
      await fetch(master_menu_api_dev + `/api/menus/res-category/` + id, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((json) =>
          setMenuRestaurantType(
            json?.map((item: MenuRestaurantType) => {
              return { ...item, isChecked: true };
            })
          )
        );
    } catch (err) {
      console.log(err);
    }
  };

  const handleCheckbox = (value: MenuRestaurantType) => {
    console.log("value: ", value);
  };

  return (
    <div>
      <Modal show={open} size="xl">
        <Modal.Header>
          <div style={{ fontSize: "20px", fontWeight: 600 }}>
            ເພີ່ມເມນູຈຳນວນຫຼາຍ
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <div style={{ marginBottom: 10 }}>
                <label>ເລືອກປະເພດຮ້ານ</label>
                <select
                  className="form-control"
                  onChange={(e) => setMenuWithRestaurant(e.target.value)}
                >
                  <option value="All">ທັງໝົດ</option>
                  {categoriesRestaurant?.map((item, index) => {
                    return (
                      <option key={index} value={item?._id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Col>
            <Col md="12">
              <table className="table table-hover">
                <thead className="thead-light">
                  <tr>
                    <th>
                      <Form.Check type="checkbox" style={{ marginLeft: 10 }} />
                    </th>
                    <th scope="col">ຮູບພາບ</th>
                    <th scope="col">ຊື່ອາຫານ</th>
                    <th scope="col">ຊື່ອາຫານອັງກິດ</th>
                    <th scope="col">ຊື່ອາຫານຈີນ</th>
                    <th scope="col">ຊື່ອາຫານເກົ່າຫຼີ</th>
                  </tr>
                </thead>
                <tbody>
                  {menuRestaurantType.length > 0
                    ? menuRestaurantType?.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              <Form.Check
                                type="checkbox"
                                checked={item?.isChecked}
                                onChange={(e) => handleCheckbox(item)}
                                color="primary"
                              />
                            </td>
                            <td>{item?.image}</td>
                            <td>{item?.name}</td>
                            <td>{item?.name_en}</td>
                            <td>{item?.name_ch}</td>
                            <td>{item?.name_kr}</td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={buttonDisabled}
            variant="secondary"
            onClick={onClose}
          >
            ຍົກເລີກ
          </Button>
          <Button
            disabled={buttonDisabled}
            style={{ backgroundColor: COLOR_APP, color: "#ffff", border: 0 }}
            onClick={() => {
              setButtonDisabled(true);
              onSubmit().then(() => setButtonDisabled(false));
            }}
          >
            ຢືນຢັນສ້າງເມນູ
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PopUpAddMenus;
