import React, { useEffect, useState } from "react";
import { COLOR_APP } from "../../constants";
import { Button, Card, Pagination } from "react-bootstrap";
import { getLocalData } from "../../constants/api";
import { Breadcrumb } from "react-bootstrap";
import { MdAssignmentAdd } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { useStore } from "../../store";
import { useNavigate } from "react-router-dom";
import { IoPeople } from "react-icons/io5";
import { deleteUser, getUserCountV5, getUsersV5 } from "../../services/user";
import PopUpCreateUser from "../../components/popup/PopUpCreateUser";
import PopUpConfirmDeletion from "../../components/popup/PopUpConfirmDeletion";
import { convertRole } from "../../helpers/convertRole";
import LoadingComponent from "../../components/LoadingComponent";

let limitData = 25;

export default function UserPage() {
  const navigate = useNavigate();
  // state
  const [isLoading, setIsLoading] = useState(true);
  const [loanDataList, setLoanDataList] = useState([]);
  const [pagination, setPagination] = useState(1);
  const [totalPagination, setTotalPagination] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [totalDataList, setTotalDataList] = useState(0);
  const [backupFormData, setBackupFormData] = useState();
  const [userData, setUserData] = useState();
  const [selectUser, setSelectUser] = useState();
  const [popup, setPopup] = useState();

  // store
  const { storeDetail } = useStore();

  // useEffect
  useEffect(() => {
    getCountData();
  }, []);
  useEffect(() => {
    getData();
  }, [pagination]);
  // function
  const getData = async () => {
    try {
      setIsLoading(true);
      const { DATA, TOKEN } = await getLocalData();
      let findby = "?";
      findby += `storeId=${storeDetail?._id}&`;
      findby += `skip=${(pagination - 1) * limitData}&`;
      findby += `limit=${limitData}&`;

      const _data = await getUsersV5(findby, TOKEN);
      if (_data.error) throw new Error("error");
      setUserData(_data);
      setIsLoading(false);
    } catch (err) {
      console.log("err", err);
      setIsLoading(false);
    }
  };
  const getCountData = async () => {
    try {
      const { DATA, TOKEN } = await getLocalData();
      let findby = "?";
      findby += `storeId=${storeDetail?._id}&`;

      const _data = await getUserCountV5(findby, TOKEN);
      setTotalPagination(Math.ceil(_data?.count / limitData));
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleDeleteUser = async () => {
    const { DATA, TOKEN } = await getLocalData();
    await deleteUser(selectUser?._id, TOKEN);
    setPopup();
    setSelectUser();
    getData();
  };
  return (
    <>
      <div className="p-4">
        <Breadcrumb>
          <Breadcrumb.Item>ພະນັກງານ</Breadcrumb.Item>
          <Breadcrumb.Item active>ລາຍການພະນັກງານ</Breadcrumb.Item>
        </Breadcrumb>
        {/* <div style={{ display: "flex", gap: 10, padding: "10px 0" }}>
          <Form.Control
            style={{ maxWidth: 220 }}
            placeholder="ຄົ້ນຫາພະນັກງານ"
          />
          <Button variant="primary">ຄົ້ນຫາ</Button>
        </div> */}

        <Card border="primary" style={{ margin: 0 }}>
          <Card.Header
            style={{
              backgroundColor: COLOR_APP,
              color: "#fff",
              fontSize: 18,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 10,
            }}
          >
            <span>
              <IoPeople /> ລາຍການພະນັກງານ
            </span>
            <Button
              variant="dark"
              bg="dark"
              onClick={() => {
                setPopup({ PopUpCreateUser: true });
              }}
            >
              <MdAssignmentAdd /> ເພີ່ມລາຍການ
            </Button>
          </Card.Header>
          <div
            style={{
              height: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            hidden={!isLoading}
          >
            <LoadingComponent />
          </div>
          <div style={{ minHeight: 300 }} hidden={isLoading}>
            <table style={{ width: "100%" }}>
              <tr>
                <th>#</th>
                <th>ຮູບພາບ</th>
                <th>ຊື່</th>
                <th>username</th>
                <th>ເບີໂທ</th>
                <th>ສິດນຳໃຊ້ລະບົບ</th>
                <th>ຈັດການ</th>
              </tr>
              {userData?.map((e, i) => (
                <tr>
                  <td style={{ textAlign: "start" }}>
                    {(pagination - 1) * limitData + i + 1}
                  </td>
                  <td style={{ textAlign: "start" }}>
                    <div>
                      <img
                        src="/images/profile.png"
                        alt=""
                        style={{ width: 60, height: 60 }}
                      />
                    </div>
                  </td>
                  <td style={{ textAlign: "start" }}>
                    {e?.firstname} {e?.lastname}
                  </td>
                  <td
                    style={{
                      textAlign: "start",
                    }}
                  >
                    <span
                      style={{
                        color: COLOR_APP,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {e?.userId}
                    </span>
                  </td>
                  <td style={{ textAlign: "start" }}>
                    <div>{e?.phone}</div>
                  </td>
                  <td style={{ textAlign: "start" }}>{convertRole(e?.role)}</td>
                  <td style={{ textAlign: "start" }}>
                    <div style={{ display: "flex", gap: 10 }}>
                      {/* <Button>ແກ້ໄຂ</Button>
                        <Button>ລັອກ</Button> */}
                      <Button
                        onClick={() => {
                          setSelectUser(e);
                          setPopup({ PopUpConfirmDeletion: true });
                        }}
                      >
                        ລົບ
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
          <Card.Footer>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <ReactPaginate
                previousLabel={
                  <span className="glyphicon glyphicon-chevron-left">{`ກ່ອນໜ້າ`}</span>
                }
                nextLabel={
                  <span className="glyphicon glyphicon-chevron-right">{`ຕໍ່ໄປ`}</span>
                }
                breakLabel={<Pagination.Item disabled>...</Pagination.Item>}
                breakClassName={"break-me"}
                pageCount={totalPagination} // Replace with the actual number of pages
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={(e) => {
                  console.log(e);
                  setPagination(e?.selected + 1);
                }}
                containerClassName={"pagination justify-content-center"} // Bootstrap class for centering
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                activeClassName={"active"}
                previousClassName={"page-item"}
                nextClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
              />
            </div>
          </Card.Footer>
        </Card>
      </div>
      <PopUpConfirmDeletion
        open={popup?.PopUpConfirmDeletion}
        onClose={() => {
          setPopup();
          setSelectUser();
        }}
        onSubmit={handleDeleteUser}
      />
      <PopUpCreateUser
        open={popup?.PopUpCreateUser}
        onClose={() => {
          setPopup();
        }}
        callback={() => {
          getData();
        }}
      />
    </>
  );
}
