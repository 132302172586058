import React, { useState, ChangeEvent } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useField } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faFileImage } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";
import { PRESIGNED_URL } from "../constants/api";
import { URL_PHOTO_AW3 } from "../constants";

// Define the props interface
interface UploadImageFormikProps {
  rootStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  commentText?: string;
  name: string; // Required for Formik's `useField`
  [key: string]: any; // Allow additional props
}

const UploadImageFormik: React.FC<UploadImageFormikProps> = ({
  rootStyle,
  style,
  commentText = "JPG, JPEG, PNG, WEBP",
  ...other
}) => {
  const [_, meta, helpers] = useField(other);
  const { value } = meta;
  const { setValue } = helpers;

  const [selectImage, setSelectImage] = useState<string | undefined>();
  const [imageLoading, setImageLoading] = useState<string | number>("");

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setSelectImage(event.target.value);
    setImageLoading("");

    const fileDatatype = file.type;
    const imageName = uuidv4() + "." + fileDatatype.split("/")[1];
    try {
      const responseUrl = await axios.post(PRESIGNED_URL, {
        name: file.type,
      });

      const afterUpload: any = await axios({
        method: "put",
        url: responseUrl.data.url,
        data: file,
        headers: {
          "Content-Type": "file/*; image/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
        onUploadProgress: (progressEvent) => {
          console.log("progressEvent", progressEvent);
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setImageLoading(percentCompleted);
          if (percentCompleted === 100) {
            setTimeout(() => setImageLoading(""), 2000);
          }
        },
      });

      const url = afterUpload.config.url.split("?")[0];
      const splitName = url.split("/");
      const name = splitName[splitName.length - 1];
      setValue(name);
      setSelectImage(undefined);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        ...rootStyle,
      }}
    >
      <input
        type="file"
        id="file-upload"
        value={selectImage}
        onChange={handleUpload}
        accept="image/png, image/jpeg, image/jpg"
        hidden
      />
      <div style={{ position: "relative", width: "auto", height: "auto" }}>
        <label
          htmlFor="file-upload"
          style={{
            backgroundColor: "#E4E4E4E4",
            height: 160,
            width: 160,
            borderRadius: "10px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            ...style,
            border: !!meta?.error ? "1px solid red" : "none",
          }}
        >
          {value && !imageLoading ? (
            <img
              src={URL_PHOTO_AW3 + value}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
              {...other}
            />
          ) : (
            <div
              style={{
                display: "flex",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
                fontSize: 12,
                padding: 10,
                gap: 10,
                color: "#ababab",
              }}
            >
              {imageLoading ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <FontAwesomeIcon
                  icon={faFileImage}
                  style={{ width: 32, height: "auto", color: "#cacaca" }}
                />
              )}

              <span>{commentText}</span>
            </div>
          )}
        </label>
      </div>
    </div>
  );
};

export default UploadImageFormik;
