import React from "react";
import { Outlet } from "react-router-dom";
import OrderNewPage from "../pages/order/OrderNewPage";
import OrderTabs from "../pages/order/OrderTabs";

// eslint-disable-next-line
export default {
  path: "/orders",
  element: <Outlet />,
  children: [
    {
      path: "",
      // element: <OrderPage />,
      element: <OrderTabs />,
    },
    {
      path: "tab",
      element: <OrderTabs />,
    },
    {
      path: "ordernew",
      element: <OrderNewPage />,
    },
  ],
};
