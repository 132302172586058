import { Outlet } from "react-router-dom";
// page

import BankTransactionPage from "../pages/therdy/payment/BankTransactionPage";
import UserPage from "../pages/therdy/user/UserPage";
import ProductPage from "../pages/therdy/product/ProductPage";
import ReportPage from "../pages/therdy/report/ReportPage";
import BillPage from "../pages/therdy/bill/BillPage";
import LockPricePage from "../pages/therdy/payment/LockPricePage";
import ProductDetailPage from "../pages/therdy/product/ProductDetailPage";
import BillDetailPage from "../pages/therdy/bill/BillDetailPage";

const TherdyRoutes = {
  path: "/therdy",
  element: <Outlet />,
  children: [
    {
      path: "report",
      element: <ReportPage />,
    },
    {
      path: "bill",
      element: <BillPage />,
    },
    {
      path: "bill/detail",
      element: <BillDetailPage />,
    },
    {
      path: "payment",
      element: <Outlet />,
      children: [
        // { path: "", element: <ProductPage /> },
        { path: "bank-transaction", element: <BankTransactionPage /> },
        { path: "lock-price", element: <LockPricePage /> },
      ],
    },
    {
      path: "product",
      element: <Outlet />,
      children: [
        { path: "", element: <ProductPage /> },
        { path: "detail", element: <ProductDetailPage /> },
        // { path: "create-one", element: <ProductCreatePage /> },
        // { path: "order", element: <ProductOrderPage /> },
      ],
    },
    {
      path: "user",
      element: <Outlet />,
      children: [{ path: "", element: <UserPage /> }],
    },
  ],
};
export default TherdyRoutes;
