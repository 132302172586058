import { Outlet } from "react-router-dom";
import FarkCreatePage from "../pages/fark/FarkCreatePage";
import UserPage from "../pages/users/UserPage";

const UserRoutes = {
  path: "/user",
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <UserPage />,
    },
    {
      path: "create",
      element: <FarkCreatePage />,
    },
  ],
};

export default UserRoutes;
