import React from "react";

export default function Logo({ style }) {
  return (
    <div style={{ padding: 10 }}>
      <div
        style={{
          backgroundColor: "none",
          display: "grid",
          width: 100,
          gridTemplateColumns: "1fr 1fr",
          gap: 5,
        }}
      >
        <div style={{ backgroundColor: "#87a923", aspectRatio: 1 }}></div>
        <div style={{ backgroundColor: "#87a923", aspectRatio: 1 }}></div>
        <div style={{ backgroundColor: "#30573b", aspectRatio: 1 }}></div>
        <div style={{ backgroundColor: "#87a923", aspectRatio: 1 }}></div>
      </div>
    </div>
  );
}
