import React from "react";
import Spreadsheet from "react-spreadsheet";

export default function MenuSheetPage() {
  const data = [
    [{ value: "Vanilla" }, { value: "Chocolate" }],
    [{ value: "Strawberry" }, { value: "Cookies" }],
  ];
  return (
    <div>
      <Spreadsheet data={data} />
    </div>
  );
}
