import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { IoMdDoneAll } from "react-icons/io";

interface PopUpConfirmPaymentProps {
  open: boolean;

  onClose: () => void;
  onSubmit: () => Promise<void>;
}

const PopUpConfirmPayment: React.FC<PopUpConfirmPaymentProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleSubmit = async () => {
    setButtonDisabled(true);
    await onSubmit();
    setButtonDisabled(false);
  };

  return (
    <Modal show={open} onHide={onClose} centered>
      <Modal.Header closeButton>ອັບເດດລາຍການທຸລະກຳ</Modal.Header>
      <Modal.Body>
        <div className="flex flex-col justify-center w-full items-center">
          <IoMdDoneAll style={{ width: 54, height: 54 }} />
          <div>ຍືນຍັນການເຮັດທຸລະກຳຖືກຕ້ອງ</div>
          {/* <div>{text2}</div> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={buttonDisabled}
          onClick={handleSubmit}
          className="w-full"
        >
          ຢືນຢັນ
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopUpConfirmPayment;
