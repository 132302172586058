import {
  FaCogs,
  FaHome,
  FaLayerGroup,
  FaBoxOpen,
  FaTachometerAlt,
} from "react-icons/fa";
import { TbReorder } from "react-icons/tb";

export const BanhouseMenu: any[] = [
  {
    title: "ລາຍງານທັງໝົດ",
    key: "banhouse/report",
    icon: FaTachometerAlt,
    system: "banhouse",
  },
  {
    title: "ລາຍການອໍເດີ",
    key: "banhouse/product/order",
    icon: TbReorder,
    system: "banhouse",
  },
  {
    title: "ສະຖານະຂອງໂຕະ",
    key: "tables",
    icon: FaHome,
    system: "tableManagement",
  },
  {
    title: "ຈັດການສິນຄ້າ",
    key: "banhouse/product",
    icon: FaBoxOpen,
    system: "banhouse",
  },
  {
    title: "ສະຖິຕິການເງິນ",
    key: "report",
    icon: FaLayerGroup,
    system: "reportManagement",
  },
  {
    title: "ຕັ້ງຄ່າຮ້ານຄ້າ",
    key: "settingStore",
    icon: FaCogs,
    system: "settingManagement",
  },
];

export const TherdyCoMenu: any[] = [
  {
    title: "ລາຍງານ",
    key: "therdy/report",
    icon: FaTachometerAlt,
  },
  {
    title: "ລາຍການອໍເດີ",
    key: "therdy/bill",
    icon: TbReorder,
  },
  {
    title: "ລາຍການສິນຄ້າ",
    key: "therdy/product",
    icon: TbReorder,
  },
  {
    title: "ລາຍການ lockPrice",
    key: "therdy/payment/lock-price",
    icon: TbReorder,
  },
  {
    title: "ລາຍການໂອນເງິນ",
    key: "therdy/payment/bank-transaction",
    icon: TbReorder,
  },
  {
    title: "ລູກຄ້າ",
    key: "therdy/user",
    icon: TbReorder,
  },
];

export const Q23CoMenu: any[] = [
  {
    title: "ລາຍງານທັງໝົດ",
    key: "banhouse/report",
    icon: FaTachometerAlt,
    system: "banhouse",
  },
];

export const LeluckMenu: any[] = [
  {
    title: "ລາຍງານທັງໝົດ",
    key: "banhouse/report",
    icon: FaTachometerAlt,
    system: "banhouse",
  },
];
