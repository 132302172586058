import React, { useState, useEffect } from "react";
import { COLOR_APP } from "../../constants";
import { useStore } from "../../store";
import axios from "axios";
import { END_POINT } from "../../constants";
import { Modal, Button, Form } from "react-bootstrap";
import { successAdd, errorAdd, warningAlert } from "../../helpers/sweetalert";
import { getHeaders } from "../../services/auth";
import PopUpConfirmDeletion from "../../components/popup/PopUpConfirmDeletion";
import { useParams } from "react-router-dom";
import { QrReader } from "react-qr-reader";
import { END_POINT_WEB_CLIENT } from "../../constants/api";
import ButtonPrimary from "../../components/button/ButtonPrimary";

export default function SettingTable() {
  const [data, setData] = useState("No result");
  const params = useParams();
  const { tableListCheck, setTableListCheck, getTableDataStoreList } =
    useStore();
  useEffect(() => {
    getTableDataStoreList();
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [tableNumber, setTableNumber] = useState();
  const [sortNumber, setSortNumber] = useState(0);
  const [selectTatle, setSelectTatle] = useState();
  const _createTable = async () => {
    let header = await getHeaders();
    const headers = {
      "Content-Type": "application/json",
      Authorization: header.authorization,
    };
    try {
      if (!tableNumber) {
        warningAlert("ກະລຸນາປ້ອນລະຫັດ");
        return;
      }
      const createTable = await axios({
        method: "post",
        url: END_POINT + `/v3/table/create`,
        data: {
          sort: sortNumber,
          name: tableNumber,
          storeId: params?.id,
        },
        headers: headers,
      });
      handleClose();
      if (createTable?.data?.message === "INVALID_NAME") {
        warningAlert("ໂຕະນີ້ໄດ້ມີແລ້ວ");
      } else {
        getTableDataStoreList();
        successAdd("ການເພີ່ມໂຕະສຳເລັດ");
      }
    } catch (err) {
      errorAdd("ການເພີ່ມໂຕະບໍ່ສຳເລັດ");
    }
  };
  const [show4, setShow4] = useState(false);
  const [popup, setPopup] = useState({ qr: false });

  const _updateTable = async () => {
    let header = await getHeaders();
    const headers = {
      "Content-Type": "application/json",
      Authorization: header.authorization,
    };
    try {
      if (!selectTatle?.name) {
        warningAlert("ກະລຸນາປ້ອນລະຫັດ");
        return;
      }
      const createTable = await axios({
        method: "put",
        url: END_POINT + `/v3/table/update`,
        data: {
          id: selectTatle?.id,
          data: {
            sort: selectTatle?.sort || 0,
            name: selectTatle?.name || "null",
            codeId: selectTatle?.codeId,
          },
        },
        headers: headers,
      });
      setShow4(false);
      if (createTable?.data?.message === "INVALID_NAME") {
        warningAlert("ໂຕະນີ້ໄດ້ມີແລ້ວ");
      } else {
        getTableDataStoreList();
        successAdd("ການແກ້ໄຂໂຕະສຳເລັດ");
      }
    } catch (err) {
      errorAdd("ການເພີ່ມໂຕະບໍ່ສຳເລັດ");
    }
  };
  const _changeStatusTable = async (data) => {
    try {
      if (data?.isOpened) {
        errorAdd("ບໍ່ສາມາດປິດໂຕະທີ່ມີແຂກໄດ້");
        return;
      }
      let header = await getHeaders();
      const headers = {
        "Content-Type": "application/json",
        Authorization: header.authorization,
      };
      const status = !data?.status ? "true" : "false";
      let res = await axios({
        method: "PUT",
        url: END_POINT + `/v3/code/update/`,
        data: {
          id: data._id,
          data: {
            status,
          },
        },
        headers: headers,
      });
      getTableDataStoreList();
    } catch (err) {
      console.log("err:", err);
    }
  };
  // ======> delete ====> delete
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const [dateDelete, setdateDelete] = useState("");
  const handleShow3 = (item) => {
    setdateDelete(item);
    setShow3(true);
  };
  const handleShow4 = (item) => {
    console.log(item);
    setSelectTatle({
      ...item,
      id: item?.tableId,
      name: item?.tableName || "",
      sort: item?.sort || 0,
      codeId: item?._id,
    });
    setShow4(true);
  };
  const _confirmeDelete = async () => {
    let header = await getHeaders();
    const headers = {
      "Content-Type": "application/json",
      Authorization: header.authorization,
    };
    if (dateDelete?.isOpened === true) {
      handleClose3();
      warningAlert("ລົບຂໍ້ມູນບໍ່ສຳເລັດເພາະກຳລັງໃຊ້ງານ");
      return;
    }
    const resData = await axios({
      method: "DELETE",
      url: END_POINT + `/v3/code/delete/` + dateDelete?._id,
      headers: headers,
    });
    if (resData.status < 300) {
      handleClose3();
      setTableListCheck((prev) => prev.filter((e) => e._id !== dateDelete?._id));
      successAdd("ລົບຂໍ້ມູນສຳເລັດ");
    }
  };
  return (
    <div
      style={{ padding: 10, height: "100%", overflow: "auto" }}
      className="col-sm-12"
    >
      <div style={{ backgroundColor: "#FAF9F7", padding: 10, borderRadius: 8 }}>
        <div className="col-sm-12 text-right">
          <button
            className="col-sm-2"
            style={{
              backgroundColor: COLOR_APP,
              color: "#ffff",
              border: 0,
              padding: 10,
            }}
            onClick={handleShow}
          >
            ເພີ່ມໂຕະ
          </button>
        </div>
        <div style={{ height: 20 }}></div>
        <div style={{ width: "100%", overflow: "auto" }}>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                {/* <th scope="col">#</th> */}
                <th scope="col">ລະຫັດ</th>
                {/* <th scope="col">ການເປີດ/ປິດ</th> */}
                {/* <th scope="col">ມີແຂກເຂົ້າແລ້ວ</th> */}
                <th scope="col" style={{ textAlign: "right" }}>
                  ຈັດການ
                </th>
              </tr>
            </thead>
            <tbody>
              {tableListCheck?.map((table, index) => {
                return (
                  <tr>
                    {/* <td>{index + 1}</td> */}
                    <td>
                      {table?.tableName}
                      {/* {table?.isOpened ? (
                        ""
                      ) : (
                        <span style={{ color: "red" }}> - (ປິດ)</span>
                      )} */}
                    </td>
                    {/* <td>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={table?.status === true ? true : false}
                          onClick={(e) => _changeStatusTable(table)}
                        />
                        <span className="slider round"></span>
                      </label>
                    </td> */}
                    {/* <td
                      style={{
                        color: table?.isOpened === true ? "green" : "red",
                      }}
                    >
                      {table?.isOpened === true ? "ມີແລ້ວ" : "ຍັງບໍ່ມີ"}
                    </td> */}
                    <td>
                      <div
                        style={{
                          display: "flex",
                          gap: 10,
                          justifyContent: "flex-end",
                        }}
                      >
                        <ButtonPrimary
                          onClick={() => handleShow3(table)}
                          disabled={table?.isOpened}
                        >
                          faTrashAlt
                        </ButtonPrimary>
                        <ButtonPrimary
                          onClick={() => handleShow4(table)}
                          disabled={table?.isOpened}
                        >
                          faEdit
                        </ButtonPrimary>
                        <ButtonPrimary
                          onClick={() => {
                            setSelectTatle(table);
                            setPopup({ qr: true });
                          }}
                        >
                          faQrcode
                        </ButtonPrimary>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>ເພີ່ມໂຕະ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>ລຳດັບ</Form.Label>
            <div style={{ height: 10 }}></div>
            <Form.Control
              type="number"
              placeholder="ກະລຸນາປ້ອນລຳດັບ"
              onChange={(e) => setSortNumber(e?.target?.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>ລະຫັດ</Form.Label>
            <div style={{ height: 10 }}></div>
            <Form.Control
              type="text"
              placeholder="ກະລຸນາປ້ອນລະຫັດ"
              onChange={(e) => setTableNumber(e?.target?.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            ຍົກເລີກ
          </Button>
          <Button
            style={{ backgroundColor: COLOR_APP, color: "#ffff", border: 0 }}
            onClick={() => _createTable()}
          >
            ບັກທືກ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ===== edit ===== */}
      <Modal show={show4} onHide={() => setShow4(false)}>
        <Modal.Header closeButton>
          <Modal.Title>ແກ້ໄຂໂຕະ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>ລຳດັບ</Form.Label>
            <div style={{ height: 10 }}></div>
            <Form.Control
              type="number"
              placeholder="ກະລຸນາປ້ອນລຳດັບ"
              value={selectTatle?.sort || 0}
              onChange={(e) =>
                setSelectTatle({ ...selectTatle, sort: e.target.value || 0 })
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>ລະຫັດ</Form.Label>
            <div style={{ height: 10 }}></div>
            <Form.Control
              type="text"
              placeholder="ກະລຸນາປ້ອນລະຫັດ"
              value={selectTatle?.name}
              onChange={(e) =>
                setSelectTatle({ ...selectTatle, name: e?.target?.value })
              }
            />
          </Form.Group>
          <div>ເປີດໃຊ້ງານ</div>
          <label className="switch">
            <input
              type="checkbox"
              checked={selectTatle?.status === true ? true : false}
              onClick={(e) => {
                _changeStatusTable(selectTatle);
                setShow4(false);
              }}
            />
            <span className="slider round"></span>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow4(false)}>
            ຍົກເລີກ
          </Button>
          <Button
            style={{ backgroundColor: COLOR_APP, color: "#ffff", border: 0 }}
            onClick={() => _updateTable()}
          >
            ບັກທືກ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ===== delete ===== */}
      <PopUpConfirmDeletion
        open={show3}
        onClose={() => setShow3(false)}
        onSubmit={_confirmeDelete}
        text={dateDelete?.code}
      />
      {/* ===== qr ===== */}

      <Modal show={popup?.qr} onHide={() => setPopup()}>
        <Modal.Header closeButton>
          <Modal.Title>QR </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QrReader
            onResult={(result, error) => {
              console.log(result);
              if (result) {
                axios
                  .put(result?.text, {
                    type: "LINK",
                    "details.redirect": `${END_POINT_WEB_CLIENT}${selectTatle?.storeId}?tableId=${selectTatle?.tableId}`,
                  })
                  .then(() => {
                    setPopup();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }

              // if (!!error) {
              //   console.info(error);
              // }
            }}
            constraints={{
              facingMode: "environment",
            }}
            style={{ width: "100%" }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
