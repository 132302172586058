import styled from "styled-components";
import { useState, useEffect } from "react";
import { moneyCurrency } from "../../helpers/index";
import moment from "moment";
import { QUERY_CURRENCIES, getLocalData } from "../../constants/api";
import Axios from "axios";

interface BillForCheckOut80Props {
  storeDetail: {
    name?: string;
    phone?: string;
    whatsapp?: string;
    printer?: { qr?: string };
  };
  selectedTable: {
    tableName?: string;
  };
  dataBill: {
    orderId?: { quantity: number; price: number; name: string }[];
    code?: string;
    discount?: number;
    discountType?: string;
    dataCustomer?: { username?: string; phone?: string };
    createdAt?: string;
    moneyReceived?: number;
    moneyChange?: number;
  };
  taxPercent?: number;
}

interface CurrencyData {
  currencyCode: string;
  sell: number;
}

export default function BillForCheckOut80({
  storeDetail,
  selectedTable,
  dataBill,
  taxPercent = 0,
}: BillForCheckOut80Props) {
  const [total, setTotal] = useState<number>(0);
  const [taxAmount, setTaxAmount] = useState<number>(0);
  const [totalAfterDiscount, setTotalAfterDiscount] = useState<number>(0);
  const [currencyData, setCurrencyData] = useState<CurrencyData[]>([]);

  useEffect(() => {
    _calculateTotal();
    getDataCurrency();
  }, []);

  useEffect(() => {
    _calculateTotal();
  }, [dataBill, taxPercent]);

  const _calculateTotal = () => {
    let _total = 0;
    dataBill?.orderId?.forEach(item => {
      _total += item.quantity * item.price;
    });
    const discount =
      dataBill?.discountType === "LAK" || dataBill?.discountType === "MONEY"
        ? dataBill.discount
        : (_total * (dataBill.discount || 0)) / 100;
    setTotalAfterDiscount(_total - (discount || 0));
    setTotal(_total);
    setTaxAmount((_total * taxPercent) / 100);
  };

  const getDataCurrency = async () => {
    try {
      const { DATA } = await getLocalData();
      if (DATA) {
        const { data } = await Axios.get(`${QUERY_CURRENCIES}?storeId=${DATA.storeId}`);
        if (data?.status === 200) {
          setCurrencyData(data?.data?.data);
        }
      }
    } catch (err) {
      console.error("Currency data fetch error:", err);
    }
  };

  return (
    <Container>
      <div style={{ textAlign: "center" }}>{storeDetail?.name}</div>
      <div style={{ textAlign: "center" }}>{selectedTable?.tableName}</div>
      <Price>
        <div style={{ textAlign: "left", fontSize: 12 }}>
          <div>ເບີໂທ: <span style={{ fontWeight: "bold" }}>{storeDetail?.phone}</span></div>
          <div>Whatapp: <span style={{ fontWeight: "bold" }}>{storeDetail?.whatsapp}</span></div>
          <div>ລະຫັດໂຕະ: <span style={{ fontWeight: "bold" }}>{dataBill?.code}</span></div>
          <div>ວັນທີ: <span style={{ fontWeight: "bold" }}>{moment(dataBill?.createdAt).format("DD-MM-YYYY")}</span></div>
        </div>
        <div style={{ flexGrow: 1 }}></div>
      </Price>
      <Name style={{ marginBottom: 10 }}>
        <div style={{ textAlign: "left" }}>ລາຍການ</div>
        <div style={{ textAlign: "center" }}>ຈຳນວນ</div>
        <div style={{ textAlign: "right" }}>ລາຄາ</div>
        <div style={{ textAlign: "right" }}>ລວມ</div>
      </Name>
      <Order>
        {dataBill?.orderId?.map((item, index) => (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              fontSize: 14,
            }}
            key={index}
          >
            <div style={{ textAlign: "left" }}>{item.name}</div>
            <div style={{ textAlign: "center" }}>{item.quantity}</div>
            <div style={{ textAlign: "right" }}>{item.price ? moneyCurrency(item.price) : "-"}</div>
            <div style={{ textAlign: "right" }}>{item.price ? moneyCurrency(item.price * item.quantity) : "-"}</div>
          </div>
        ))}
      </Order>
      <div style={{ height: 10 }}></div>
      <hr style={{ border: "1px solid #000", margin: 0 }} />
      <div style={{ fontSize: 14 }}>
        <div>
          <div>ລວມ: {moneyCurrency(total)} ກີບ</div>
          <div>ລວມ + ພາສີ {taxPercent}%: {moneyCurrency(total + taxAmount)} ກີບ</div>
          {currencyData.map((item, index) => (
            <div key={index}>ລວມ + ພາສີ {taxPercent}% ({item.currencyCode}): {moneyCurrency((total + taxAmount) / item.sell)}</div>
          ))}
          <div>ສ່ວນຫຼຸດ: {dataBill.discount} {dataBill.discountType === "MONEY" || dataBill.discountType === "LAK" ? "ກີບ" : "%"}</div>
          <div>ລູກຄ້າ : {dataBill.dataCustomer?.username} ({dataBill.dataCustomer?.phone})</div>
        </div>
      </div>
      <hr style={{ border: "1px solid #000", margin: 0 }} />
      <div style={{ height: 10 }}></div>
      <Price>
        <h6>ເງິນທີ່ຕ້ອງຊຳລະ {moneyCurrency(totalAfterDiscount + taxAmount)} ກີບ</h6>
      </Price>
      <Price>
        <div style={{ flexGrow: 1 }}></div>
        <div style={{ display: "flex", gap: 10, fontSize: 12 }}>
          <div>ຮັບເງີນມາ {dataBill.moneyReceived || 0}</div>
          <div>ເງີນທອນ {dataBill.moneyChange || 0}</div>
        </div>
      </Price>
      <div style={{ display: "flex", justifyContent: "center", padding: 10 }}>
        <Img>
          <img
            src={`https://app-api.banhouse.la/qr-gennerate/qr?data=${storeDetail.printer?.qr}`}
            style={{ width: "100%", height: "100%" }}
            alt=""
          />
        </Img>
      </div>
    </Container>
  );
}

const Name = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;
const Price = styled.div`
  display: flex;
`;
const Container = styled.div`
  margin: 10px;
  width: 100%;
  /* maxwidth: 80mm; */
`;
const Img = styled.div`
  width: 200px;
  height: 200px;
  font-size: 14px;
  border: 2px dotted #000;
`;
const Order = styled.div`
  display: flex;
  flex-direction: column;
`;
