// @ts-nocheck
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Col, ListGroup, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";

export default function SettingList() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const data = [
    {
      id: "479af7e5-1947-426d-b511-d95f0155f70f",
      title: t("restuarentSetting"),
      icon: "",
      path: `/settingStore/storeDetail/${params?.id}`,
    },
    {
      id: "0f83cb87-fc96-4212-b67d-2af6f33ed937",
      title: t("employeeManage"),
      icon: "",
      path: `/user`,
    },
    {
      id: "ab2dd4fe-617d-48f7-afa6-645fa3b8e04e",
      title: t("menuManage"),
      icon: "",
      path: `/settingStore/menu/limit/40/page/1/${params?.id}`,
    },
    {
      id: "1b76514a-d0e2-4808-89d1-3c66bc46d8ce",
      title: t("tableSetting"),
      icon: "",
      path: `/settingStore/settingTable/${params?.id}`,
    },
    {
      id: "42e27d60-6f12-446a-aa8f-ae6307b8ab34",
      title: t("stockManage"),
      icon: "",
      path: `/settingStore/stock/limit/40/page/1/${params?.id}`,
    },
    {
      id: "0f90941b-c594-4365-a279-a995868ede2a",
      title: t("printerSetting"),
      icon: "",
      path: `/printer`,
    },
    {
      id: "a2233469-a0b3-4247-9247-6282e2bafc1b",
      title: t("soundManage"),
      icon: "",
      path: `/audio`,
    },
    {
      id: "64bf476a-cbb6-43e1-abe1-29d4bdce7683",
      title: "POS Config",
      icon: "",
      path: `/config`,
    },
    {
      id: "a84952ca-c02b-91a0-fa30-2930ab39f01b",
      title: t("currencyManage"),
      icon: "",
      path: `/settingStore/currency/${params?.id}`,
    },
  ];

  return (
    <div style={{}}>
      <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
        <Row>
          <Col sm={4}>
            <ListGroup as="ol" numbered={true}>
              {data.map((e) => (
                <ListGroup.Item
                  onClick={() => navigate(e.path)}
                  key={e.id}
                  // action
                  // href={"#" + e?.title}
                >
                  {e.icon}
                  {e.title}
                </ListGroup.Item>
              ))}{" "}
            </ListGroup>
          </Col>{" "}
          <Col sm={8}>
            <Tab.Content>
              <Tab.Pane eventKey="#link1">Tab pane content 1</Tab.Pane>
              <Tab.Pane eventKey="#link2">Tab pane content 2</Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}

const ItemBox = styled("div")({
  padding: 10,
  height: "100%",
  color: "#212529",
  outlineColor: "#87A922",
  backgroundColor: "white",
  border: "1px solid  #E4E4E4",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: 10,
  borderRadius: 8,
  "&:hover": {
    backgroundColor: "rgba(251,110,59,0.2)",
    cursor: "pointer",
  },
});
