import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { TiCancel } from "react-icons/ti";
import { TfiLayoutListThumbAlt } from "react-icons/tfi";
import { RiCheckDoubleFill } from "react-icons/ri";
import { COLOR_APP } from "../../constants";
import { getOrderCountService, getOrdersService } from "../../services/order";

import { LiaCommentDots } from "react-icons/lia";
import moment from "moment";
import { isEmpty } from "lodash";
import { useStore } from "../../store";
import { getLocalData } from "../../constants/api";

export default function OrderNewPage() {
  // state
  const [orders, setOrders] = useState();
  const [orderCount, setOrderCount] = useState();
  const [selectOrders, setSelectOrders] = useState();
  const [mergeOrders, setMergeOrders] = useState();

  // store
  const {
    newOrderTransaction,
    setNewOrderTransaction,
    newOrderUpdateStatusTransaction,
    setNewOrderUpdateStatusTransaction,
  } = useStore();

  // useEffect
  useEffect(() => {
    getOrders(); // ໂຫຼດອໍເດີໃໝ່
    getOrderCount(); // ໂຫຼດຈຳນວນອໍເດີ
  }, []);
  useEffect(() => {
    console.log("newOrderTransaction", newOrderTransaction);
    console.log(
      "newOrderUpdateStatusTransaction",
      newOrderUpdateStatusTransaction
    );
    if (newOrderTransaction || newOrderUpdateStatusTransaction) {
      setNewOrderTransaction(false);
      setNewOrderUpdateStatusTransaction(false);
      getOrders(); // ໂຫຼດອໍເດີໃໝ່
      getOrderCount(); // ໂຫຼດຈຳນວນອໍເດີ
    }
  }, [newOrderTransaction, newOrderUpdateStatusTransaction]);
  useEffect(() => {
    // merge ລະຫວ່າງອໍເດີທັງໝົດແລະອໍເດີທີເລືອກ
    if (isEmpty(selectOrders)) {
      setMergeOrders(orders);
    } else if (!isEmpty(orders)) {
      let _mergeOrders = [];
      for (let order of orders) {
        const _checkSelectOrder = selectOrders?.find((e) => e === order?._id);
        if (!isEmpty(_checkSelectOrder)) {
          _mergeOrders.push({ ...order, _select: true });
        } else {
          _mergeOrders.push({ ...order, _select: false });
        }
      }
      setMergeOrders(_mergeOrders);
    }
  }, [orders, selectOrders]);

  // function
  const getOrders = async () => {
    try {
      let _userData = await getLocalData();

      let findby = "?";
      findby += "storeId=" + _userData?.DATA?.storeId;
      findby += "&limit=" + 50;
      findby += "&skip=" + 0;
      findby += "&status=" + "NEW";

      const _data = await getOrdersService("v3", findby);
      if (_data.error) throw new Error(_data.message);
      setOrders(_data);
    } catch (error) {
      console.log("Error getOrders() ==> ", error);
    }
  };
  const getOrderCount = async () => {
    try {
      let _userData = await getLocalData();

      let findby = "?";
      findby += "storeId=" + _userData?.DATA?.storeId;
      findby += "&status=" + "DOING";

      const _data = await getOrderCountService("v3", findby);
      if (_data.error) throw new Error(_data.message);
      setOrderCount(_data?.count);
    } catch (error) {
      console.log("Error getOrderCount() ==> ", error);
    }
  };
  const hasSelect = (_id) => {
    const _check = selectOrders?.find((e) => e === _id);
    console.log("_check", _check);
    if (!isEmpty(_check)) return true;
    return false;
  };
  const handleSelectOrder = (order) => {
    const _hasSelect = hasSelect(order?._id);
    if (_hasSelect) {
      setSelectOrders((prev) => {
        const _orders = prev?.filter((e) => e !== order?._id);
        return _orders;
      });
    } else {
      setSelectOrders((prev) => [...(prev || []), order?._id]);
    }
  };

  const handleUpdateManyOrder = async (arrayOrderId, status) => {
    try {
      console.log("object");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          gap: 10,
          paddingTop: 10,
          paddingBottom: 10,
          position: "sticky",
          top: 0,
          backgroundColor: "white",
        }}
      >
        <Button variant="danger">
          <TiCancel />
          ຍົກເລີກອໍເດີ
        </Button>
        <Button
          variant="info"
          onClick={() => {
            let _orders = mergeOrders?.filter((e) => e?._select);
            let arrayOrderId = _orders?.map((e) => e?._id);
            handleUpdateManyOrder(arrayOrderId, "DOING");
          }}
        >
          <TfiLayoutListThumbAlt />
          ຍືນຍັນອໍເດີ
        </Button>
        <Button disabled>
          <RiCheckDoubleFill />
          ສຳເລັດອໍເດີ
        </Button>
      </div>

      <div>
        <Table style={{ width: "100%", maxWidth: 800 }}>
          <thead>
            <tr>
              <th style={{ maxWidth: "10px", padding: 0 }}></th>
              <th style={{ textAlign: "start" }}>
                ເລືອກ(0) -{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    color: COLOR_APP,
                    cursor: "pointer",
                  }}
                >
                  ເລືອກທັງໝົດ
                </span>
              </th>
              <th style={{ textAlign: "end", whiteSpace: "pre" }}>
                ຈຳນວນ/ລາຄາ
              </th>
            </tr>
          </thead>
          <tbody>
            {mergeOrders?.map((e, i) => (
              <tr
                style={{
                  backgroundColor: e?._select ? "#87a92236" : "#fff",
                }}
                onClick={() => {
                  handleSelectOrder(e);
                }}
                key={i}
              >
                <td
                  style={{
                    width: "10px",
                    maxWidth: "10px",
                    padding: 0,
                    backgroundColor: COLOR_APP,
                  }}
                ></td>
                <td
                  style={{
                    textAlign: "start",
                    padding: 5,
                  }}
                >
                  <div>
                    ໂຕະ:{" "}
                    <span style={{ color: COLOR_APP, fontWeight: "bold" }}>
                      {e?.tableId?.name}
                    </span>
                    <span style={{ fontSize: 10 }}> ({e?.code})</span>
                    <br />
                    <span
                      style={{
                        fontSize: 18,
                        fontWeight: 700,
                      }}
                    >
                      {e?.name}
                    </span>
                    <br />
                    <LiaCommentDots />: {e?.note}
                    <br />
                    <span style={{ fontSize: 10, color: "rgba(0,0,0,0.5)" }}>
                      {moment(e?.createdAt).format("DD-MM-YYYY HH:ss")}
                    </span>
                  </div>
                </td>
                <td style={{ textAlign: "end", whiteSpace: "pre" }}>
                  6<br />
                  18,000
                  <br />
                  {e?.status}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
