import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { COLOR_APP } from "../../constants";
import { IoMdCloseCircle } from "react-icons/io";
import Upload from "../Upload";
import { moneyCurrency } from "../../helpers";

interface PopUpAddMenuProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: any) => Promise<void>;
}

interface Option {
  nameOption: string;
  priceOption: string;
}

export default function PopUpAddMenu({
  open,
  onClose,
  onSubmit,
}: PopUpAddMenuProps) {
  // state
  const [isStockOne, setIsStockOne] = useState(false);
  const [isStockMulti, setIsStockMulti] = useState(false);
  const [isOption, setIsOption] = useState(false);
  const [option, setOption] = useState<Option[]>([]);
  const [addStock, setAddStock] = useState(false);
  const [addOption, setAddOption] = useState(false);

  const [nameOption, setNameOption] = useState<string>("");
  const [priceOption, setPriceOption] = useState<string>("");

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Modal show={open} onHide={onClose} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>ເພີ່ມເມນູອາຫານ</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          name: "",
          name_en: "",
          name_cn: "",
          name_kr: "",
          quantity: 1,
          categoryId: "",
          price: "",
          detail: "",
          images: [] as string[],
          unit: "",
          menuOptionId: "",
        }}
        validate={(values) => {
          const errors: { [key: string]: string } = {};
          if (!values.name) {
            errors.name = "ກະລຸນາປ້ອນຊື່ອາຫານ...";
          }
          if (parseInt(values.price) < 0 || isNaN(parseInt(values.price))) {
            errors.price = "ກະລຸນາປ້ອນລາຄາ...";
          }
          if (!values.categoryId) {
            errors.categoryId = "ກະລຸນາປ້ອນ...";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }: FormikHelpers<any>) => {
          onSubmit(values).then(() => {});
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="gap-[10px] md:flex">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Upload
                    src={values?.images?.[0] || ""}
                    removeImage={() => setFieldValue("images", [])}
                    onChange={(e: any) => {
                      setFieldValue("images", [e.name]);
                    }}
                  />
                </div>
                <div>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "80px 1fr",
                      gridGap: 10,
                    }}
                  >
                    <Form.Group>
                      <Form.Label>
                        ຊື່ອາຫານ <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        placeholder="ຊື່ອາຫານ..."
                        style={{
                          border:
                            errors.name && touched.name && errors.name
                              ? "solid 1px red"
                              : "",
                        }}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>ຊື່ອາຫານ (en)</Form.Label>
                      <Form.Control
                        type="text"
                        name="name_en"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name_en}
                        placeholder="ຊື່ອາຫານ..."
                        style={{
                          border:
                            errors.name_en && touched.name_en && errors.name_en
                              ? "solid 1px red"
                              : "",
                        }}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>ຊື່ອາຫານ (cn)</Form.Label>
                      <Form.Control
                        type="text"
                        name="name_cn"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name_cn}
                        placeholder="ຊື່ອາຫານ..."
                        style={{
                          border:
                            errors.name_cn && touched.name_cn && errors.name_cn
                              ? "solid 1px red"
                              : "",
                        }}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>ຊື່ອາຫານ (kr)</Form.Label>
                      <Form.Control
                        type="text"
                        name="name_kr"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name_kr}
                        placeholder="ຊື່ອາຫານ..."
                        style={{
                          border:
                            errors.name_kr && touched.name_kr && errors.name_kr
                              ? "solid 1px red"
                              : "",
                        }}
                      />
                    </Form.Group>
                  </div>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>
                      ປະເພດອາຫານ <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="categoryId"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.categoryId}
                      style={{
                        border:
                          errors.categoryId &&
                          touched.categoryId &&
                          errors.categoryId
                            ? "solid 1px red"
                            : "",
                      }}
                    >
                      <option selected={true} disabled={true} value="">
                        ເລືອກປະເພດອາຫານ
                      </option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
              <Form.Group>
                <Form.Label>
                  ລາຄາ <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  name="price"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.price}
                  placeholder="ລາຄາ..."
                  style={{
                    border:
                      errors.price && touched.price && errors.price
                        ? "solid 1px red"
                        : "",
                  }}
                />
              </Form.Group>
              <Form.Group style={{ display: "flex" }}>
                <Form.Check
                  id="isOption"
                  checked={isOption}
                  onChange={() => {
                    setIsOption((prev) => !prev);
                    setIsStockMulti(false);
                    setIsStockOne(false);
                  }}
                />
                <Form.Label htmlFor="isOption">ເພີ່ມ Option</Form.Label>
              </Form.Group>
              {isOption && !isStockMulti && !isStockOne && (
                <div
                  style={{
                    border: "1px dotted #ccc",
                    padding: 10,
                    borderRadius: 8,
                  }}
                >
                  {!addOption && (
                    <Button
                      onClick={() => setAddOption(true)}
                      style={{ marginBottom: 10 }}
                    >
                      ເພີ່ມວັດຖຸດິບ
                    </Button>
                  )}
                  {addOption && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 60px",
                        gridGap: 10,
                        marginBottom: 10,
                      }}
                    >
                      <Form.Control
                        type="text"
                        value={nameOption}
                        onChange={(e) => setNameOption(e.target.value)}
                        placeholder="ຊື່ Option"
                      />
                      <Form.Control
                        type="number"
                        value={priceOption}
                        onChange={(e) => setPriceOption(e.target.value)}
                        placeholder="ລາຄາ (ກີບ)"
                      />
                      <Button
                        onClick={() =>
                          setOption((prev) => [
                            ...prev,
                            { nameOption, priceOption },
                          ])
                        }
                      >
                        ເພີ່ມ
                      </Button>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      gap: 10,
                      flexWrap: "wrap",
                      maxHeight: 150,
                      overflow: "auto",
                    }}
                  >
                    {option.map((opt, idx) => (
                      <div
                        key={idx}
                        style={{
                          border: "1px solid #000",
                          padding: 5,
                          borderRadius: 8,
                        }}
                      >
                        {opt.nameOption} - {moneyCurrency(opt.priceOption)}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                style={{ color: COLOR_APP }}
                onClick={onClose}
              >
                <IoMdCloseCircle size={20} /> ຍົກເລີກ
              </Button>
              <Button
                variant="primary"
                style={{ backgroundColor: COLOR_APP }}
                type="submit"
                disabled={isSubmitting}
              >
                ບັນທຶກ
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
}
