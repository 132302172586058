import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { Button, Breadcrumb } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import DashboardFinance from "./DashboardFinance";
import "./index.css";
import useQuery from "../../helpers/useQuery";
import { useStore } from "../../store";
import {
  BsFillCalendarEventFill,
  BsFillCalendarWeekFill,
} from "react-icons/bs";
import { AiFillPrinter } from "react-icons/ai";
import { MdOutlineCloudDownload } from "react-icons/md";

export default function Dashboard() {
  const { accessToken } = useQuery();

  // state
  const [popup, setPopup] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [currency, setcurrency] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("LAK");
  const { storeDetail } = useStore();
  const newDate = new Date();

  const [startDate, setStartDate] = useState(
    moment(moment(newDate)).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(moment(newDate)).format("YYYY-MM-DD")
  );
  const [changeUi, setChangeUi] = useState("CHECKBILL");
  const [changeText, setChangeText] = useState("CLICK1");

  const _click1day = () => {
    setStartDate(moment(moment(newDate).add(-1, "days")).format("YYYY-MM-DD"));
    setEndDate(moment(moment(newDate)).format("YYYY-MM-DD"));
  };

  const _click7days = () => {
    setStartDate(moment(moment(newDate).add(-7, "days")).format("YYYY-MM-DD"));
    setEndDate(moment(moment(newDate)).format("YYYY-MM-DD"));
  };
  const _click30days = () => {
    setStartDate(moment(moment(newDate).add(-30, "days")).format("YYYY-MM-DD"));
    setEndDate(moment(moment(newDate)).format("YYYY-MM-DD"));
  };

  const { t } = useTranslation();

  const getcurrency = async () => {
    // try {
    //   let res = await axios.get(
    //     END_POINT_SEVER + `/v4/currencies?storeId=${storeDetail?._id}`
    //   );
    //   setcurrency(res.data ?? []);
    // } catch (err) {
    //   console.log(err);
    // }
  };

  useEffect(() => {
    getcurrency();
  }, []);

  return (
    <div style={{ padding: 20, overflow: "auto" }}>
      <Breadcrumb>
        <Breadcrumb.Item>ຕັ້ງຄ່າຮ້ານອາຫານ</Breadcrumb.Item>
        <Breadcrumb.Item active>ເມນູອາຫານ</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ marginBottom: 10, display: "flex", gap: 10 }}>
        <Button
          variant="outline-primary"
          size="small"
          style={{ display: "flex", gap: 10, alignItems: "center" }}
          onClick={() => setPopup({ popupfiltter: true })}
        >
          <BsFillCalendarWeekFill />
          <div>
            {startDate} {startTime}
          </div>{" "}
          ~{" "}
          <div>
            {endDate} {endTime}
          </div>
        </Button>
        <select
          onChange={(e) => setSelectedCurrency(e.target.value)}
          className="btn btn-outline-info"
        >
          <option value="LAK" className="option">
            ກີບ
          </option>
          {currency?.map((cur, index) => (
            <option key={cur + index} value={cur?.currencyCode}>
              {cur?.currencyName}
            </option>
          ))}
        </select>
        <Button
          variant="outline-primary"
          style={{ display: "flex", gap: 10, alignItems: "center" }}
          // onClick={() => setPopup({ PopupDaySplitView: true })}
        >
          <BsFillCalendarEventFill /> DAY SPLIT VIEW
        </Button>
        <div style={{ flex: 1 }} />
        <Button
          variant="outline-primary"
          style={{ display: "flex", gap: 10, alignItems: "center" }}
          onClick={() => setPopup({ printReport: true })}
        >
          <AiFillPrinter /> PRINT
        </Button>
        <Button
          variant="outline-primary"
          style={{ display: "flex", gap: 10, alignItems: "center" }}
        >
          <MdOutlineCloudDownload /> EXPORT
        </Button>
      </div>

      <DashboardFinance
        startDate={startDate}
        endDate={endDate}
        selectedCurrency={selectedCurrency}
      />
    </div>
  );
}

const Option = styled.option`
  cursor: pointer;
  padding: 10px;
  &:hover {
    background-color: #ffffff !important;
  }
`;
