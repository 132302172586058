import React from "react";
import { moneyCurrency } from "../../helpers";
import { ImImages } from "react-icons/im";

// Define props types
interface MenuCardItemProps {
  onClick?: () => void;
  name?: string;
  price?: number;
}

const MenuCardItem: React.FC<MenuCardItemProps> = ({
  onClick = () => {
    console.log("Click! MenuCardItem");
  },
  name = "Menu Name",
  price = 18_000,
}) => {
  return (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      <div
        style={{
          height: 100,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ccc",
        }}
      >
        <ImImages style={{ width: 50, height: 50 }} />
      </div>
      <div
        style={{
          backgroundColor: "#000",
          color: "#FFF",
          padding: 10,
        }}
      >
        <span>{name}</span>
        <br />
        <span>{moneyCurrency(price)}</span>
      </div>
    </div>
  );
};

export default MenuCardItem;
