// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Button,
  Form,
  Breadcrumb,
  Table,
} from "react-bootstrap";
import fileDownload from "js-file-download";
import { COLOR_APP, END_POINT, URL_PHOTO_AW3 } from "../../constants";
import { AiFillCloseSquare } from "react-icons/ai";
import { IoIosCheckbox } from "react-icons/io";
import { FaFileCsv } from "react-icons/fa";
import html2canvas from "html2canvas";
import PopUpSetStartAndEndDate from "../../components/popup/PopUpSetStartAndEndDate";
import moment from "moment";
import { useStore } from "../../store";
import "moment/locale/lo";
import StarComponent from "../../components/StarComponent";
import { useNavigate, useParams } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import Axios from "axios";
import QRCode from "react-qr-code";
import axios from "axios";
import "moment/locale/lo";
import { errorAdd } from "../../helpers/sweetalert";
import { formatToTwoDecimalPlaces } from "../../helpers";
import PaginationBh from "../../components/PaginationBh";
import usePagination from "../../hooks/usePagination";

export default function ReportReviewStorePage() {
  moment.locale("lo");
  const navigate = useNavigate();

  const { storeId } = useParams();
  // state
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [startTime, setStartTime] = useState("00:00:00");
  const [endTime, setEndTime] = useState("23:59:59");
  const [popup, setPopup] = useState();
  const [reviewData, setReviewData] = useState();
  const [storeData, setStoreData] = useState();
  const [reviewCount, setReviewCount] = useState();
  const [substarData, setSubstarData] = useState();
  const [loadingExportCsv, setLoadingExportCsv] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [totalPagination, setTotalPagination] = useState(0);
  const { skip, limit } = usePagination(pagination);
  const imageRef = useRef(null);

  // provider
  const { storeDetail } = useStore();

  // useEffect
  useEffect(() => {
    // getDataReviews();
    getDataStore();
    getStoreReviewCountData();
    getDataSubstars();
  }, [storeId]);

  useEffect(() => {
    getDataReviews();
  }, [skip]);

  // function
  const handleDownload = () => {
    const container = imageRef.current;

    if (!container) return;

    html2canvas(container).then((canvas) => {
      // Convert canvas to image data URL
      const dataUrl = canvas.toDataURL("image/png");

      // Create a temporary anchor element to trigger the download
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "downloaded_image.png"; // Change the file name and extension as needed
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };
  const getDataStore = async () => {
    try {
      let url = END_POINT + "/store/get-one/" + storeId;
      url += "?p=substars.substarId";
      const _res = await Axios.get(url);
      setStoreData(_res?.data);
    } catch (err) {}
  };
  // function
  const getDataReviews = async () => {
    try {
      let url = END_POINT + "/review/get-many?";
      url += "storeId=" + storeId;
      url += "&p=substars.substarId";
      url += "&skip=" + skip;
      url += "&limit=" + limit;

      const _res = await Axios.get(url);
      setReviewData(_res?.data);
    } catch (err) {}
  };
  const getDataSubstars = async () => {
    try {
      let url = END_POINT + "/substar/get-many?";
      url += "storeId=" + storeDetail?._id;

      const _res = await Axios.get(url);
      setSubstarData(_res?.data);
    } catch (err) {}
  };

  const getStoreReviewCountData = async () => {
    try {
      const _res = await axios.get(
        END_POINT + "/review/get-count?storeId=" + storeId
      );
      if (_res.status >= 300) throw new Error("");
      setReviewCount(_res.data.count);
      setTotalPagination(Math.ceil(_res.data.count / limit));
    } catch (err) {
      console.log("err:", err);
    }
  };
  const downloadCsv = async () => {
    try {
      setLoadingExportCsv(true);
      const url = END_POINT + "/export/reviews?storeId=" + storeId;
      const _res = await Axios.get(url);
      fileDownload(_res.data, storeData?.name + ".csv" || "export.csv");
      setLoadingExportCsv(false);
    } catch (err) {
      setLoadingExportCsv(false);
      errorAdd("Export ບໍ່ສຳເລັດ");
    }
  };
  return (
    <div
      style={{ padding: 20, overflowY: "auto", height: "calc(100dvh - 64px)" }}
    >
      <Breadcrumb>
        <Breadcrumb.Item>ລາຍງານ</Breadcrumb.Item>
        <Breadcrumb.Item active>ລາຍງານລູກຄ້າລີວິວ</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          marginBottom: 20,
          display: "flex",
          flexDirection: "row",
          gap: 10,
        }}
      >
        <div style={{ display: "flex", gap: 10 }}>
          <Form.Control placeholder="ຄົ້ນຫາ" />
          <Button
            variant="primary"
            style={{ display: "flex", gap: 10, alignItems: "center" }}
          >
            <FaSearch /> ຄົ້ນຫາ
          </Button>
        </div>

        {/* <Button
          variant="outline-primary"
          size="small"
          style={{ display: "flex", gap: 10, alignItems: "center" }}
          onClick={() => setPopup({ popupfiltter: true })}
        >
          <BsFillCalendarWeekFill />
          <div>
            {startDate} {startTime}
          </div>{" "}
          ~{" "}
          <div>
            {endDate} {endTime}
          </div>
        </Button> */}
        <div style={{ flex: 1 }} />
        <Button
          variant="outline-primary"
          style={{ display: "flex", gap: 10, alignItems: "center" }}
          onClick={downloadCsv}
          disabled={loadingExportCsv}
        >
          <FaFileCsv /> Export (CSV)
        </Button>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 2fr",
          gap: 20,
          marginBottom: 20,
        }}
      >
        <Card border="primary">
          <Card.Header>ຂໍ້ມູນຮ້ານ</Card.Header>
          <Card.Body>
            <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 10 }}>
              ຊື່ຮ້ານ: {storeData?.name}
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
              <div style={{ aspectRatio: 2 }}>
                <img
                  src={URL_PHOTO_AW3 + storeData?.image}
                  alt=""
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card border="primary">
          <Card.Header>QR-Code</Card.Header>
          <Card.Body>
            <div>
              <div
                style={{
                  width: 140,
                  height: 140,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 10,
                }}
                ref={imageRef}
              >
                <QRCode
                  value={"https://review.banhouse.la/store/" + storeId}
                  size={120}
                />
              </div>
              <br />
              <a
                href={"https://review.banhouse.la/store/" + storeId}
                target="_blank" rel="noreferrer"
              >
                {">>Link<<"}
              </a>
              <br />
              <Button onClick={handleDownload}>Download QR</Button>
            </div>
          </Card.Body>
        </Card>

        <Card border="primary">
          <Card.Header>
            ຄະແນນລວມ - (ອັບເດດລ່າສຸດ{" "}
            {moment(storeData?.updatedReviewAt).format("LLL")})
          </Card.Header>
          <Card.Body>
            <div>
              {storeData?.substars?.map((e) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <StarComponent
                    star={e?.averageStars}
                    onChangeStar={() => {}}
                    rootStyle={{ gap: 2 }}
                    starStyle={{ width: 10, height: 10 }}
                  />
                  ({formatToTwoDecimalPlaces(e?.averageStars)} /{" "}
                  {reviewCount || "-"} ຄັ້ງ)
                  <div>{e?.substarId?.title}</div>
                </div>
              ))}
            </div>
          </Card.Body>
        </Card>
      </div>

      <div>
        <Card border="primary">
          <Card.Header style={{ backgroundColor: COLOR_APP, color: "white" }}>
            ຈຳນວນການລີວິວ
          </Card.Header>
          <Table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th style={{ width: 60 }}>#</th>
                <th>ຊື່</th>
                <th>ຈຳນວນດາວ</th>
                <th>ຄວາມຄິດເຫັນ</th>
                <th>ເພດ</th>
                <th>ປີເກີດ</th>
                <th>ຈະກັບມາອີກ</th>
                <th>ເວລາ</th>
              </tr>
            </thead>
            <tbody>
              {reviewData?.map((e, i) => (
                <tr>
                  <td style={{ width: 60 }}>
                    {(pagination - 1) * limit + (i + 1)}
                  </td>
                  <td>{e?.name}</td>
                  <td>
                    {e?.substars?.map((e2) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 10,
                        }}
                      >
                        <StarComponent
                          star={e2?.star || 5}
                          onChangeStar={() => {}}
                          rootStyle={{ gap: 2 }}
                          starStyle={{ width: 10, height: 10 }}
                        />
                        <div>({e2?.star})</div>
                        <div>{e2?.substarId?.title}</div>
                      </div>
                    ))}
                  </td>
                  <td>{e?.comment}</td>
                  <td>
                    {e?.sex === "f" ? "ຊາຍ" : e?.sex === "m" ? "ຍິງ" : ""}
                  </td>
                  <td>{e?.birthYear ? 2024 - e?.birthYear : ""}</td>
                  <td>
                    {e?.youComeBack ? (
                      e?.youComeBack === "YES" ? (
                        <IoIosCheckbox
                          style={{ width: 40, height: 40, color: COLOR_APP }}
                        />
                      ) : (
                        <AiFillCloseSquare
                          style={{ width: 40, height: 40, color: "red" }}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </td>
                  <td>{moment(e?.createdAt).format("LLL")}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Card.Footer>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <PaginationBh
                totalPagination={totalPagination}
                setPagination={setPagination}
              />
            </div>
          </Card.Footer>
        </Card>
      </div>
      <PopUpSetStartAndEndDate
        open={popup?.popupfiltter}
        onClose={() => setPopup()}
        startDate={startDate}
        setStartDate={setStartDate}
        setStartTime={setStartTime}
        startTime={startTime}
        setEndDate={setEndDate}
        setEndTime={setEndTime}
        endTime={endTime}
        endDate={endDate}
      />
    </div>
  );
}
