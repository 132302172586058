import React, { useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { COLOR_APP } from "../../../constants";
import { paymentGetMany } from "../../../services/therdy/payment.service";
import PopUpAddTransaction from "../../../components/popup/therdy/PopUpAddTransaction";

type Props = {};

export default function BankTransactionPage({}: Props) {
  const [popup, setPopup] = useState<any>({});
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const data = await paymentGetMany("", "");
    console.log("data", data);
    setData(data);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <React.Fragment>
      <div className="p-4">
        {/* TODO: create flutter */}
        <Card border="primary">
          <Card.Header>
            <div className="flex items-center">
              <div>Bank Transaction</div>
              <div className="flex-1" />
              <Button onClick={() => setPopup({ PopUpAddTransaction: true })}>
                Add Transaction
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            <Table>
              <table>
                <tr
                  style={{
                    fontWeight: 700,
                    fontSize: 18,
                    textDecoration: "underline",
                  }}
                >
                  <th>#</th>
                  <th>Bank</th>
                  <th>Ref</th>
                  <th>Amount</th>
                  <th>Fee</th>
                  <th>Date/Time</th>
                  <th>Status</th>
                  <th>Note</th>
                </tr>
                {data?.map((e: any, i: any) => (
                  <tr key={i}>
                    <td className="text-left">{i + 1}</td>
                    <td className="text-left">{e?.platform}</td>
                    <td className="text-left">{e?.ref}</td>
                    <td>{e?.transactionAmount}</td>
                    <td>{e?.fee}</td>
                    <td>
                      {e?.date} {e?.time}
                    </td>
                    <td>{e?.status}</td>
                    <td>{e?.note}</td>
                  </tr>
                ))}
              </table>
            </Table>
          </Card.Body>
        </Card>
      </div>
      <PopUpAddTransaction
        open={popup?.PopUpAddTransaction}
        onClose={() => setPopup({})}
        productId={""}
      />
    </React.Fragment>
  );
}
