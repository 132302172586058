import { Modal, Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import UploadImageFormik from "../../UploadImageFormik";
import CustomInput from "../../CustomInput";
import CustomSelect from "../../CustomSelect";
import validationSchema from "../../../utils/validationSchema";
import {
  productCreateOne,
  productDeleteOne,
} from "../../../services/therdy/product.service";
import { errorAdd, successAdd } from "../../../helpers/sweetalert";

interface PopUpAddProductProps {
  open: boolean;
  onClose: () => void;
  productId: string;
}

export default function PopUpDeleteProduct({
  open,
  onClose,
  productId,
}: PopUpAddProductProps) {
  const onSubmit = async (values: any) => {
    const { error } = await productDeleteOne(productId, "");
    if (error) {
      errorAdd("error something");
    } else {
      successAdd("success");
      onClose();
    }
  };
  return (
    <Modal show={open} onHide={onClose} keyboard={false}>
      <Modal.Header>
        <Modal.Title>Create Product</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{}}
        onSubmit={onSubmit}
      >
        <Form>
          <Modal.Body>
            <div>Delete Product _id: {productId}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Confirm Delete
            </Button>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  );
}
