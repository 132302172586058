import React, { useEffect, useState } from "react";
import { Button, Form, Card, Pagination, Table } from "react-bootstrap";
import { Breadcrumb, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
import PopUpAddPackage from "../../../components/popup/therdy/PopUpAddPackage";
import useQuery from "../../../helpers/useQuery";
import { billGetOne } from "../../../services/therdy/bill.service";
import { orderGetMany } from "../../../services/therdy/order.service";

export default function BillDetailPage() {
  const { billId } = useQuery();

  const _id = billId;

  const navigate = useNavigate();
  // state
  const [pagination, setPagination] = useState(1);
  const [totalPagination, setTotalPagination] = useState(10);
  const [billData, setBillData] = useState<any>();
  const [orderData, setOrderData] = useState<any[]>([]);

  const [popup, setPopup] = useState<any>({});

  // store
  // const { storeDetail }: any = useStore();

  // useEffect
  useEffect(() => {
    getData();
  }, []);
  // function
  const getData = async () => {
    try {
      const data = await billGetOne(_id, "");
      const _packages = await orderGetMany(
        "billId=" + _id + "&p=productId",
        ""
      );
      setOrderData(_packages);
      console.log(data);
      setBillData(data);
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <React.Fragment>
      <div className="p-4">
        <Breadcrumb>
          <Breadcrumb.Item>THERDY CO</Breadcrumb.Item>
          <Breadcrumb.Item>ລາຍການອໍເດີ</Breadcrumb.Item>
          <Breadcrumb.Item active>{billData?.name || _id}</Breadcrumb.Item>
        </Breadcrumb>
        <Tabs defaultActiveKey="user-list">
          <Tab eventKey="user-list" title="ລາຍລະອຽດສິນຄ້າ" className="pt-2">
            <Card border="primary" className="mb-2">
              <Card.Header className="flex items-center">
                ລາຍລະອຽດສິນຄ້າ
              </Card.Header>
              <Card.Body>
                <div>
                  <div className="flex-1">
                    <div>_id: {billData?._id || "--"}</div>
                    <div>ref: {billData?.code || "--"}</div>
                    <div>status: {billData?.status || "--"}</div>
                    <div>total: {billData?.total || "--"}</div>
                    <div>
                      Date:{" "}
                      {billData?.createdAt
                        ? moment(billData?.createdAt).format("DD/MM/YYYY LT")
                        : "--"}
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card border="primary">
              <Card.Header className="flex items-center">
                <div>ລາຍການແພັກເກດ</div>
                <div className="flex-1" />
                <Button onClick={() => setPopup({ PopUpAddPackage: true })}>
                  Add Package
                </Button>
              </Card.Header>
              <Card.Body>
                <Table>
                  <table>
                    <tr>
                      <th className="text-start">#</th>
                      <th className="text-start">Product</th>
                      <th className="text-start">Provider</th>
                      <th className="text-start">Package</th>
                      <th className="text-start">Amount</th>
                      <th className="text-start">status</th>
                      <th className="text-start">Note</th>
                      <th className="text-start">UpdatedAt</th>
                      <th className="text-start">Tool</th>
                    </tr>
                    {orderData?.map((value: any, index: any) => (
                      <tr key={index}>
                        <td className="text-start">{index + 1}</td>
                        <td className="text-start">
                          {value?.productId?.name ?? "--"}
                        </td>
                        <td className="text-start">
                          {value?.productId?.type ?? "--"}
                        </td>
                        <td className="text-start">
                          {value?.productPackageRef?.packageGame ?? "--"}
                        </td>
                        <td className="text-start">
                          {value?.productPackageRef?.quantityGame ?? "--"}
                        </td>
                        <td className="text-start">{value?.status ?? "--"}</td>
                        <td className="text-start">{"--"}</td>
                        <td className="text-start">
                          {moment(value?.UpdatedAt).format("DD/MM/YYYY LT")}
                        </td>
                        <td>
                          <Button variant="outline-primary">Update</Button>
                        </td>
                      </tr>
                    ))}
                  </table>
                </Table>
              </Card.Body>
              <Card.Footer>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    bottom: 20,
                  }}
                >
                  <ReactPaginate
                    previousLabel={
                      <span className="glyphicon glyphicon-chevron-left">{`ກ່ອນໜ້າ`}</span>
                    }
                    nextLabel={
                      <span className="glyphicon glyphicon-chevron-right">{`ຕໍ່ໄປ`}</span>
                    }
                    breakLabel={<Pagination.Item disabled>...</Pagination.Item>}
                    breakClassName={"break-me"}
                    pageCount={totalPagination} // Replace with the actual number of pages
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={(e) => {
                      console.log(e);
                      setPagination(e?.selected + 1);
                    }}
                    containerClassName={"pagination justify-content-center"} // Bootstrap class for centering
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                  />
                </div>
              </Card.Footer>
            </Card>
          </Tab>
          <Tab eventKey="history" title="ປະຫວັດລາຍການ" disabled></Tab>
        </Tabs>
      </div>
      <PopUpAddPackage
        open={popup?.PopUpAddPackage}
        onClose={() => {
          setPopup({});
        }}
        productId={_id}
      />
    </React.Fragment>
  );
}
