import React from "react";
import { Pagination } from "react-bootstrap";
import ReactPaginate from "react-paginate";

export default function PaginationBh({ totalPagination, setPagination }) {
  return (
    <ReactPaginate
      previousLabel={
        <span className="glyphicon glyphicon-chevron-left">{`ກ່ອນໜ້າ`}</span>
      }
      nextLabel={
        <span className="glyphicon glyphicon-chevron-right">{`ຕໍ່ໄປ`}</span>
      }
      breakLabel={<Pagination.Item disabled>...</Pagination.Item>}
      breakClassName={"break-me"}
      pageCount={totalPagination} // Replace with the actual number of pages
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
      onPageChange={(e) => {
        console.log(e);
        setPagination(e?.selected + 1);
      }}
      containerClassName={"pagination justify-content-center"} // Bootstrap class for centering
      pageClassName={"page-item"}
      pageLinkClassName={"page-link"}
      activeClassName={"active"}
      previousClassName={"page-item"}
      nextClassName={"page-item"}
      previousLinkClassName={"page-link"}
      nextLinkClassName={"page-link"}
    />
  );
}
