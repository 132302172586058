import styled from "styled-components";
import React from "react";

interface BillQRShortSmartOrdering80Props {
  CodeShortLink?: string;
  tableName?: string;
}

const BillQRShortSmartOrdering80: React.FC<BillQRShortSmartOrdering80Props> = ({
  CodeShortLink = "",
  tableName = "",
}) => {
  return (
    <Container>
      <h2>{tableName}</h2>
      <div>QR ສຳຫຼັບສັ່ງອາຫານ</div>
      <div>{`https://apz.pw/${CodeShortLink}`.toLowerCase()}</div>
      <Img>
        <img
          src={`https://app-api.banhouse.la/qr-gennerate/qr?data=https://apz.pw/${CodeShortLink}`}
          style={{ width: "100%", height: "100%" }}
          alt="QR Code"
        />
      </Img>
    </Container>
  );
};

export default BillQRShortSmartOrdering80;

const Container = styled.div`
  color: #000;
  width: 100%;
  max-width: 330px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Img = styled.div`
  width: 200px;
  height: 200px;
  font-size: 14px;
  border: 2px dotted #000;
`;
