import axios from "axios";
import { WarningNetworkFailure } from "../../helpers/alertsweet";
import { END_POINT_SEVER } from "../../constants/api";
import { Product } from "../../interfaces/Product";

// @ts-ignore
export const productGetMany = async (findby, token) => {
  try {
    const url = END_POINT_SEVER + "/product/get-many?" + findby;
    const res = await axios.get(url, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error) {
    // @ts-ignore
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    // @ts-ignore
    return { error: true, message: error.message };
  }
};

// export const productGetCount = async (findby, token) => {
//   try {
//     const url = END_POINT_SEVER + "/product/get-count?" + findby;
//     const res = await axios.get(url, {
//       headers: { authorization: "Bearer " + token },
//     });
//     return res.data;
//   } catch (error) {
//     if (error.message === "Network Error") {
//       WarningNetworkFailure();
//     }

//     console.error("error", error);
//     return { error: true, message: error.message };
//   }
// };

// export const productGetOne = async (_id, token) => {
//   try {
//     const url = END_POINT_SEVER + "/product/get-one/" + _id;
//     const res = await axios.get(url, {
//       headers: { authorization: "Bearer " + token },
//     });
//     return res.data;
//   } catch (error) {
//     if (error.message === "Network Error") {
//       WarningNetworkFailure();
//     }

//     console.error("error", error);
//     return { error: true, message: error.message };
//   }
// };

// export const productUpdateOne = async (_id, body, token) => {
//   try {
//     const url = END_POINT_SEVER + "/product/update-one/" + _id;
//     const res = await axios.put(url, body, {
//       headers: { authorization: "Bearer " + token },
//     });
//     return res.data;
//   } catch (error) {
//     if (error.message === "Network Error") {
//       WarningNetworkFailure();
//     }

//     console.error("error", error);
//     return { error: true, message: error.message };
//   }
// };

// export const productUpdateMany = async (body, token) => {
//   try {
//     const url = END_POINT_SEVER + "/product/update-many";
//     const res = await axios.put(url, body, {
//       headers: { authorization: "Bearer " + token },
//     });
//     return res.data;
//   } catch (error) {
//     if (error.message === "Network Error") {
//       WarningNetworkFailure();
//     }

//     console.error("error", error);
//     return { error: true, message: error.message };
//   }
// };

export const productCreateOne = async (body: Product, token: string) => {
  try {
    const url = END_POINT_SEVER + "/product/create-one";
    const res = await axios.post(url, body, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: Error | unknown) {
    let message = "Unknown Error";
    if (error instanceof Error) message = error.message;
    if (message === "Network Error") {
      WarningNetworkFailure();
    }
    return { error: true, message: message };
  }
};

// export const productCreateMany = async (body, token) => {
//   try {
//     const url = END_POINT_SEVER + "/product/create-many";
//     const res = await axios.post(url, body, {
//       headers: { authorization: "Bearer " + token },
//     });
//     return res.data;
//   } catch (error) {
//     if (error.message === "Network Error") {
//       WarningNetworkFailure();
//     }

//     console.error("error", error);
//     return { error: true, message: error.message };
//   }
// };

// export const productDeleteOne = async (_id, token) => {
//   try {
//     const url = END_POINT_SEVER + "/product/delete-one/" + _id;
//     const res = await axios.put(url, {
//       headers: { authorization: "Bearer " + token },
//     });
//     return res.data;
//   } catch (error) {
//     if (error.message === "Network Error") {
//       WarningNetworkFailure();
//     }

//     console.error("error", error);
//     return { error: true, message: error.message };
//   }
// };

// export const productDeleteMany = async (data) => {
//   try {
//     const url = END_POINT_SEVER + "/product/delete-one/" + _id;
//     const res = await axios.put(url, {
//       headers: { authorization: "Bearer " + token },
//     });
//     return res.data;
//   } catch (error) {
//     if (error.message === "Network Error") {
//       WarningNetworkFailure();
//     }

//     console.error("error", error);
//     return { error: true, message: error.message };
//   }
// };
