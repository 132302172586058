import React from "react";
// import ReactGA from 'react-ga4';
import Routes from "./routes";
import { StateProvider } from "./store";
import { ToastContainer /* toast */ } from "react-toastify";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

function App() {
  return (
    <StateProvider>
      <Routes />
      <ToastContainer />
    </StateProvider>
  );
}
export default App;
