// @ts-nocheck
import React from "react";
import moment from "moment";
import { Button } from "react-bootstrap";
//
import { COLOR_APP } from "../../constants";

// icon
import { MdMeetingRoom } from "react-icons/md";
import { BsCashCoin } from "react-icons/bs";
import { MdOutlineAddShoppingCart } from "react-icons/md";

import EmptyComponent from "../EmptyComponent";
import TableOpen from "./TableOpen";
import TableDetailBar from "./TableDetailBar";
import useLongPress from "../../helpers/useLongPress";
import OrderListComponent from "../OrderListComponent";

export default function TableDetail({
  selectedTable,
  setSelectedTable,
  dataBill,
  total,
  handleUpdateOrderStatusgo,
  onSelect,
  checkedBox,
  totalAfterDiscount,
  handleShow,
  onPrinting,
  onPrintForCher,
  isCheckedOrderItem,
  canCheckOut,
  _openModalSetting,
  _goToAddOrder,
  setPopup,
  handleShow1,
  checkAllOrders,
  _onCheckOut,
  tableOrderItems,
  setCodeShortLink,
  handleUpdateOrderStatus,
  setCheckedBox,
  openTableAndReturnCodeShortLink,
  openTable,
  t,
}) {
  const onLongPress = () => {
    alert("longpress is triggered");
  };

  const onClick = () => {
    alert("click is triggered");
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };
  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);
  return (
    <div
      style={{
        display: "block",
        minWidth: 420,
        width: 420,
        maxWidth: 420,
        boxShadow: "-1px 0px 10px rgba(0,0,0,0.1)",
      }}
    >
      <div>
        <TableDetailBar handleClickBlack={() => setSelectedTable()} />
      </div>
      {selectedTable !== null &&
        selectedTable?.isStaffConfirm &&
        selectedTable?.isOpened && (
          <div
            style={{
              width: "100%",
              backgroundColor: "#FFF",
              height: "100%",
              overflowY: "scroll",
            }}
          >
            {
              <div>
                {/*section ລາຍລະອຽດ */}
                <div
                  style={{
                    backgroundColor: COLOR_APP,
                    color: "#fff",
                    padding: "10px 20px",
                  }}
                >
                  <div style={{ fontSize: 32, fontWeight: "bold" }}>
                    {selectedTable?.tableName}
                  </div>
                  <div>
                    {[
                      {
                        name: "ຊື່ຫ້ອງ",
                        value: selectedTable?.tableName,
                      },
                      {
                        name: "ລະຫັດບິນ",
                        value: selectedTable?.code,
                      },
                      {
                        name: "ເວລາເປີດ",
                        value: moment(selectedTable?.createdAt).format(
                          "HH:mm A"
                        ),
                      },
                    ].map((e, i) => (
                      <div key={i}>
                        {e?.name}: {e?.value}
                      </div>
                    ))}
                  </div>
                </div>

                {/*section ຈັດການ */}
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2,1fr)",
                  }}
                >
                  {/* <ButtonCustom
                    onClick={() => onPrintForCher()}
                    disabled={onPrinting}
                  >
                    {onPrinting && <Spinner animation="border" size="sm" />}
                    {t("printBillToKitchen")}
                  </ButtonCustom> */}
                  <Button
                    variant="success"
                    // bg="dark"
                    disabled={!canCheckOut}
                    onClick={() => _onCheckOut()}
                  >
                    <BsCashCoin /> ຄິດໄລເງິນ
                  </Button>
                  <Button
                    variant="dark"
                    bg="dark"
                    onClick={() =>
                      _goToAddOrder(
                        selectedTable?.tableId,
                        selectedTable?.code,
                        selectedTable?._id
                      )
                    }
                  >
                    <MdOutlineAddShoppingCart /> ເພີ່ມອໍເດີ
                  </Button>
                </div>
                {/* ---- */}
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                  }}
                  // hidden={checkedBox}
                >
                  <Button
                    variant="danger"
                    onClick={() => handleShow1()}
                    disabled={checkedBox}
                  >
                    ອັບເດດຍົກເລີກ
                  </Button>
                  <Button
                    variant="warning"
                    onClick={() => handleUpdateOrderStatusgo("DOING")}
                    disabled={checkedBox}
                  >
                    ອັບເດດກຳລັງຄົວ
                  </Button>
                  <Button
                    variant="success"
                    onClick={() => handleUpdateOrderStatus("SERVED")}
                    disabled={checkedBox}
                  >
                    ອັບເດດເປັນເສີບ
                  </Button>
                </div>
                <OrderListComponent
                  orders={isCheckedOrderItem}
                  handleSelectOrder={(item) => {
                    onSelect({
                      ...item,
                      isChecked: !item?.isChecked,
                      _select: !item?.isChecked,
                    });
                  }}
                />
                <div
                  hidden={tableOrderItems?.length !== 0}
                  style={{ width: "100%" }}
                >
                  <EmptyComponent />
                </div>
                <div style={{ marginBottom: 100 }} />
              </div>
            }
          </div>
        )}

      {/* ຕ້ອນກຳລັງຈະເປີດ */}
      {selectedTable !== null && !selectedTable?.isStaffConfirm && (
        <TableOpen
          setCodeShortLink={setCodeShortLink}
          selectedTable={selectedTable}
          openTable={openTable}
          openTableAndReturnCodeShortLink={openTableAndReturnCodeShortLink}
        />
      )}

      {!selectedTable && (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#d1d1d1",
            borderColor: "black",
            borderWidth: 1,
            display: "flex",
            flexDirection: "column",
            gap: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <MdMeetingRoom style={{ width: 100, height: 100 }} />
          </div>
          <div style={{ fontSize: 22, fontWeight: 700 }}>Please select</div>
        </div>
      )}
    </div>
  );
}
