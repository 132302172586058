import React, { useEffect, useState } from "react";
import {
  Card,
  Breadcrumb,
  Form,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { COLOR_APP } from "../../constants";
import { useStore } from "../../store";
import { BsExclamationDiamondFill } from "react-icons/bs";
import { getSetting, updateSetting } from "../../services/setting";
import { END_POINT_SEVER, getLocalData } from "../../constants/api";
import Axios from "axios";
import { IoMdImage } from "react-icons/io";

export default function StoreSettingPage() {
  // state
  const [setting, setSetting] = useState();
  const [switchState, setSwitchState] = useState({});
  const [tax, setTax] = useState(0);
  const [popup, setPopup] = useState();

  // provider
  const { audioSetting, setAudioSetting, storeDetail } = useStore();

  // useEffect
  useEffect(() => {
    getSettingData();
    getDataTax();
  }, []);

  // function
  const handleChangeTax = async (newTax) => {
    const { DATA } = await getLocalData();
    const _res = await Axios.put(
      END_POINT_SEVER + "/v4/tax/update/" + DATA.storeId,
      { newTax: parseInt(newTax) }
    );
    getDataTax();
    setPopup();
  };
  const getDataTax = async () => {
    const { DATA } = await getLocalData();
    const _res = await Axios.get(END_POINT_SEVER + "/v4/tax/" + DATA?.storeId);
    setTax(_res?.data?.taxPercent);
  };
  const getSettingData = async () => {
    const data = await getSetting(storeDetail?._id);
    setSwitchState((prev) => ({ ...prev, ...data?.smartMenu }));
    setSetting(data);
    console.log(data?.smartMenu);
  };
  const changeSwitchData = async (dataUpdate) => {
    const data = await updateSetting(setting?._id, dataUpdate);
  };
  const TooltipFunc = ({ id, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <BsExclamationDiamondFill style={{ color: COLOR_APP }} />
    </OverlayTrigger>
  );

  return (
    <div style={{ padding: 20 }}>
      <Breadcrumb>
        <Breadcrumb.Item>ຕັ້ງຄ່າ</Breadcrumb.Item>
        <Breadcrumb.Item active>ຕັ້ງຄ່າຮ້ານ</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 20,
          gridTemplateRows: "masonry",
        }}
      >
        <Card border="primary" style={{ margin: 0 }}>
          <Card.Header
            style={{
              backgroundColor: COLOR_APP,
              color: "#fff",
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            ໂປຣຟາຍ
          </Card.Header>
          <Card.Body>
            <div>
              <Form.Label>ຮູ້ໂປຣຟາຍ</Form.Label>
              <div
                style={{
                  width: 150,
                  height: 150,
                  backgroundColor: "#ccc",
                  color: COLOR_APP,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: `2px solid ${COLOR_APP}`,
                }}
              >
                <IoMdImage style={{ width: 40, height: 40 }} />
              </div>
              <Form.Label>ຊື່ຮ້ານ</Form.Label>
              <Form.Control placeholder="ຊື່ຮ້ານ" />
              <Form.Label>ເບີໂທຮ້ານ</Form.Label>
              <Form.Control placeholder="ເບີໂທຮ້ານ" />
              <Form.Label>ວັນໝົດກຳໜົດ</Form.Label>
              <Form.Control placeholder="ວັນໝົດກຳໜົດ" type="date" />
            </div>
          </Card.Body>
          <Card.Footer style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button>ບັນທຶກ</Button>
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
}
