import { Modal, Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import UploadImageFormik from "../../UploadImageFormik";
import CustomInput from "../../CustomInput";
import CustomSelect from "../../CustomSelect";
import validationSchema from "../../../utils/validationSchema";
import { productPackageCreateOne } from "../../../services/therdy/product.service";
import { errorAdd, successAdd } from "../../../helpers/sweetalert";

interface PopUpAddPackageProps {
  open: boolean;
  onClose: () => void;
  productId: string;
}

export default function PopUpAddPackage({
  open,
  onClose,
  productId,
}: PopUpAddPackageProps) {
  const onSubmit = async (values: any) => {
    const { error } = await productPackageCreateOne(
      { ...values, productId },
      ""
    );
    if (error) {
      errorAdd("error something");
    } else {
      successAdd("success");
      onClose();
    }
  };
  return (
    <Modal show={open} onHide={onClose} keyboard={false}>
      <Modal.Header>
        <Modal.Title>Create Product Package</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{}}
        validationSchema={validationSchema.createPackage}
        onSubmit={onSubmit}
      >
        <Form>
          <Modal.Body>
            <div className="gap-[10px]">
              <UploadImageFormik
                commentText="500x500"
                name="image"
                style={{ width: "200px", height: "200px" }}
              />
              <CustomInput
                require
                label="Package Name"
                name="name"
                type="text"
                placeholder="-- Package Name --"
              />
              <CustomInput
                require
                label="Price"
                name="price"
                type="number"
                placeholder="-- Price --"
              />
              <CustomSelect
                require
                label="Show on"
                name="active"
                options={[
                  {
                    value: "ADMIN",
                    label: "Admin Only",
                  },
                  {
                    value: "VIP",
                    label: "VIP Only",
                  },
                  {
                    value: "PUBLIC",
                    label: "ALL",
                  },
                ]}
              />
              <CustomInput
                label="Cost"
                name="cost"
                type="number"
                placeholder="-- Cost --"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              ບັນທຶກ
            </Button>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  );
}
