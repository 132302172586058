import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

export default function SettingLayout() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "200px 1fr",
        minHeight: "100%",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "1px 0px 10px rgba(0,0,0,0.1)",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "sticky",
            top: 0,
            display: "flex",
            flexDirection: "column",
            padding: 10,
            gap: 10,
          }}
        >
          <Button>ຕັ້ງຄ່າຮ້ານ</Button>
          <Button variant="outline-primary">ຕັ້ງຄ່າພະນັກງານ</Button>
          <Button variant="outline-primary">ຕັ້ງຄ່າສ້າງ</Button>
          <Button variant="outline-primary">ຕັ້ງຄ່າສະຕ໊ອກ</Button>
          <Button variant="outline-primary">ຕັ້ງຄ່າເມນູ</Button>
          <Button
            variant="outline-primary"
            onClick={() => navigate("/report/members-report")}
          >
            ລາຍງານສະມາຊິກ
          </Button>
          <Button variant="outline-primary">ການພະລິດ</Button>
        </div>
      </div>
      <div style={{ height: "calc(100dvh - 64px)", overflowY: "auto" }}>
        <Outlet />
      </div>
    </div>
  );
}
