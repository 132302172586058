import React from "react";
import { Outlet } from "react-router-dom";
// page
import ReportMenuPage from "../pages/report/ReportMenuPage";

// eslint-disable-next-line
export default {
  path: "/dashboardmenu",
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <ReportMenuPage />,
    },
  ],
};
