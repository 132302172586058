import React, { CSSProperties, ReactNode } from "react";

interface ButtonPrimaryProps {
  text?: string;
  children?: ReactNode;
  style?: CSSProperties;
  disabled?: boolean;
  onClick?: () => void;
  [key: string]: any; // For additional props
}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  text,
  children,
  style,
  disabled = false,
  onClick,
  ...other
}) => {
  return (
    <div
      style={{
        backgroundColor: disabled
          ? "rgba(251, 110, 59,0.3)"
          : "rgb(251, 110, 59)",
        borderRadius: 8,
        padding: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: disabled ? "not-allowed" : "pointer",
        ...style,
      }}
      onClick={() => {
        if (!disabled && onClick) {
          onClick();
        }
      }}
      {...other}
    >
      {children || text || "Button"}
    </div>
  );
};

export default ButtonPrimary;
