import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { COLOR_APP } from "../../constants";
import moment from "moment";
import styled from "styled-components";
import { moneyCurrency, orderStatus } from "../../helpers";
import * as _ from "lodash";
import axios from "axios";
import { END_POINT_SEVER } from "../../constants/api";
import { getHeaders } from "../../services/auth";
import { useTranslation } from "react-i18next";

// Prevent negative values in input
export const preventNegativeValues = (
  e: React.KeyboardEvent<HTMLInputElement>
) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

// Define types for props
interface OrderItem {
  name: string;
  quantity: number;
  price: number;
  status: string;
  createdBy: { firstname: string };
  createdAt: string;
}

interface PopUpAddDiscountProps {
  open: boolean;
  value: OrderItem[];
  onClose: () => void;
  onSubmit: () => Promise<void>;
  dataBill: { _id: string; discount: number };
}

const PopUpAddDiscount: React.FC<PopUpAddDiscountProps> = ({
  open,
  value,
  onClose,
  onSubmit,
  dataBill,
}) => {
  const { t } = useTranslation();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [selectedButton, setSelectedButton] = useState<string>("");

  // Set discount on bill
  const setDiscountBill = async () => {
    try {
      const url = `${END_POINT_SEVER}/v3/bill-discount`;
      const body = {
        id: dataBill?._id,
        data: {
          discount: discount,
          discountType: selectedButton === "%" ? "PERCENT" : "LAK",
        },
      };
      const headers = await getHeaders();
      await axios.put(url, body, { headers });
    } catch (err) {
      console.log(err);
    }
  };

  // Update total and discount when values change
  useEffect(() => {
    const data = value.filter((e) => e?.status !== "CANCEL");
    const _sumTotal = _.sumBy(data, (o) => o?.quantity * o?.price);
    setTotal(_sumTotal);
    setDiscount(dataBill?.discount || 0);
  }, [value, dataBill]);

  return (
    <Modal show={open} onHide={onClose}>
      <Modal.Header closeButton>{t("discount")}</Modal.Header>
      <Modal.Body>
        <TableCustom>
          <thead>
            <tr>
              <th>ລຳດັບ</th>
              <th>ຊື່ເມນູ</th>
              <th>ຈຳນວນ</th>
              <th>ສະຖານະ</th>
              <th>ຜູ້ສັ່ງ</th>
              <th>ເວລາ</th>
            </tr>
          </thead>
          <tbody>
            {value.map((orderItem, index) => (
              <tr key={index} style={{ borderBottom: "1px solid #eee" }}>
                <td>{index + 1}</td>
                <td>{orderItem?.name}</td>
                <td>{orderItem?.quantity}</td>
                <td
                  style={{
                    color:
                      orderItem?.status === "SERVED"
                        ? "green"
                        : orderItem?.status === "DOING"
                        ? ""
                        : "red",
                  }}
                >
                  {orderItem?.status ? orderStatus(orderItem?.status) : "-"}
                </td>
                <td>{orderItem?.createdBy?.firstname}</td>
                <td>
                  {orderItem?.createdAt
                    ? moment(orderItem?.createdAt).format("HH:mm A")
                    : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </TableCustom>

        <div
          style={{
            padding: "10px 0",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div>ລວມ: {moneyCurrency(total)} ກີບ</div>
        </div>

        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <div>ສ່ວນຫຼຸດ</div>
          <div style={{ display: "flex", border: "1px solid #ccc" }}>
            <div
              onClick={() => setSelectedButton("%")}
              style={{
                backgroundColor: selectedButton === "%" ? COLOR_APP : "white",
                width: 40,
                height: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              %
            </div>
            <div
              onClick={() => setSelectedButton("ກີບ")}
              style={{
                backgroundColor: selectedButton === "ກີບ" ? COLOR_APP : "white",
                width: 40,
                height: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              ກີບ
            </div>
          </div>
          <input
            onKeyDown={preventNegativeValues}
            type="number"
            value={discount}
            min="0"
            style={{ height: 40 }}
            onChange={(e) => setDiscount(Number(e.target.value))}
          />
          <div>{selectedButton}</div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          disabled={buttonDisabled}
          style={{ backgroundColor: COLOR_APP, color: "#ffff", border: 0 }}
          onClick={async () => {
            setButtonDisabled(true);
            await setDiscountBill();
            await onSubmit();
            onClose();
            setButtonDisabled(false);
          }}
        >
          ເພີ່ມສ່ວນຫຼຸດ
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// Custom Table Styling
const TableCustom = styled.table({
  width: "100%",
  fontSize: 12,
  "th, td": {
    padding: 0,
  },
  "th:first-child": {
    maxWidth: 40,
    width: 40,
  },
  "td:first-child": {
    maxWidth: 40,
    width: 40,
  },
  thead: {
    backgroundColor: "#e9e9e9",
  },
});

export default PopUpAddDiscount;
