import React, { useState } from "react";
import { Breadcrumb, Tab, Tabs } from "react-bootstrap";
import { useStore } from "../../../store";
import { useNavigate } from "react-router-dom";
import ProductOrderWaitTab from "../../../components/tab/ProductOrderWaitTab";
import ProductOrderSuccessTab from "../../../components/tab/ProductOrderSuccessTab";
import ProductOrderCancelTab from "../../../components/tab/ProductOrderCancelTab";
import PopUpConfirmPayment from "../../../components/popup/PopUpConfirmPayment";

const limitData = 50;

const TabList = [
  { title: "ລາຍການໃໝ່", key: "ORDER_WAIT", Tab: ProductOrderWaitTab },
  { title: "ລາຍການສຳເລັດ", key: "ORDER_DONE", Tab: ProductOrderSuccessTab },
  { title: "ລາຍການຍົກເລີກ", key: "ORDER_CANCLED", Tab: ProductOrderCancelTab },
];

export default function ProductOrderPage() {
  const navigate = useNavigate();
  // state
  const [pagination, setPagination] = useState(1);
  const [totalPagination, setTotalPagination] = useState(10);

  const [popup, setPopup] = useState();

  // store
  const { storeDetail }: any = useStore();

  return (
    <>
      <div style={{ padding: 20 }}>
        <Breadcrumb>
          <Breadcrumb.Item active>ລາຍການອໍເດີ</Breadcrumb.Item>
        </Breadcrumb>
        <Tabs defaultActiveKey="ORDER_WAIT">
          {TabList.map((e, i) => (
            <Tab eventKey={e.key} title={e.title} key={i}>
              <e.Tab />
            </Tab>
          ))}
        </Tabs>
      </div>
      <PopUpConfirmPayment
        open={false}
        onClose={() => {}}
        onSubmit={async () => {}}
      />
    </>
  );
}
