import axios from "axios";
import { WarningNetworkFailure } from "../../helpers/alertsweet";

export interface User {
  _id?: string;
  code?: string;
  image?: string;
  description?: string;
  stock?: number;
  categoryGroup?: any[];
  name?: string;
  price?: number;
  createdAt?: Date;
  updatedAt?: Date;
}

const END_POINT_SEVER = "https://minion-api.nonlub.com";
// const END_POINT_SEVER = "http://localhost:7070";

// @ts-ignore
export const userGetMany = async (findby, token) => {
  try {
    const url = END_POINT_SEVER + "/user/get-many?" + findby;
    const res = await axios.get(url, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error) {
    // @ts-ignore
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    // @ts-ignore
    return { error: true, message: error.message };
  }
};

export const userGetCount = async (findby: string, token: string) => {
  try {
    const url = END_POINT_SEVER + "/user/get-count?" + findby;
    const res = await axios.get(url, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};

export const userGetOne = async (_id: string, token: string) => {
  try {
    const url = END_POINT_SEVER + "/user/get-one/" + _id;
    const res = await axios.get(url, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};

export const userUpdateOne = async (_id: string, body: any, token: string) => {
  try {
    const url = END_POINT_SEVER + "/user/update-one/" + _id;
    const res = await axios.put(url, body, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};

export const userUpdateMany = async (body: any, token: string) => {
  try {
    const url = END_POINT_SEVER + "/user/update-many";
    const res = await axios.put(url, body, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};

export const userCreateOne = async (body: User, token: string) => {
  try {
    const url = END_POINT_SEVER + "/user/create-one";
    const res = await axios.post(url, body, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: Error | unknown) {
    let message = "Unknown Error";
    if (error instanceof Error) message = error.message;
    if (message === "Network Error") {
      WarningNetworkFailure();
    }
    return { error: true, message: message };
  }
};

export const userCreateMany = async (body: any, token: string) => {
  try {
    const url = END_POINT_SEVER + "/user/create-many";
    const res = await axios.post(url, body, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};

export const userDeleteOne = async (_id: string, token: string) => {
  try {
    const url = END_POINT_SEVER + "/user/delete-one/" + _id;
    const res = await axios.put(url, {
      headers: { authorization: "Bearer " + token },
    });
    return res.data;
  } catch (error: any) {
    if (error.message === "Network Error") {
      WarningNetworkFailure();
    }

    console.error("error", error);
    return { error: true, message: error.message };
  }
};
