import React from "react";
import { Button, Form } from "react-bootstrap";
// icons
import { SiAirtable } from "react-icons/si";
import { BiSolidDoorOpen } from "react-icons/bi";

export default function TableOpen({ selectedTable, openTable }) {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#FFF",
        height: "100%",
        borderColor: "black",
        borderWidth: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 10,
      }}
    >
      <div
        style={{
          fontSize: 24,
          fontWeight: "bold",
          textAlign: "center",
          padding: 20,
        }}
      >
        <SiAirtable /> {selectedTable?.tableName}
      </div>
      <div>
        <Form.Check type="checkbox">
          <Form.Check.Input type="checkbox" isValid />
          <Form.Check.Label>ໃຊ້ QR ສັ່ງອາຫານ</Form.Check.Label>
        </Form.Check>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 10,
          width: "100%",
          gridAutoRows: "100px",
        }}
      >
        <Button
          size="lg"
          style={{ width: "100%" }}
          variant="primary"
          onClick={() => openTable()}
        >
          <BiSolidDoorOpen /> ເປີດຫ້ອງລາຍເດືອນ
        </Button>
        <Button
          size="lg"
          style={{ width: "100%" }}
          variant="primary"
          onClick={() => openTable()}
        >
          <BiSolidDoorOpen /> ເປີດຫ້ອງລາຍມື້
        </Button>
        <Button
          size="lg"
          style={{ width: "100%" }}
          variant="primary"
          onClick={() => openTable()}
        >
          <BiSolidDoorOpen /> ເປີດໂຕະອາຫານ
        </Button>
      </div>
      {/* <Button
        style={{ width: "100%" }}
        variant="primary"
        onClick={() => {
          openTableAndReturnCodeShortLink().then((e) => {
            setCodeShortLink(e);
          });
        }}
      >
        ເປີດຫ້ອງພ້ອມ ປິນ QR
      </Button> */}
    </div>
  );
}
