import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { END_POINT_SEVER, getLocalData } from "../../constants/api";
import { getMenus } from "../../services/menu";
import { useStore } from "../../store";
import { URL_PHOTO_AW3 } from "../../constants";
import Swal from "sweetalert2";

// Type definitions for props
interface PopUpAddMenuForBillFarkProps {
  open: boolean;
  onClose: () => void;
  callback: () => void;
}

interface Menu {
  _id: string;
  name: string;
  images: string[];
}

const PopUpAddMenuForBillFark: React.FC<PopUpAddMenuForBillFarkProps> = ({
  open,
  onClose,
  callback,
}) => {
  // state
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [menusData, setMenusData] = useState<Menu[] | undefined>(undefined);
  // store
  const { storeDetail }: any = useStore();

  // useEffect to load menu data when modal is opened
  useEffect(() => {
    if (open) {
      getMenuData();
    }
  }, [open]);

  // functions
  const getMenuData = async () => {
    try {
      setIsLoading(true);
      let findby = "?";
      findby += `storeId=${storeDetail?._id}`;
      const data = await getMenus(findby);
      setMenusData(data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleAddMenuToCanFark = async (menuId: string) => {
    try {
      const url = `${END_POINT_SEVER}/v4/menu-fark/create/${menuId}`;
      const { TOKEN } = await getLocalData();
      await Axios.post(url, { canFark: true }, { headers: TOKEN });
      await Swal.fire({
        icon: "success",
        title: "ສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      });
      callback();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal show={open} onHide={onClose} size={"lg"}>
      <Modal.Header
        closeButton
        style={{ display: "flex", alignItems: "center", gap: 10 }}
      >
        ເພີ່ມເມນູທີສາມາດຝາກໄດ້
      </Modal.Header>
      <Modal.Body
        style={{
          boxSizing: "border-box",
          overflow: "auto",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "25% 25% 25% 25%",
            overflow: "hidden",
            maxWidth: "100%",
          }}
        >
          {menusData?.map((menu) => (
            <div key={menu._id} style={{ padding: 2 }}>
              <div
                style={{
                  border: "1px solid #ccc",
                  overflow: "hidden",
                  padding: 5,
                  borderRadius: 8,
                }}
              >
                <div style={{ height: 100, width: "100%" }}>
                  <img
                    src={
                      menu?.images?.[0]
                        ? `${URL_PHOTO_AW3}${menu.images[0]}`
                        : "https://media.istockphoto.com/vectors/thumbnail-image-vector-graphic-vector-id1147544807?k=20&m=1147544807&s=612x612&w=0&h=pBhz1dkwsCMq37Udtp9sfxbjaMl27JUapoyYpQm0anc="
                    }
                    alt={menu.name}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div style={{ whiteSpace: "nowrap" }}>{menu?.name}</div>
                <Button
                  style={{ width: "100%" }}
                  onClick={() => {
                    handleAddMenuToCanFark(menu._id);
                  }}
                >
                  ເພີ່ມ
                </Button>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PopUpAddMenuForBillFark;
