import React, { useState } from "react";
import { COLOR_APP } from "../../constants";
import { useNavigate } from "react-router-dom";
import { Card, Table } from "react-bootstrap";

type Props = {};

function ProductOrderSuccessTab({}: Props) {
  const navigate = useNavigate();
  // state
  const [pagination, setPagination] = useState(1);
  const [totalPagination, setTotalPagination] = useState(10);
  return (
    <div>
      <div style={{ height: 10 }} />
      <Card>
        <Card.Header>test</Card.Header>
        <Card.Body>
          <Table>
            <table>
              <tr
                style={{
                  fontWeight: 700,
                  fontSize: 18,
                  textDecoration: "underline",
                }}
              >
                <th>#</th>
                <th>Bill No</th>
                <th>ຊື່ສິນຄ້າ</th>
                <th>ລາຄາ</th>
                <th>ລູກຄ້າ</th>
                <th>ບິນຊຳລະເງິນ</th>
                <th>ເວລາສ້າງບິນ</th>
                <th>ເວລາຍືນຍັນ</th>
                <th>ຜູ້ຍືນຍັນ</th>
              </tr>

              <tr>
                <td className="text-left">1</td>
                <td className="text-left">EHSF-2345</td>
                <td className="text-left">ສູດນັງສື</td>
                <td className="text-left">100,000</td>
                <td className="text-left">
                  <span
                    style={{
                      color: COLOR_APP,
                      fontWeight: 700,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    admin
                  </span>
                </td>
                <td className="text-left">
                  <span
                    style={{
                      color: COLOR_APP,
                      fontWeight: 700,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {">> ຮູບ <<"}
                  </span>
                </td>
                <td className="text-left">19/12/2024 13:34</td>
                <td className="text-left">19/12/2024 14:20</td>
                <td className="text-left">
                  {" "}
                  <span
                    style={{
                      color: COLOR_APP,
                      fontWeight: 700,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    lion
                  </span>
                </td>
              </tr>
            </table>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ProductOrderSuccessTab;
