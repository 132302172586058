import React, { useState, useEffect } from "react";
import packageJson from "../../package.json";
import { USER_KEY } from "../constants";
import { useNavigate } from "react-router-dom";
import { useStore } from "../store";
import { Button } from "react-bootstrap";
import { HiMenu } from "react-icons/hi";
import Sidenav from "./SideNav";
interface UserData {
  [key: string]: any; // You can define more specific types if needed
}

export default function NavBar() {
  const navigate = useNavigate();

  const [showNavMobile, setShowNavMobile] = useState<Boolean>(false);

  // Provider
  const { setStoreDetail, setProfile }: any = useStore();

  const _onLogout = () => {
    setProfile({});
    setStoreDetail({});
    navigate(`/`);
  };

  return (
    <React.Fragment>
      <div
        className={
          showNavMobile
            ? "fixed top-0 left-0 z-50 h-screen pt-16 min-w-full w-full bg-black bg-opacity-20"
            : "hidden"
        }
        onClick={() => setShowNavMobile(false)}
      >
        <Sidenav />
      </div>
      <nav className="bg-primary w-full h-16 min-h-16 z-50 flex gap-2 items-center px-2 sticky top-0">
        <div className="block md:hidden">
          <Button
            variant="light"
            onClick={() => setShowNavMobile(!showNavMobile)}
          >
            <HiMenu />
          </Button>
        </div>
        <div className="flex-1">
          <span className="text-sm cursor-pointer">
            v{packageJson?.version}
          </span>
        </div>
        <div>
          <Button variant="light" onClick={_onLogout}>
            Logout
          </Button>
        </div>
      </nav>
    </React.Fragment>
  );
}
