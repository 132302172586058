import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { BiArchiveIn } from "react-icons/bi";
import { COLOR_APP } from "../../constants";
import { MdAssignmentAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Image from "../Image";

type Props = {};

function ProductOrderWaitTab({}: Props) {
  const navigate = useNavigate();
  // state
  const [pagination, setPagination] = useState(1);
  const [totalPagination, setTotalPagination] = useState(10);
  return (
    <div>
      <div style={{ height: 10 }} />
      <div className="grid gap-[10px] grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
        {[...new Array(20)].map((e, i) => (
          <Card border="primary" key={i}>
            <Card.Header className="flex items-center gap-2">
              <BiArchiveIn /> ລາຍການເຂົ້າໃໝ່
            </Card.Header>
            <Card.Body>
              <div>
                Username:{" "}
                <span
                  style={{
                    color: COLOR_APP,
                    fontWeight: 700,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Hacker no
                </span>
              </div>
              <hr />
              <div>
                Bill No:{" "}
                <span
                  style={{
                    fontWeight: 700,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  EHSF-2345
                </span>
              </div>
              <div>ບິນເວລາ: 12/02/2000 14:40</div>
              <div>ສີນຄ້າ: ລະບົບຮ້ານຄ້າ</div>
              <div>
                ລາຄາ:{" "}
                <span
                  style={{
                    color: "#000",
                    fontWeight: 700,
                    fontSize: 20,
                  }}
                >
                  60,000 LAK
                </span>
              </div>
              <div className="flex justify-center">
                <div className="flex items-center w-[150px] h-[300px] overflow-hidden">
                  <Image
                    src="/images/unnamed.webp"
                    alt=""
                    style={{
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
              <Button
                variant="dark"
                onClick={() => navigate("/banhouse/product/create-one")}
                className="flex items-center gap-2"
              >
                <MdAssignmentAdd /> ອັບເດດລາຍການອໍເດີ
              </Button>
            </Card.Footer>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default ProductOrderWaitTab;
